const singleTries = [
  'alfa_additional_task',
  'alfa_challenge_task',
  'alfa_minimum_task',
  'ph_week_test',
  'prova_task',
  'standard_test_task',
]

const noTitle = ['ph_week_test', 'prova_task']

const checkTaskNoTitle = (context: string | undefined): boolean =>
  !!context && noTitle.includes(context)

const checkMultipleTries = (context: string | undefined): boolean =>
  !(!!context && singleTries.includes(context))

const dashConcatText = (text: Array<undefined | null | string>) =>
  text.filter(label => ![undefined, null, ''].includes(label)).join(' - ')

const checkContainsText = (text: string) => text && new RegExp('\\S').test(text)

export { checkTaskNoTitle, checkMultipleTries, dashConcatText, checkContainsText }
