const defaultSort = accessor => (rowA, rowB) => {
  const valA =
    typeof rowA[accessor] === 'string' ? naturalArrange(rowA[accessor] || '') : rowA[accessor]
  const valB =
    typeof rowB[accessor] === 'string' ? naturalArrange(rowB[accessor] || '') : rowB[accessor]

  return doSort(valA, valB)
}

const naturalArrange = text => text.replace(/\d+/g, replacement => replacement.padStart(10, '0'))

const doSort = (valA, valB) => {
  if (valA < valB) return -1
  if (valA > valB) return 1
  return 0
}

export default defaultSort
