import { getFieldFromMap } from 'utils/queryBuilder'
import { FilterFields } from 'types/enums'
import numericSort from 'utils/numericSort'

export interface FieldHandlerInterface {
  field: string
  label?: string
  buttonLabels?: { singular: string; plural: string }
  multiSelect?: boolean
  sortById?: boolean
  preFilter?(data: any): any
}

interface StructuredLessonInterface {
  disciplineId: string
  disciplineName: string
  sectorId: string
}

interface FilterTypes extends ExtraVisiblesFiltersTypes {
  grade: any[]
  klasses: any[]
  startDate: Date
  endDate: Date
}

interface ExtraVisiblesFiltersTypes {
  disciplines?: any[]
  exams?: any[]
  standardTests?: any[]
  olimpicoLists?: any[]
  lessons?: any[]
  lists?: any[]
  activities?: any[]
  emTempoLists?: any[]
  materials?: any[]
  notebooks?: any[]
  phs?: any[]
  testTypes?: any[]
}

const handlers: { [field: string]: any } = {
  disciplines: (): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Disciplinas',
      singular: 'Disciplina',
    },
    field: FilterFields.disciplines,
    label: 'Disciplina',
    multiSelect: getFieldFromMap(FilterFields.disciplines).multiSelect || false,
    preFilter: (options: any[]) => {
      return options
    },
  }),
  endDate: (): FieldHandlerInterface => ({
    field: FilterFields.endDate,
    label: 'Data de Término',
    multiSelect: false,
  }),
  exams: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Provas',
      singular: 'Prova',
    },
    field: FilterFields.exams,
    label: 'Prova',
    multiSelect: getFieldFromMap(FilterFields.exams).multiSelect || false,
    preFilter: (options: any[]) => {
      const validDisciplines = getFilters(
        filters,
        'disciplines',
        ExtraVisiblesFilters.disciplines || [],
      )
      ExtraVisiblesFilters.exams = filterOptions(
        options,
        'disciplineIds',
        getFilters({ validDisciplines }, 'validDisciplines'),
      )
      return ExtraVisiblesFilters.exams
    },
  }),
  standardTests: (): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Standard tests',
      singular: 'Standard test',
    },
    field: FilterFields.standardTests,
    label: 'Atividade',
    multiSelect: getFieldFromMap(FilterFields.standardTests).multiSelect || false,
  }),
  olimpicoLists: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Atividades',
      singular: 'Atividade',
    },
    field: FilterFields.olimpicoLists,
    label: 'Atividade',
    multiSelect: getFieldFromMap(FilterFields.olimpicoLists).multiSelect || false,
    preFilter: (options: any[]) => {
      const validDisciplines = getFilters(
        filters,
        'disciplines',
        ExtraVisiblesFilters.disciplines || [],
      )
      ExtraVisiblesFilters.olimpicoLists = filterOptions(
        options,
        'disciplineIds',
        getFilters({ validDisciplines }, 'validDisciplines'),
      )
      return ExtraVisiblesFilters.olimpicoLists
    },
  }),
  grade: (): FieldHandlerInterface => ({
    field: FilterFields.grade,
    multiSelect: getFieldFromMap(FilterFields.grade).multiSelect || false,
    sortById: true,
  }),
  klasses: (): FieldHandlerInterface => ({
    field: FilterFields.klasses,
    multiSelect: getFieldFromMap(FilterFields.klasses).multiSelect || false,
    sortById: true,
  }),
  lessons: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Atividades',
      singular: 'Atividade',
    },
    field: FilterFields.lessons,
    label: 'Atividade',
    multiSelect: getFieldFromMap(FilterFields.lessons).multiSelect || false,
    preFilter: (options: any[]) => {
      const structuredLessons = [] as any[]
      const validDisciplines = getFilters(
        filters,
        'disciplines',
        ExtraVisiblesFilters.disciplines || [],
      )
      const selectedNotebookIds = getFilters(filters, 'notebooks')
      const selectedTestTypes = getFilters(filters, 'testTypes')
      const selectedDisciplineIds = getFilters({ validDisciplines }, 'validDisciplines')

      ExtraVisiblesFilters.lessons = []

      options.forEach(({ disciplines, value, id }: any) => {
        disciplines
          .filter(
            ({ disciplineId, notebookId, testTypeId }: any) =>
              (!selectedDisciplineIds || selectedDisciplineIds.includes(`${disciplineId}`)) &&
              (!selectedNotebookIds || selectedNotebookIds.includes(notebookId)) &&
              (!selectedTestTypes || selectedTestTypes.includes(testTypeId.toUpperCase())),
          )
          .forEach(({ disciplineId, disciplineName, sectorId }: any) => {
            addDiscipline(
              structuredLessons,
              { disciplineId, disciplineName, sectorId },
              { id, value },
            )
            ExtraVisiblesFilters.lessons?.push({ id, value })
          })
      })

      return structuredLessons
        .sort((slA: any, slB: any) => (slA.value > slB.value ? 1 : -1))
        .map((sl: any) => {
          sl.items.sort(numericSort('value'))
          return sl
        })
    },
  }),
  lists: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Atividades',
      singular: 'Atividade',
    },
    field: FilterFields.lists,
    label: 'Atividade',
    multiSelect: getFieldFromMap(FilterFields.lists).multiSelect || false,
    preFilter: (options: any[]) => {
      const validDisciplines = getFilters(
        filters,
        'disciplines',
        ExtraVisiblesFilters.disciplines || [],
      )
      ExtraVisiblesFilters.lists = filterOptions(
        options,
        'disciplineIds',
        getFilters({ validDisciplines }, 'validDisciplines'),
      )
      return ExtraVisiblesFilters.lists
    },
  }),
  activities: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
    fields: string[],
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Atividades',
      singular: 'Atividade',
    },
    field: FilterFields.activities,
    label: 'Atividade',
    multiSelect: getFieldFromMap(FilterFields.activities).multiSelect || false,
    preFilter: (options: any[]) => {
      const isTestTypes = fields.includes('testTypes')
      const validDisciplines = getFilters(
        filters,
        'disciplines',
        ExtraVisiblesFilters.disciplines || [],
      )
      const validNotebooks = getFilters(filters, 'notebooksIds', filters.notebooks || [])
      const selectedNotebookIds = getFilters(filters, 'notebooks')

      ExtraVisiblesFilters.activities = filterOptions(
        options,
        'disciplineIds',
        getFilters({ validDisciplines }, 'validDisciplines'),
      )

      if (selectedNotebookIds) {
        const list: any = []
        validNotebooks.forEach((element: any) => {
          element.materialIds.forEach((id: any) => list.push(id))
        })

        ExtraVisiblesFilters.activities = filterOptions(
          ExtraVisiblesFilters.activities,
          'testIds',
          list,
        )
      }

      if (isTestTypes && filters.testTypes && filters.testTypes?.length !== 0) {
        const validTestTypes = concatArrayFilters(filters.testTypes, 'testIds')
        ExtraVisiblesFilters.activities = filterOptions(
          ExtraVisiblesFilters.activities || [],
          'testIds',
          validTestTypes,
        )
      }
      return ExtraVisiblesFilters.activities
    },
  }),
  emTempoLists: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Atividades',
      singular: 'Atividade',
    },
    field: FilterFields.emTempoLists,
    label: 'Atividade',
    multiSelect: getFieldFromMap(FilterFields.emTempoLists).multiSelect || false,
    preFilter: (options: any[]) => {
      const validDisciplines = getFilters(
        filters,
        'disciplines',
        ExtraVisiblesFilters.disciplines || [],
      )
      ExtraVisiblesFilters.emTempoLists = filterOptions(
        options,
        'disciplineIds',
        getFilters({ validDisciplines }, 'validDisciplines'),
      )
      return ExtraVisiblesFilters.emTempoLists
    },
  }),
  materials: (): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Materiais',
      singular: 'Material',
    },
    field: FilterFields.materials,
    label: 'Material',
    multiSelect: getFieldFromMap(FilterFields.materials).multiSelect || false,
  }),
  notebooks: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Cadernos',
      singular: 'Caderno',
    },
    field: FilterFields.notebooks,
    label: 'Caderno',
    multiSelect: getFieldFromMap(FilterFields.notebooks).multiSelect || false,
    preFilter: (options: any[]) => {
      const disciplines = filterOptions(
        options,
        'disciplineIds',
        getFilters(filters, 'disciplines'),
      )
      if (disciplines) {
        ExtraVisiblesFilters.notebooks = disciplines
      } else {
        ExtraVisiblesFilters.notebooks = filterOptions(
          options,
          'materialIds',
          getFilters(filters, 'materials'),
        )
      }
      return ExtraVisiblesFilters.notebooks
    },
  }),
  phs: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Atividades',
      singular: 'Atividade',
    },
    field: FilterFields.phs,
    label: 'Atividade',
    multiSelect: getFieldFromMap(FilterFields.phs).multiSelect || false,
    preFilter: (options: any[]) => {
      const validDisciplines = getFilters(
        filters,
        'disciplines',
        ExtraVisiblesFilters.disciplines || [],
      )
      ExtraVisiblesFilters.phs = filterOptions(
        options,
        'disciplineIds',
        getFilters({ validDisciplines }, 'validDisciplines'),
      )
      return ExtraVisiblesFilters.phs
    },
  }),
  startDate: (): FieldHandlerInterface => ({
    field: FilterFields.startDate,
    label: 'Data de Início',
    multiSelect: false,
  }),
  testTypes: (
    ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
    filters: FilterTypes,
    fields: string[],
  ): FieldHandlerInterface => ({
    buttonLabels: {
      plural: 'Tipos de Tarefa',
      singular: 'Tipo de Tarefa',
    },
    field: FilterFields.testTypes,
    label: 'Tipo de Tarefa',
    multiSelect: getFieldFromMap(FilterFields.testTypes).multiSelect || false,
    preFilter: (options: any[]) => {
      const validNotebooks = getFilters(filters, 'notebooksIds', filters.notebooks || [])
      const selectedNotebookIds = getFilters(filters, 'notebooks')

      const isLessons = fields.includes('lessons')
      if (isLessons) {
        ExtraVisiblesFilters.testTypes = filterOptions(options, 'lessons')
      } else {
        const validTests = getValidTests(filters, options)
        ExtraVisiblesFilters.testTypes = options.filter((option: any) =>
          option.testIds?.some((testId: string) => validTests.includes(testId)),
        )
      }

      if (selectedNotebookIds) {
        const list: any = []
        validNotebooks.forEach((element: any) => {
          element.materialIds.forEach((id: any) => list.push(id))
        })
        if (isLessons) {
          ExtraVisiblesFilters.testTypes = filterOptions(
            ExtraVisiblesFilters.testTypes,
            'notebooks',
            list,
          )
        } else {
          ExtraVisiblesFilters.testTypes = filterOptions(
            ExtraVisiblesFilters.testTypes,
            'testIds',
            list,
          )
        }
      }
      return ExtraVisiblesFilters.testTypes
    },
  }),
}

const concatArrayFilters = (options: any[], key: string): any[] => {
  let newArray: any[] = []
  options.forEach((option: any) => {
    if (option[key]) {
      newArray = newArray.concat(option[key])
    }
  })
  return newArray
}

const getValidTests = (filters: any, options: any) => {
  return Array.isArray(filters.lists) && filters.lists.length
    ? filters.lists.map((list: any) => list.testIds).flat()
    : options.map((option: any) => option.testIds).flat()
}

const addDiscipline = (
  structuredLessons: any[],
  { disciplineId, disciplineName, sectorId }: StructuredLessonInterface,
  item: any,
) => {
  const discipline = structuredLessons.find((sl: any) => sl.value === disciplineName)
  if (!!discipline) {
    const disciplineExists = discipline.items.find((dp: any) => dp.id === item.id)
    if (!disciplineExists) discipline.items.push(item)
  } else {
    structuredLessons.push({
      disciplineId: `${disciplineId}|${sectorId || ''}`,
      items: [item],
      value: disciplineName,
    })
  }
}

const getFilters = (filters: any, key: string, defaultValue?: any) =>
  Array.isArray(filters[key]) && filters[key].length
    ? filters[key].map((item: any) => item.id || item)
    : defaultValue

const filterOptions = (options: any[], key: string, list?: any[]) =>
  !list ? options : options.filter((option: any) => filterOptionMatch(option[key], list))

const filterOptionMatch = (listKey: any[], list: any[]): boolean =>
  listKey.some((value: any) =>
    list.some((item: any) =>
      item.includes(',') ? item.split(',').includes(`${value}`) : `${item}` === `${value}`,
    ),
  )

const FieldHandler = (
  ExtraVisiblesFilters: ExtraVisiblesFiltersTypes,
  filters: FilterTypes,
  fields: string[],
) => (fieldName: string) =>
  handlers[fieldName] ? handlers[fieldName](ExtraVisiblesFilters, filters, fields) : null

export { FieldHandler }
