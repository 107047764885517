import React, { useState } from 'react'
import {
  Separator,
} from 'components'
import styles from './EngagementDigitalClasses.module.css'
import Modal from 'plurall-modal'
import { Icon, Link, Button } from '@plurall/elo'


const AboutDigitalClassesData = () => {

  const [showDigitalClassesInfo, setShowDigitalClassesInfo] = useState(false)

  const handleToggleInfo = () => {
    setShowDigitalClassesInfo(!showDigitalClassesInfo)
  }

  const handleInfoClick = (e: Event) => {
    e.preventDefault()
    handleToggleInfo()
  }

  return (
    <>
      <div className={styles.info}>
        <Link onClick={handleInfoClick} data-test-id={'info-rules-link'}>
          <span className={styles.infoIcon}>
            <Icon type='subtleInformation' size='small' />
          </span>
          <span className={styles.infoText}>
            Quais aulas são contabilizadas neste relatório?
          </span>
        </Link>
      </div>

      {showDigitalClassesInfo &&
        <Modal
          width='55vw'
          overflow='auto'
          padding='0 20px'
          height='auto'
          title='Quais aulas são contabilizadas neste relatório?'
          closed={handleToggleInfo}
          maskOnClose={true}
        >
          <div className={styles.modal}>
            <section >
              <Separator className={styles.separator} />
              <div className={styles.subtitle}>
                <ul>
                  <li>Aulas criadas no Maestro.</li>
                  <li>Com duração de no mínimo 5 minutos e no máximo de 6 horas.</li>
                  <li>Com a presença do organizador e pelo menos um convidado.</li>
                </ul>
              </div>
            </section>
            <footer>
              <Separator className={styles.separator} />
              <div className={styles.modalCloseButton}>
                <Button onClick={handleToggleInfo} data-test-id={'info-rules-modal-btn-close'}>Fechar</Button>
              </div>
            </footer>
          </div>
        </Modal>
      }
    </>
  )
}

export default AboutDigitalClassesData
