import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'components'

import styles from './styles.module.css'

const InformationCard = ({ id, heading, text, chart, tooltip, className }) => (
  <Card className={className}>
    <div className={styles.content} data-test-id={id}>
      {chart && <div className={styles.chart}>{chart}</div>}
      <div className={styles.text}>
        {heading}
        {text}
      </div>
      {tooltip}
    </div>
  </Card>
)

InformationCard.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.node,
  text: PropTypes.node,
  chart: PropTypes.node,
  tooltip: PropTypes.node,
  className: PropTypes.string,
}

export default InformationCard
