import formatDate, {
  formatTime,
  secondsToHuman,
  secondsToHours,
  hoursMinutesToHuman,
} from 'utils/formatDate'
import capitalize from 'utils/capitalize'
import defaultSort from 'utils/defaultSort'

const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' }

const formatDigitalClassesOverview = klass => ({
  classesAverageTime: klass.averageClassesSeconds
    ? secondsToHuman(klass.averageClassesSeconds)
    : '-',
  classesTotal: klass.totalClasses || 0,
  classesTotalTime: klass.totalClassesSeconds
    ? hoursMinutesToHuman(klass.totalClassesSeconds)
    : '0 h',
})

const formatDigitalClassesStatsDisciplines = data =>
  data.map(klass => ({
    ...klass,
    classTimeToHuman: klass.totalClassesSeconds ? secondsToHuman(klass.totalClassesSeconds) : '-',
    classTime: secondsToHours(klass.totalClassesSeconds) || 0,
  }))

const formatEngagementDigitalClasses = data =>
  data.map(klass => ({
    ...klass,
    gradeName: klass.gradeName || ' – ',
    className: klass.className || ' – ',
    eventDate: klass.classDate || '-',
    classBeginEndTime:
      klass.classBegin && klass.classEnd
        ? `${formatTime(klass.classBegin)} às ${formatTime(klass.classEnd)}`
        : '-',
    classTimeDuration: klass.totalClassSeconds ? secondsToHuman(klass.totalClassSeconds) : '-',
  }))

const formatAttendessDigitalClasses = data =>
  data.map(digitalClass => ({
    ...digitalClass,
    gradeName: digitalClass.gradeName || ' – ',
    className: digitalClass.className || ' – ',
    classDate: digitalClass.eventDate
      ? formatDate(`${digitalClass.eventDate} 00:00:00`, dateOptions)
      : '-',
    classBeginEndTime:
      digitalClass.classBegin && digitalClass.classEnd
        ? `${formatTime(digitalClass.classBegin)} às ${formatTime(digitalClass.classEnd)}`
        : '-',
    classTimeDuration: digitalClass.totalClassSeconds
      ? secondsToHuman(digitalClass.totalClassSeconds)
      : '-',
    attendances: digitalClass.attendances
      .map((attendee, index) => ({
        ...attendee,
        attendeeName: attendee.attendeeName.toUpperCase(),
        totalTimeDuration: attendee.totalTime ? secondsToHuman(attendee.totalTime) : '-',
      }))
      .sort(defaultSort('attendeeName'))
      .map((item, index) => ({
        ...item,
        attendeeCount: index + 1,
      })),
  }))

const formatEngagementTeacher = data =>
  data.map(teacher => ({
    ...teacher,
    name: capitalize(teacher.name),
    disciplines: teacher?.disciplines?.sort().join(', ') || ' – ',
    lastAccess: teacher.lastAccess
      ? formatDate(`${teacher.lastAccess} 00:00:00`, dateOptions)
      : '-',
  }))

const formatEngagementStudent = data =>
  data.map(student => ({
    ...student.userInfo,
    name: capitalize(student.userInfo.name),
    gradeName: student?.userInfo?.gradeName || ' – ',
    className: student?.userInfo?.className || ' – ',
    lastAccess: student?.userInfo?.lastAccess
      ? formatDate(`${student.userInfo.lastAccess} 00:00:00`, dateOptions)
      : '-',
    doubtsPosted: student.totalDoubtsPosted,
    doubtsRead: student.totalDoubtsRead,
  }))

export {
  formatDigitalClassesOverview,
  formatDigitalClassesStatsDisciplines,
  formatEngagementDigitalClasses,
  formatAttendessDigitalClasses,
  formatEngagementTeacher,
  formatEngagementStudent,
}
