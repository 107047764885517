import React from 'react'
import { EngagementOverview, LastUpdateEngagement } from 'components'
import { EngagementRole } from 'types/enums'

interface EngagementDashboardProps {
  filters: EngagementFilter
}

const EngagementDashboard = ({ filters }: EngagementDashboardProps) => (
  <>
    <LastUpdateEngagement />
    <EngagementOverview role={EngagementRole.Professor} filters={filters} />
    <EngagementOverview role={EngagementRole.Aluno} filters={filters} />
  </>
)

export default EngagementDashboard
