/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import CsvDownloader from 'react-csv-downloader'
import { Link, Icon, Text } from '@plurall/elo'
import { orderBy } from 'natural-orderby'
import { showEloToast, removeEloToast } from 'utils/eloToast'
import { Spinner } from '@plurall/elo-beta'

class ExportCSV extends React.Component {
  constructor(props) {
    super(props)
    const { key, desc } = props.defaultSort || {}
    this.state = {
      sortingColumnKey: key,
      desc,
      exportingCSV: false,
    }
    this.getExportData = this.getExportData.bind(this)
  }

  setExportingCSV = exporting => {
    this.setState({ exportingCSV: exporting })
  }

  getExportData() {
    return new Promise(resolve => {
      this.prepareData()
      const mappedData = this.exportData.data.map(item => {
        const returnObj = {}
        this.exportData.columns.forEach(column => {
          returnObj[column.displayName] = item[column.id]
        })
        return returnObj
      })
      resolve(mappedData)
    })
  }

  exportData = { data: [], columns: [] }

  flatColumns() {
    return this.props.columns.flatMap(column => (column.columns ? column.columns : column))
  }

  sortedData() {
    const { data } = this.props
    const { sortingColumnKey, desc } = this.state

    return orderBy(data, [sortingColumnKey], [desc])
  }

  prepareData() {
    this.exportData = { data: [], columns: [] }
    window.PLURALL_TRACKER.track('export')
    const toastId = showEloToast('Exportando relatório, aguarde um momento', 'informative', false)
    try {
      this.setExportingCSV(true)
      const columns = this.flatColumns()
      columns.pop()
      const sorted = this.sortedData()
      sorted.map((row, rowIndex) =>
        columns.map(
          (column, columnIndex) => row.id && this.prepareRow(row, column, rowIndex, columnIndex),
        ),
      )
      removeEloToast(toastId)
      showEloToast('Relatório de desempenho por aluno foi exportado.', 'positive')
    } catch (error) {
      showEloToast('Erro ao exportar relatório de desempenho por aluno.', 'negative')
    } finally {
      this.setExportingCSV(false)
    }
  }

  prepareRow(row, column, rowIndex) {
    const { isFilterExamsOrStandardTests } = this.props
    const { accessor, key, parentKey } = column

    if (key === 'number') return
    if (!isFilterExamsOrStandardTests && (key === 'exam_time' || key === 'exam_status')) return

    let cellValue
    try {
      cellValue =
        typeof accessor === 'function' ? accessor(row, rowIndex) : row[parentKey || accessor]
    } catch (e) {
      cellValue = row[parentKey || accessor]
    }

    if (this.exportData.data[rowIndex] === undefined) this.exportData.data[rowIndex] = [{}]

    const cellContent = this.prepareCellContent(rowIndex, column, cellValue)

    this.prepareColumnsHeader(column, cellContent, cellValue)
  }

  prepareColumnsHeader(column, cellContent, cellValue) {
    const { csvHeaders, key, header } = column

    const colHeader = typeof csvHeaders === 'function' ? csvHeaders(cellValue) : { [key]: header }

    Object.keys(cellContent).forEach(k => {
      const obj = {
        id: k,
        displayName: colHeader[k] !== undefined ? colHeader[k] : k,
      }
      this.exportData.columns.push(obj)
    })
  }

  prepareCellContent(rowIndex, column, cellValue) {
    const { csvColumns, key, parentKey } = column

    let cellContent
    try {
      cellContent =
        typeof csvColumns === 'function' && typeof cellValue !== 'string'
          ? csvColumns(cellValue)
          : { [parentKey || key]: cellValue }
    } catch (e) {
      cellContent = { [parentKey || key]: cellValue }
    }

    this.exportData.data[rowIndex] = { ...this.exportData.data[rowIndex], ...cellContent }

    return cellContent
  }

  render() {
    const { className, text, filename, suffix, separator, noHeader } = this.props

    const { exportingCSV } = this.state

    if (exportingCSV) {
      return (
        <div className={className}>
          <Text>Exportando relatório </Text>
          <Spinner size='small' />
        </div>
      )
    }

    return (
      <div className={className}>
        <CsvDownloader
          noHeader={noHeader}
          datas={this.getExportData}
          separator={separator}
          suffix={suffix}
          filename={filename}
        >
          <Link secondary dataTestId='export'>
            <Text bold='True'>
              {text}
              <Icon type='download' size='small' />
            </Text>
          </Link>
        </CsvDownloader>
      </div>
    )
  }
}

ExportCSV.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  noHeader: PropTypes.bool,
  defaultSort: PropTypes.shape().isRequired,
  separator: PropTypes.string,
  suffix: PropTypes.string,
  filename: PropTypes.string,
  text: PropTypes.string,
  isFilterExamsOrStandardTests: PropTypes.bool,
}

export default ExportCSV
