import Filter from 'components/Filter'
import React from 'react'

interface EOFormatFilterProps {
  formats: Item[]
  selected?: Item
  onChange: (format: Item) => void
}

const EOFormatFilter = ({ formats, selected, onChange }: EOFormatFilterProps) => {
  return (
    <Filter
      items={formats}
      name='Tipo de Atividade'
      onChange={onChange}
      selected={selected && selected.id}
    />
  )
}

export default EOFormatFilter
