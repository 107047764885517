import capitalize from 'utils/capitalize'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './SchoolSelector.module.css'
import { Card, PageContainer } from 'components'
import { Redirect } from 'react-router'
import { SearchBox, Heading } from '@plurall/elo'

const SchoolSelector = ({ baseUrl, schools }) => {
  const [selectedSchoolId, setSelectedSchoolId] = React.useState(null)
  if (selectedSchoolId) {
    return <Redirect to={`${baseUrl}/escolas/${selectedSchoolId}`} />
  }

  const heading = `Você possui ${schools.length} escolas em sua rede.`
  const label = 'Digite e selecione o nome da escola da qual deseja gerar os relatórios.'

  const items = schools.map(({ id, name }) => ({
    id,
    value: capitalize(name),
  }))

  const handleClick = school => {
    setSelectedSchoolId(school.id)
  }

  return (
    <PageContainer>
      <div className={styles.content}>
        <div className={styles.lowerHeading}>
          <Heading size='normal'>Busque uma escola</Heading>
        </div>

        <Card>
          <div className={styles.innerHeading}>
            <Heading size='small'>{heading}</Heading>
          </div>

          <SearchBox
            dataTestId='search-school'
            notFoundMessage='Nenhuma escola encontrada'
            placeholder='Buscar uma escola'
            items={items}
            label={label}
            onClick={handleClick}
          />
        </Card>
      </div>
    </PageContainer>
  )
}

SchoolSelector.propTypes = {
  schools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
}

export default SchoolSelector
