import { Link } from '@plurall/elo'
import React, { useState } from 'react'
import AttendeesList from './AttendeesList/AttendeesList'

interface OpenModalAttendeesListProps {
  text: string
  title: string
  classId: number
  index: number
}

const OpenModalAttendeesList: React.FC<OpenModalAttendeesListProps> = ({
  text,
  title,
  classId,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (event: MouseEvent) => {
    event.preventDefault()
    window.PLURALL_TRACKER.track('digital-class-tab:modal-class_click')
    setIsOpen(true)
  }
  const handleClose = () => setIsOpen(false)

  return (
    <>
      <Link secondary onClick={handleOpen} dataTestId={`open-modal-attendees-${index + 1}`}>
        {text}
      </Link>

      {isOpen && <AttendeesList classId={classId} title={title} onClose={handleClose} />}
    </>
  )
}

export default OpenModalAttendeesList
