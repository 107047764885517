import React, { SFC } from 'react'
import styles from './Averages.module.css'
import { CircleChart, InformationCard } from 'components'
import { Heading, Icon, Text } from 'plurall-ui'
import { Tooltip } from '@plurall/elo'
import { FilterFields } from 'types/enums'

// tslint:disable:max-line-length
const doneAverageTooltipText =
  'É a quantidade média de questões feitas por aluno em relação à sua seleção.'
const doneRateTooltipText = 'É o percentual de questões feitas pela turma em relação à sua seleção.'
const correctRateTooltipText =
  'É o percentual de acertos das questões objetivas da turma em relação às questões feitas.'
// tslint:enable:max-line-length

interface Averages {
  correct: number
  done: number
  objectivesCorrect: number
  objectivesDone: number
  objectivesAverage: number
  doneAverage: number
}

interface Totals {
  objectiveQuestions: number
  discursiveQuestions: number
  readingActivities: number
  videos: number
  essay: number
}

interface AveragesProps {
  averages: Averages
  totals: Totals
  filters: any
}

const getClassText = (filters: any) => {
  return filters[FilterFields.klasses] && filters[FilterFields.klasses].length > 1
    ? 'das turmas'
    : 'da turma'
}

const Averages: SFC<AveragesProps> = ({ averages, totals, filters }) => {
  const { objectiveQuestions, discursiveQuestions, readingActivities, essay, videos } = totals
  const totalQuestions =
    (objectiveQuestions || 0) +
    (discursiveQuestions || 0) +
    (readingActivities || 0) +
    (essay || 0) +
    (videos || 0)

  const doneAbsolute = Math.round(averages.done)

  const doneAverage = totalQuestions <= 0 ? 0 : Math.round((averages.done / totalQuestions) * 100)

  const correctAverage =
    averages.objectivesAverage <= 0 ? 0 : Math.round(averages.objectivesAverage * 100)

  const classText = getClassText(filters)

  return (
    <div className={styles.averagesWrapper}>
      <div className={styles.informationCard}>
        <InformationCard
          id='students-done'
          heading={<Heading size='normal'>{`${doneAbsolute} de ${totalQuestions}`}</Heading>}
          text={<Text secondary>Média de questões feitas por aluno</Text>}
          tooltip={
            <Tooltip content={doneAverageTooltipText} position='right'>
              <Icon type='help' size='small' />
            </Tooltip>
          }
        />
      </div>

      <div className={styles.informationCard}>
        <InformationCard
          id='classes-done'
          chart={<CircleChart value={doneAverage} />}
          heading={<Heading size='normal'>{`${doneAverage}%`}</Heading>}
          text={<Text secondary>{`Taxa de conclusão ${classText}`}</Text>}
          tooltip={
            <Tooltip content={doneRateTooltipText}>
              <Icon type='help' size='small' />
            </Tooltip>
          }
        />
      </div>

      <div className={styles.informationCard}>
        <InformationCard
          id='classes-correct'
          chart={<CircleChart value={correctAverage} />}
          heading={
            <Heading size='normal'>{correctAverage === 0 ? '-' : `${correctAverage}%`}</Heading>
          }
          text={<Text secondary>{`Taxa de acertos ${classText}`}</Text>}
          tooltip={
            <Tooltip content={correctRateTooltipText} position='left'>
              <Icon type='help' size='small' />
            </Tooltip>
          }
        />
      </div>
    </div>
  )
}

export default Averages
