import defaultSort from 'utils/defaultSort'

const gradeBlacklist = ['EF1', 'EF2', 'EF3', 'EF4', 'EF5']

const removeDuplicates = (arr, prop) =>
  arr.filter((obj, index, self) => index === self.findIndex(elem => elem[prop] === obj[prop]))

const formatGrades = schoolData => {
  const grades = schoolData
    .filter(grade => grade.somosidGradeId > 0 && !gradeBlacklist.includes(grade.gradeName))
    .map(({ somosidGradeId, gradeName }) => ({
      id: somosidGradeId,
      name: gradeName,
    }))
    .sort(defaultSort('name'))

  return removeDuplicates(grades, 'id')
}

const formatClasses = schoolData => {
  const classes = schoolData
    .filter(grade => grade.somosidGradeId > 0)
    .map(({ somosidClassId, className, somosidGradeId }) => ({
      id: somosidClassId,
      name: className,
      gradeId: somosidGradeId,
    }))
    .sort(defaultSort('name'))

  return removeDuplicates(classes, 'id')
}

const formatDisciplines = schoolData => {
  const disciplines = schoolData
    .reduce(
      (acc, schoolClass) =>
        acc.concat(schoolClass.disciplines.map(({ id, name }) => ({ id, name }))),
      [],
    )
    .sort(defaultSort('name'))

  return removeDuplicates(disciplines, 'name')
}

const formatSchoolData = schoolData => ({
  grades: formatGrades(schoolData),
  classes: formatClasses(schoolData),
  disciplines: formatDisciplines(schoolData),
})

export default formatSchoolData
