import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'plurall-ui'
import { Notification, PageContainer, Layout } from 'components'
import styles from './NotificationPage.module.css'

const NotificationPage = ({ title, text, img }) => (
  <Layout>
    <PageContainer>
      <Notification
        title={title}
        subtext={
          text && (
            <div className={styles.subtext}>
              <Text secondary element='span'>
                {text}
              </Text>
            </div>
          )
        }
      >
        {img && <img src={img} alt={title} />}
      </Notification>
    </PageContainer>
  </Layout>
)

NotificationPage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
}

export default NotificationPage
