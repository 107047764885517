import React, { FunctionComponent } from 'react'
import styles from './DateFilter.module.css'
import { TextBox } from '@plurall/elo'
import currentYear from 'utils/currentYear'

const minimalDateLength = 5

interface ValidationResult {
  message?: string
  success: boolean
}

interface DateFilterProps {
  endOfDay?: boolean
  errorMessage?: string
  label: string
  lowerLimit?: Date
  onChange: (date?: Date) => void
  upperLimit?: Date
  value: Date
}

const formatDate = (date: string): Date =>
  new Date(
    `${currentYear()}/${date
      .split('/')
      .reverse()
      .join('/')}`,
  )

const DateFilter: FunctionComponent<DateFilterProps> = ({
  endOfDay,
  errorMessage,
  label,
  lowerLimit,
  onChange,
  upperLimit,
  value,
}) => {
  const handleChange = (date: string): void => {
    localStorage.removeItem('answerDate')

    const length = date.length
    if (length === 0) {
      onChange()
      return
    }

    if (length < minimalDateLength) {
      return
    }

    const formattedDate = formatDate(date)

    if (endOfDay) {
      formattedDate.setHours(23, 59, 59, 999)
    }

    localStorage.setItem('answerDate', formattedDate.toISOString())
    onChange(formattedDate)
  }

  const validate = (date: string): ValidationResult => {
    const tooShort = date.length < minimalDateLength
    const noBoundaries = !upperLimit && !lowerLimit

    if (tooShort || noBoundaries) {
      return { success: true }
    }

    const newDate = formatDate(date)

    const lowerThanLimit = lowerLimit && newDate < lowerLimit
    const higherThanLimit = upperLimit && newDate >= upperLimit

    if (lowerThanLimit || higherThanLimit) {
      return {
        message: errorMessage,
        success: false,
      }
    }

    return { success: true }
  }

  return (
    <div className={styles.filter} data-test-id={label}>
      <TextBox
        label={label}
        mask='date'
        mismatchMessage='Preencha a data com um formato válido.'
        onChange={handleChange}
        placeholder='dd/mm'
        validate={validate}
        value={value && value.toLocaleString('pt-BR', { month: '2-digit', day: '2-digit' })}
      />
    </div>
  )
}

export default DateFilter
