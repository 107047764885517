import React, { useState, useContext, useEffect } from 'react'
import {
  EngagementOverview,
  EngagementRanking,
  EngagementFilterBar,
  LastUpdateEngagement,
  Separator,
  EngagementTable,
  EngagementFilterHeading,
} from 'components'
import { EngagementRole } from 'types/enums'
import { getIds } from 'utils/itemBuilder'
import { LoggedUserContext } from 'utils/context'
import EngagementClient from 'utils/restClient'
import {
  exportReportXLSX,
  EXPORT_STATUS_MESSAGE,
  getFormattedDate,
  removeEloToast,
  showEloToast,
} from 'utils'
import { useExportLink } from 'hooks'
import styles from './Engagement.module.css'

interface EngagementTeachersProps {
  selectedSchool: School
  filters: EngagementFilter
  onFiltersChange: (filters: EngagementFilter) => void
}

const client = new EngagementClient()

const EngagementTeachers = ({
  selectedSchool,
  filters,
  onFiltersChange,
}: EngagementTeachersProps) => {
  const user = useContext(LoggedUserContext)
  const { renderExportLink } = useExportLink()

  const [loading, setLoading] = useState<boolean>(true)
  const [exportLoading, setExportLoading] = useState(false)
  const [engagement, setEngagement] = useState<EngagementTeacher[]>([])

  useEffect(() => {
    const getEngagement = async () => {
      setLoading(true)
      const newEngagement = await fetchEngagementTeacher(user, filters)

      setEngagement(newEngagement)
      setLoading(false)
    }

    getEngagement()
  }, [user, filters])

  const handleSubmit = (event: any) => {
    window.PLURALL_TRACKER.track('teachers-tab:filter')
    return onFiltersChange(event)
  }

  const handleExportEngagementTeachers = async (event: MouseEvent) => {
    event.preventDefault()
    window.PLURALL_TRACKER.track('teachers-tab:export')

    const toastId = showEloToast(EXPORT_STATUS_MESSAGE.LOADING)
    try {
      setExportLoading(true)
      const { request } = await client.fetchEngagementTeacherExport(
        user.uid,
        filters.school.id,
        filters.month.id,
        getIds(filters.disciplines),
      )

      const options = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }
      const date = new Date()
      const disciplines =
        Array.isArray(filters.disciplines) && filters.disciplines.length > 1
          ? `${filters.disciplines.length} disciplinas`
          : filters.disciplines?.map(discipline => discipline.value)
      const name = `Acessos_por_professor-${filters.month?.value}-${disciplines ||
        'Todas as Disciplinas'}-${getFormattedDate(date, options)}`

      removeEloToast(toastId)
      showEloToast(EXPORT_STATUS_MESSAGE.SUCCESS, 'positive')

      exportReportXLSX(request?.response, name)
    } catch (error) {
      removeEloToast(toastId)
      showEloToast(EXPORT_STATUS_MESSAGE.ERROR, 'negative')
    } finally {
      setExportLoading(false)
    }
  }

  return (
    <>
      <EngagementFilterBar
        role={EngagementRole.Professor}
        selectedSchool={selectedSchool}
        filters={filters}
        onSubmit={handleSubmit}
      />
      <Separator className={styles.separator} />
      <LastUpdateEngagement />
      <EngagementFilterHeading
        title='Professores'
        role={EngagementRole.Professor}
        filters={filters}
      />
      <EngagementOverview role={EngagementRole.Professor} filters={filters} />
      <EngagementRanking role={EngagementRole.Professor} loading={loading} data={engagement} />
      <EngagementFilterHeading
        title='Acessos por Professor'
        role={EngagementRole.Professor}
        filters={filters}
      />
      {engagement?.length > 0 && !loading && (
        <div className={styles['button-export']}>
          {renderExportLink(
            exportLoading,
            handleExportEngagementTeachers,
            'Exportar planilha',
            'Exportando planilha',
            'export-teachers',
          )}
        </div>
      )}
      <EngagementTable role={EngagementRole.Professor} loading={loading} data={engagement} />
    </>
  )
}

const fetchEngagementTeacher = async (user: User, filters: EngagementFilter) => {
  return await client.fetchEngagementTeacher(
    user.uid,
    filters.school.id,
    filters.month.id,
    getIds(filters.disciplines),
  )
}

export default EngagementTeachers
