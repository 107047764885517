import React from 'react'
import PropTypes from 'prop-types'

import { ProgressBar, Text } from 'plurall-ui'

import styles from './TasksDone.module.css'

const TasksDone = ({ available, done, progressBar, showPercentage }) => {
  const percentage = available > 0 ? Math.round((done / available) * 100) : 0

  return (
    <div className={styles.wrapper}>
      {progressBar ? (
        <div className={styles.progressBar}>
          <ProgressBar threshold={50} percentage={percentage}>
            {`${done} de ${available}`}
          </ProgressBar>
        </div>
      ) : (
        <Text element='span'>
          {done} de {available}
        </Text>
      )}
      {showPercentage && (
        <div className={styles.percentage}>
          <Text secondary>({percentage}%)</Text>
        </div>
      )}
    </div>
  )
}

TasksDone.propTypes = {
  done: PropTypes.number.isRequired,
  available: PropTypes.number.isRequired,
  progressBar: PropTypes.bool,
  showPercentage: PropTypes.bool,
}

export default TasksDone
