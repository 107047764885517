import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import { getOauthClient, setToken } from 'utils'

const LoginCallback = props => {
  // eslint-disable-next-line react/prop-types
  const { location } = props
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    const oauth = getOauthClient()
    const fullPath = `${location.pathname}${location.search}${location.hash}`
    oauth.token.getToken(fullPath).then(({ accessToken }) => {
      setToken(accessToken)
      setRedirect(true)
    })
  }, [location])

  if (redirect) {
    const search = queryString.parse(location.search)
    const href = search?.redirectTo && search?.redirectTo !== 'undefined' ? search.redirectTo : '/'
    window.location.href = href
    return null
  }

  return <div>Você tem que estar logado para acessar esta página</div>
}
LoginCallback.propTypes = {
  location: PropTypes.object,
}

export default LoginCallback
