import React, { useState } from 'react'
import { Toast } from '@plurall/elo-beta'

import styles from './styles.module.css'

interface ToastData {
  show: boolean
  message: string
  variant?: 'informative' | 'negative' | 'positive'
}

export const useToastInModal = () => {
  const [toast, setToast] = useState<ToastData>({ show: false, message: '', variant: undefined })

  const renderToast = () => {
    if (toast.show) {
      return (
        <div className={styles.toast}>
          <Toast
            variant={toast.variant}
            autoClose
            disableButtonClose={false}
            seconds={6}
            onDismiss={() => setToast({ show: false, message: '', variant: undefined })}
          >
            {toast.message}
          </Toast>
        </div>
      )
    }
  }

  return {
    setToast,
    renderToast,
  }
}
