import React from 'react'
import PropTypes from 'prop-types'

import { Card, Notification } from 'components'
import searchImage from 'images/search.svg'

const StartSearchCard = ({ title }) => (
  <Card>
    <Notification
      title={title}
      subtext='Selecione os filtros desejados e clique no botão “Buscar”.'
    >
      <img src={searchImage} alt='Inicie uma busca' />
    </Notification>
  </Card>
)

StartSearchCard.propTypes = {
  title: PropTypes.string.isRequired,
}

export default StartSearchCard
