const getCookieLoggedName = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'pull_request':
      return 'staging_shared_cookie'
    case 'development':
      return 'development_shared_cookie'
    case 'staging':
      return 'staging_shared_cookie'
    case 'production':
      return 'shared_cookie'
    default:
      return 'staging_shared_cookie'
  }
}

const getToken = () => {
  const r = document.cookie.match(`\\b${getCookieLoggedName()}=([^;]*)\\b`)
  return r ? r[1] : undefined
}

const setToken = token => {
  document.cookie = `logged_in=${true}; path=/; domain=.plurall.net`
  document.cookie = `${getCookieLoggedName()}=${token}; path=/; domain=.plurall.net`
}

const clearToken = () => {
  const cookies = document.cookie.split(';')

  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.plurall.net`
  })
}

export { getToken, setToken, clearToken }
