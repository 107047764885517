import React from 'react'
import { CardItem, CircleFill } from 'components'
import { Text } from '@plurall/elo'
import { EngagementRole } from 'types/enums'
import styles from './EngagementPositionRanking.module.css'

interface EngagementPositionRankingProps {
  data: EngagementPositionRanking
  expanded: boolean
  limit: number
  position: number
  role: EngagementRole
}

const EngagementPositionRanking = ({
  data,
  position,
  role,
  expanded = false,
  limit = 5,
}: EngagementPositionRankingProps) => {
  const labels =
    role === EngagementRole.Professor
      ? {
          plural: 'professores',
          singular: 'professor',
        }
      : {
          plural: 'alunos',
          singular: 'aluno',
        }

  const renderNames = () => {
    const more = data.engagements.length - limit
    const showMore = !expanded && more > 0
    const engagements = showMore ? data.engagements.slice(0, limit) : data.engagements

    return (
      <>
        {role === EngagementRole.Professor
          ? (engagements as EngagementTeacher[]).map(engagement => (
              <Text key={engagement.name} secondary>
                {engagement.name}
              </Text>
            ))
          : (engagements as EngagementStudent[]).map(engagement => (
              <Text key={engagement.name} secondary>
                {`${engagement.name} - ${engagement.gradeName} - ${engagement.className}`}
              </Text>
            ))}
        {showMore && (
          <Text secondary bold>
            + {more} {more > 1 ? labels.plural : labels.singular}
          </Text>
        )}
      </>
    )
  }

  return (
    <CardItem
      small
      id={`ranking-${position}`}
      verticalAlign='top'
      chart={<CircleFill small value={`${position}°`} />}
    >
      <Text secondary className={styles.title} size='big'>
        {data.engagements.length > 1 ? 'Acessaram' : 'Acessou'} {data.total}{' '}
        {data.total > 1 ? 'dias' : 'dia'}
      </Text>
      {renderNames()}
    </CardItem>
  )
}

export default EngagementPositionRanking
