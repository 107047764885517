/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { Link, Text, Icon } from '@plurall/elo'
import classnames from 'classnames'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

import styles from './styles.module.css'

const defaultSort = accessor => (rowA, rowB) => {
  const valA = rowA[accessor] || ''
  const valB = rowB[accessor] || ''

  return checkOrder(valA, valB)
}

const checkOrder = (valA, valB) => {
  if (valA === valB) {
    return 0
  }
  return valA < valB ? 1 : -1
}

class Table extends React.Component {
  constructor(props) {
    super(props)
    const { key, desc } = props.defaultSort || {}
    this.state = {
      page: 1,
      sortingColumnKey: key,
      desc,
    }
  }

  handlePagination = event => {
    event.preventDefault()
    this.setState({ page: this.state.page + 1 })
  }

  flatColumns() {
    return this.props.columns.flatMap(column => {
      if (column.columns) {
        return column.columns
      }
      return column
    })
  }

  handleSorting = ({ key, sortable }) => {
    if (!sortable) {
      return
    }

    const desc = this.state.sortingColumnKey === key && !this.state.desc
    window.PLURALL_TRACKER.track(`table-sort-${key}`)
    this.setState({ sortingColumnKey: key, desc })
  }

  sortedData() {
    const { data } = this.props
    const columns = this.flatColumns()
    const { sortingColumnKey, desc } = this.state
    const sortingColumn = columns.find(({ key }) => key === sortingColumnKey)
    const { sortFunction, accessor } = sortingColumn || {}
    let sortedData

    if (sortFunction) {
      sortedData = data.sort(sortFunction)
    } else {
      sortedData = data.sort(defaultSort(accessor))
    }

    if (desc) {
      return sortedData.reverse()
    }
    return sortedData
  }

  sortIcon(column) {
    let sortType

    if (!column.sortable) {
      return null
    }

    const { desc, sortingColumnKey } = this.state

    if (sortingColumnKey === column.key) {
      sortType = desc ? 'orderDescending' : 'orderAscending'
    } else {
      sortType = 'order'
    }

    return (
      <Icon
        data-test-id={`${column.key}-sort`}
        className={styles.sort}
        type={sortType}
        size='small'
      />
    )
  }

  headerCellClassNames(column) {
    return classnames(styles.tableHeaderCell, styles.cellContent, styles[column.align || 'left'], {
      [styles.sortable]: column.sortable,
    })
  }

  renderHeader() {
    const { columns } = this.props
    const tableHasSubheader = columns.some(col => !!col.columns)

    return (
      <div className={classnames(styles.header, this.props.headerClass)}>
        <table>
          <thead className={styles.tableHeader}>
            <tr>{this.props.columns.map(this.renderHeaderCell)}</tr>
            {tableHasSubheader && this.renderSubheader()}
          </thead>
        </table>
      </div>
    )
  }

  renderSubheader() {
    const subheadingColumns = this.props.columns.reduce(
      (acc, cur) => (cur.columns ? acc.concat(cur.columns) : acc),
      [],
    )

    return (
      <tr className={styles.tableSubheader}>
        {subheadingColumns.map(col => this.renderHeaderCell(col))}
      </tr>
    )
  }

  renderHeaderCell = column => {
    if (column.visible) {
      const cellHasSubheader = !!column.columns
      const rowSpan = cellHasSubheader ? 1 : 2
      const colSpan = cellHasSubheader ? column.columns.length : 1

      return (
        <th
          data-test-id={column.key}
          key={column.key}
          colSpan={colSpan}
          rowSpan={rowSpan}
          onClick={() => this.handleSorting(column)}
        >
          <span className={this.headerCellClassNames(column)} style={{ width: column.width }}>
            <Text element='span' size='small'>
              {column.header}
            </Text>
            {this.sortIcon(column)}
          </span>
        </th>
      )
    }
  }

  renderBody() {
    const { pageSize } = this.props
    const columns = this.flatColumns()

    const rows = this.sortedData().slice(0, pageSize * this.state.page)

    return (
      <div className={styles.table}>
        <table>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr
                key={row.key || row.id}
                className={styles.tableRow}
                data-test-id={`table-row-${rowIndex + 1}`}
              >
                {columns.map((column, columnIndex) =>
                  this.renderCell(row, column, rowIndex, columnIndex),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  renderCell(row, column, rowIndex, columnIndex) {
    if (column.visible) {
      const { accessor, cell } = column
      const cellValue = typeof accessor === 'function' ? accessor(row, rowIndex) : row[accessor]
      const cellContent =
        typeof cell === 'function' ? cell(cellValue) : <Text element='span'>{cellValue}</Text>

      return (
        <td key={columnIndex}>
          <div className={styles.tableCell} style={{ width: column.width }}>
            {cellContent}
          </div>
        </td>
      )
    }
  }

  renderPagination() {
    const { data, pageSize } = this.props
    const { page } = this.state
    const remainingEntries = data.length - page * pageSize

    if (remainingEntries <= 0) {
      return null
    }

    const quantity = remainingEntries < pageSize ? remainingEntries : pageSize
    const text = remainingEntries === 1 ? 'resultado' : 'resultados'

    return (
      <div className={styles.pagination}>
        <Link href={null} secondary onClick={this.handlePagination}>
          Carregar mais {quantity} {text}
        </Link>
      </div>
    )
  }

  render() {
    return (
      <ScrollSync>
        <>
          <ScrollSyncPane>{this.renderHeader()}</ScrollSyncPane>
          <ScrollSyncPane>{this.renderBody()}</ScrollSyncPane>
          {this.renderPagination()}
        </>
      </ScrollSync>
    )
  }
}

const cellPropTypes = {
  header: PropTypes.string.isRequired,
  accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  cell: PropTypes.func,
  width: PropTypes.number,
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      ...cellPropTypes,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          ...cellPropTypes,
        }),
      ),
    }).isRequired,
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  pageSize: PropTypes.number,
  defaultSort: PropTypes.shape({
    key: PropTypes.string.isRequired,
    desc: PropTypes.bool,
  }),
}

Table.defaultProps = {
  pageSize: 50,
}

export default Table
