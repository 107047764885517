import React, { FunctionComponent } from 'react'
import { InformationCard } from 'components'
import { Heading, Text } from '@plurall/elo'

import styles from './Stats.module.css'

interface InformationCardItem {
  percent: number
  subtitle: string
  title: string
  break?: boolean
  id: string
}

const getStats = (
  {
    discursiveQuestions,
    discursiveQuestionsDone,
    objectiveQuestions,
    objectiveQuestionsDone,
    objectiveQuestionsRight,
    essay,
    essayDone,
  }: StatsTotals,
) => {

  const statsWidgets: InformationCardItem[] = []

  if (objectiveQuestions > 0) {
    statsWidgets.push({
      id: 'objective-questions-done',
      percent: (objectiveQuestionsDone * 100 / objectiveQuestions),
      subtitle: `${objectiveQuestionsDone} de ${objectiveQuestions}`,
      title: 'Questões objetivas feitas',
    })

    statsWidgets.push({
      id: 'objective-questions-correct-done',
      percent: objectiveQuestionsDone > 0 ? (objectiveQuestionsRight * 100 / objectiveQuestionsDone) : 0,
      subtitle: `${objectiveQuestionsRight} de ${objectiveQuestionsDone}`,
      title: 'Acertos nas questões objetivas feitas',
    })
  }

  if (discursiveQuestions > 0) {
    statsWidgets.push({
      id: 'discursive-questions-done',
      percent: (discursiveQuestionsDone * 100 / discursiveQuestions),
      subtitle: `${discursiveQuestionsDone} de ${discursiveQuestions}`,
      title: 'Questões discursivas feitas',
    })
  }

  if (essay > 0) {
    statsWidgets.push({
      id: 'essay-done',
      percent: (essayDone * 100 / essay),
      subtitle: `${essayDone} de ${essay}`,
      title: 'Questões de redação feitas',
    })
  }

  return statsWidgets
}

const getTries = (
  attempts: StatsAttempt[],
  hasMultipleTries: boolean,
  objectiveQuestionsRight: number,
) => !hasMultipleTries ? [] : attempts.slice(0, 3).map(({
  attempt,
  correctObjectiveQuestions,
  correctObjectiveRate,
}: StatsAttempt) => ({
  break: attempt === 1,
  id: `objective-questions-correct-attempt-${attempt}`,
  percent: correctObjectiveRate,
  subtitle: `${correctObjectiveQuestions} de ${objectiveQuestionsRight}`,
  title: `Acertos na ${attempt}ª tentativa`,
}))

const Stats: FunctionComponent<StatsProps> = ({ attempts, totals, hasMultipleTries }) => {
  const widgets = [
    ...getStats(totals),
    ...getTries(attempts, hasMultipleTries, totals.objectiveQuestionsRight),
  ]
  const threeColumns = (widgets.length >= 5 || widgets.length === 3)
  const columnsClass = threeColumns ? styles.threeColumns : styles.twoColumns

  return (
    <div className={[styles.flexContainer, styles.cardsContainer].join(' ')}>
      {widgets.map((widget: InformationCardItem) => (
        <InformationCard
          id={widget.id}
          key={widget.id}
          heading={<Heading size='big'>{`${Math.round(widget.percent)}%`}</Heading>}
          className={[
            styles.cardItem,
            columnsClass,
            (widget.break && threeColumns ? styles.breakItem : null),
          ].join(' ')}
          text={
            <>
              <Text size='big' className={styles.flexContainer} secondary>
                <Text size='big' element='span' className={styles.innerText}>{widget.title}:</Text>
                <Text size='big' element='span' className={styles.innerText} bold>{widget.subtitle}</Text>
              </Text>
            </>
          }
        />
      ))}
    </div>
  )
}

export default Stats
