import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import { SchoolSelection } from 'components'
import { schoolsQuery } from 'graphql/queries'

import { useQuery } from 'react-apollo-hooks'
import { getReportTypeFromPath, getReportContextFormat, getReportBaseUrl } from 'utils/reportType'

interface SelectedSchoolContext {
  schools: ISchool[]
  selectedSchool: ISchool
  noResult: boolean
  setSelectedSchool: React.Dispatch<React.SetStateAction<ISchool>>
  renderSchoolSelection: () => JSX.Element
  showSchoolSelection: boolean
  formattedSchools: FormattedSchools[]
  handleSchoolChange: (schoolParam?: FormattedSchools) => Promise<void>
}

interface FormattedSchools {
  checked: boolean
  id: string | number
  label: string
}

const SelectedSchoolContext = createContext<SelectedSchoolContext>({} as SelectedSchoolContext)

export const SelectedSchoolProvider = ({ children }: { children: ReactNode }) => {
  const maxTotalSchools = 5

  const reportType = getReportTypeFromPath('')
  const contextFormat = getReportContextFormat(reportType)
  const baseUrl = getReportBaseUrl(reportType)
  const [schools, setSchools] = useState<ISchool[]>([])
  const [formattedSchools, setFormattedSchools] = useState<FormattedSchools[]>([])
  const [selectedSchool, setSelectedSchool] = useState<ISchool>({} as ISchool)
  const [showSchoolSelection, setShowSchoolSelection] = useState(true)
  const [noResult, setNoResult] = useState(false)

  const { loading, data, error } = useQuery(schoolsQuery, {
    errorPolicy: 'all',
    variables: { contextFormat },
  })

  const getSchools = () => {
    try {
      if (loading || error) return
      if (!data.me.schools) {
        setNoResult(true)
      } else {
        if (noResult) setNoResult(false)
        if (schools.length === 0) setSchools(data.me.schools)
        if (data.me.schools.length === 1 && !selectedSchool.id) {
          setSelectedSchool(data.me.schools[0])
        }
      }
    } catch {
      setNoResult(true)
    }
  }

  useEffect(() => {
    getSchools()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error])

  useEffect(() => {
    const formattedSchoolsToInput = schools?.map(school => ({
      checked: schools.length < maxTotalSchools ? schools[0].id === school.id : false,
      id: school.id,
      label: school.name,
    }))

    if (formattedSchools[0]?.id !== formattedSchoolsToInput[0]?.id) {
      setFormattedSchools(formattedSchoolsToInput)
    }
  }, [schools, formattedSchools])

  const handleChangeRadioSchool = (selectedIndex: number) => {
    const newSchools = formattedSchools.map((item, index) => ({
      ...item,
      checked: selectedIndex === index,
    }))
    setFormattedSchools(newSchools)
  }

  const handleConfirmSchool = async () => {
    await handleSchoolChange()
    setShowSchoolSelection(false)
  }

  const handleSchoolChange = async (schoolParam?: FormattedSchools) => {
    const checkedSchool = formattedSchools.find(school =>
      schoolParam ? school.id === schoolParam.id : school.checked,
    )
    const findedSchool = schools.find(school => school.id === checkedSchool?.id)

    if (findedSchool) {
      setSelectedSchool({
        ...findedSchool,
      })
    }
  }

  const renderSchoolSelection = () => {
    return (
      <SchoolSelection
        formattedSchools={formattedSchools}
        setFormattedSchools={setFormattedSchools}
        maxTotalSchools={maxTotalSchools}
        schools={schools}
        handleConfirmSchool={handleConfirmSchool}
        handleChangeRadioSchool={handleChangeRadioSchool}
      />
    )
  }

  return (
    <>
      <SelectedSchoolContext.Provider
        value={{
          formattedSchools,
          handleSchoolChange,
          noResult,
          renderSchoolSelection,
          schools,
          selectedSchool,
          setSelectedSchool,
          showSchoolSelection,
        }}
      >
        {children}
        {selectedSchool?.id && <Redirect to={`${baseUrl}/escolas/${selectedSchool.id}`} />}
      </SelectedSchoolContext.Provider>
    </>
  )
}

export const useSelectedSchoolContext = () => {
  const context = useContext(SelectedSchoolContext)

  if (!context) {
    throw new Error('useSelectedSchoolContext must be used within a SelectedSchoolProvider')
  }

  return context
}
