import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'

const CircleFill = ({ value, small }) => (
  <div className={classNames(styles.fill, { [styles.small]: small })}>
    <span className={styles.value}>{value}</span>
  </div>
)

CircleFill.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  small: PropTypes.bool,
}

CircleFill.defaultProps = {
  small: false,
}

export default CircleFill
