import React from 'react'
import { Text } from '@plurall/elo'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ReactComponent as ImageNotFound } from 'images/not-found.svg'

import styles from './styles.module.css'

const CardStatus = ({ status, message, className }) => {
  const loading = (
    <div className={classnames(className, styles.loader)}>
      <img
        className={styles.image}
        src={`${process.env.REACT_APP_ASSETS_URL}/images/loading.gif`}
        alt='Carregando'
      />
    </div>
  )

  const notFound = (
    <div className={classnames(className, styles.notFound)}>
      <ImageNotFound className={styles.image} alt='Não encontramos nenhum resultado' />
      {message && <Text secondary>{message}</Text>}
    </div>
  )

  return status === 'loading' ? loading : notFound
}

CardStatus.propTypes = {
  status: PropTypes.oneOf(['loading', 'not-found']),
  className: PropTypes.string,
  message: PropTypes.string,
}

CardStatus.defaultProps = {
  status: 'loading',
}

export default CardStatus
