const MillisecondsPerMinute: number = 60000
const MillisecondsPerHour: number = MillisecondsPerMinute * 60
const MillisecondsPerDay: number = MillisecondsPerHour * 24

const formatDateTime = (dateString: string) => {
  const formatedDate = formatDate(dateString)
  const formatedTime = formatTime(dateString)
  return `${formatedDate} às ${formatedTime}`
}

const formatDate = (
  dateString: string,
  dateOptions: any = { year: 'numeric', month: 'long', day: 'numeric' },
) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('pt-BR', dateOptions)
}

const formatTime = (
  dateString: string,
  timeOptions: any = { hour: '2-digit', minute: '2-digit' },
) => {
  const date = new Date(dateString)
  return date.toLocaleTimeString('pt-BR', timeOptions)
}

const isSameDate = (date1: Date, date2: Date) => date1.toDateString() === date2.toDateString()

const dateDiffToHuman = (dateStarted: Date, dateFinished: Date) => {
  const dateDiffInMilliseconds = dateFinished.getTime() - dateStarted.getTime()
  if (dateDiffInMilliseconds > 0) {
    return millisecondsToHuman(dateDiffInMilliseconds)
  }
  return '--'
}

const secondsToHuman = (seconds: number) => {
  if (seconds > 0) {
    return millisecondsToHuman(seconds * 1000)
  }
  return '--'
}

const secondsToHumanHours = (seconds: number) => {
  const hours = Math.round(seconds / 3600)
  if (hours === 1) return `${hours} h`
  if (hours > 0) return `${hours} h`
  return 0
}

const hoursMinutesToHuman = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  return `${hours} h e ${minutes} min`
}

const secondsToHours = (seconds: number) => Math.round(seconds / 3600)

const millisecondsToHuman = (milliseconds: number) => {
  const days = Math.trunc(milliseconds / MillisecondsPerDay)
  milliseconds -= days * MillisecondsPerDay

  let hours = Math.trunc(milliseconds / MillisecondsPerHour)
  milliseconds -= hours * MillisecondsPerHour

  let minutes = Math.ceil(milliseconds / MillisecondsPerMinute)

  if (minutes >= 60) {
    hours += 1
    minutes = 0
  }

  const daysText = formatDaysOfTime(days, hours > 0, minutes > 0)
  const hoursText = formatHoursOfTime(hours, minutes > 0)
  const minutesText = formatMinutesOfTime(minutes)
  return `${daysText}${hoursText}${minutesText}`
}

const formatDaysOfTime = (days: number, hasHours: boolean, hasMinutes: boolean): string => {
  const conjunction = formatConjunction(hasHours, hasMinutes)
  if (days > 1) {
    return `${days} dias${conjunction}`
  } else if (days > 0) {
    return `${days} dia${conjunction}`
  }
  return ''
}

const formatHoursOfTime = (hours: number, hasMinutes: boolean): string => {
  const conjunction = formatConjunction(false, hasMinutes)
  return hours > 0 ? `${hours} h${conjunction}` : ''
}

const formatMinutesOfTime = (minutes: number) => {
  return minutes > 0 ? `${minutes} min` : ''
}

const formatConjunction = (hasHours: boolean, hasMinutes: boolean) => {
  if (hasHours && hasMinutes) {
    return ', '
  } else if (hasHours || hasMinutes) {
    return ' e '
  }
  return ''
}

export default formatDate

export {
  dateDiffToHuman,
  formatDate,
  formatTime,
  formatDateTime,
  isSameDate,
  millisecondsToHuman,
  secondsToHours,
  secondsToHuman,
  secondsToHumanHours,
  hoursMinutesToHuman,
}
