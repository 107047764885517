import React, { SFC } from 'react'
import styles from './Cell.module.css'

interface CellProps {
  width?: number
}

const Cell: SFC<CellProps> = ({ children, width }) => (
  <td className={styles.cell} style={{ maxWidth: width, minWidth: width }}>
    {children}
  </td>
)

export default Cell
