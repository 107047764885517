import React from 'react'
import { ArrowButton } from '@plurall/elo'
import { animateScroll as scroll } from 'react-scroll'
import classnames from 'classnames'
import { throttle } from 'lodash'

import { PageContainer } from 'components'

import styles from './styles.module.css'

class ScrollToTopButton extends React.Component {
  state = { hide: true }

  componentDidMount() {
    document.addEventListener('scroll', throttle(this.handleScroll, 200))
    scroll.scrollToTop()
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  handleClick = () => {
    scroll.scrollToTop()
  }

  handleScroll = () => {
    const { hide } = this.state
    const pageOffset = window.pageYOffset

    if (pageOffset === 0 && !hide) {
      this.setState({ hide: true })
    } else if (pageOffset > 0 && hide) {
      this.setState({ hide: false })
    }
  }

  render() {
    const { hide } = this.state
    const className = classnames(styles.button, { [styles.hide]: hide })

    return (
      <PageContainer className={styles.container}>
        <ArrowButton
          dataTestId='page-scroll-up'
          className={className}
          direction='up'
          onClick={this.handleClick}
        />
      </PageContainer>
    )
  }
}

export default ScrollToTopButton
