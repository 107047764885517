import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Layout as LayoutElo } from '@plurall/layout'
import { ScrollToTopButton } from 'components'
import { getToken } from 'utils/token'
import { getMenuSubItem } from 'utils/reportType'
import { SlugType } from 'types/enums'

const Layout = ({ children, render }) => {
  const [menuSubItem, setMenuSubItem] = useState(SlugType.EstudoOrientado)

  useEffect(() => {
    if (sessionStorage.getItem('chosenPath')) {
      setMenuSubItem(getMenuSubItem(sessionStorage.getItem('chosenPath')))
    }
    return () => sessionStorage.removeItem('chosenPath')
  }, [])

  return (
    <LayoutElo
      menuURL={
        process.env.REACT_APP_PLURALL_MENU_API_URL
          ? `${process.env.REACT_APP_PLURALL_MENU_API_URL}/plurall`
          : ''
      }
      apiURL={process.env.REACT_APP_PLURALL_API_URL}
      applicationId={process.env.REACT_APP_PLURALL_ID_APPLICATION}
      graphqlURL={process.env.REACT_APP_SOMOS_GRAPHQL_URL}
      userToken={getToken()}
      service='reports'
      menuSubItem={menuSubItem}
      projectName='relatórios'
      msApiURL={process.env.REACT_APP_MS_API_URL}
      apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
      authDomain={process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}
      projectId={process.env.REACT_APP_FIREBASE_PROJECT_ID}
      storageBucket={process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}
      messagingSenderId={process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}
      appId={process.env.REACT_APP_FIREBASE_APP_ID}
      measurementId={process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}
    >
      <div style={{ width: '100%' }}>
        {render && render()}
        {children}
        <ScrollToTopButton />
      </div>
    </LayoutElo>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  render: PropTypes.func,
}

export default Layout
