import React, { FunctionComponent } from 'react'
import styles from './Filter.module.css'
import { Dropdown } from '@plurall/elo'
import { formatDate } from 'utils/formatDate'
import numericSort from 'utils/numericSort'

interface FilterProps {
  autoSelectSingleItem?: boolean
  disabled?: boolean
  items: Item[]
  multiSelect?: boolean
  name: string
  onChange: (args: any) => void
  selected?: number | string | any[]
  sortAsNumeric?: boolean
  buttonLabels?: { singular: string; plural: string }
  placeholder?: string
}

const Filter: FunctionComponent<FilterProps> = ({
  autoSelectSingleItem,
  disabled,
  items,
  multiSelect,
  name,
  onChange,
  selected,
  sortAsNumeric,
  buttonLabels = {
    plural: 'itens',
    singular: 'item',
  },
  placeholder,
}) => {
  const itemsToRender = getItemsToRender(items)

  // TODO - A API deveria retornar os resultados ordenados.
  if (sortAsNumeric) {
    itemsToRender.sort(numericSort('id'))
  }

  const handleChange = (value: any) => {
    if (multiSelect) {
      if (!!itemsToRender[0]?.items) {
        const selectendItemsToRender = itemsToRender
          .flatMap(item => item.items!)
          .filter(item => value.includes(item.id))
        onChange(selectendItemsToRender)
        return
      }

      const selectedItems = itemsToRender.filter(item => value.includes(item.id))

      onChange(selectedItems)
      return
    }

    const selectedItem = items.find(item => item.id === value)

    onChange(selectedItem)
  }

  if ((!selected || (Array.isArray(selected) && !selected.length)) && autoSelectSingleItem) {
    selected = getAutoSelectSingleItem(itemsToRender, multiSelect, handleChange)
  }

  return (
    <div className={styles.filter} data-test-id={name}>
      <Dropdown
        placeholder={placeholder}
        buttonLabel={buttonLabels}
        disabled={disabled}
        items={itemsToRender}
        multiselect={multiSelect}
        name={name}
        onChange={handleChange}
        selected={selected || (multiSelect ? [] : undefined)}
      />
    </div>
  )
}

const getItemsToRender = (items: Item[]) => {
  if (!items) return []

  if (items.some(item => !!item.close_at)) return items.map(formatCloseAt)

  return [...items]
}

const formatCloseAt = (item: Item) => {
  const today = new Date()
  const dateOptions = { day: '2-digit', month: '2-digit' }

  if (!!item.close_at) {
    const closeAtDate = new Date(`${item.close_at}T03:00:00.000`)
    const formattedDate = formatDate(closeAtDate.toString(), dateOptions)
    const textCloseAt = closeAtDate < today ? 'Fechado' : 'Fecha'
    const value = `${item.value} (${textCloseAt} em ${formattedDate})`

    return { ...item, value }
  }
  return item
}

const getAutoSelectSingleItem = (
  items: Item[],
  multiSelect: boolean = false,
  handleChange: (args: any) => void,
) => {
  const isCategorizedAndSingleItem = items.length === 1 && !!items[0].items
  return isCategorizedAndSingleItem
    ? getIfSingleItem(items[0].items, multiSelect, handleChange)
    : getIfSingleItem(items, multiSelect, handleChange)
}

const getIfSingleItem = (
  items: Item[] = [],
  multiSelect: boolean = false,
  handleChange: (args: any) => void,
) => {
  if (items.length === 1) {
    const value = multiSelect ? [items[0].id] : items[0].id
    handleChange(value)
    return value
  }
}

export default Filter
