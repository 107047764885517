import React, { useContext } from 'react'
import {
  DigitalClassesByDisciplineChart,
  DigitalClassesWidgets,
  EngagementFilterBar,
  EngagementFilterHeading,
  EngagementDigitalClassesTable,
  LastUpdateEngagement,
  Separator,
} from 'components'
import { LoggedUserContext } from 'utils/context'
import { EngagementRole } from 'types/enums'
import styles from './EngagementDigitalClasses.module.css'
import AboutDigitalClassesData from './AboutDigitalClassesData'

interface EngagementStudentsProps {
  selectedSchool: School
  filters: EngagementFilter
  onFiltersChange: (filters: EngagementFilter) => void
}

const EngagementDigitalClasses = ({
  selectedSchool,
  filters,
  onFiltersChange,
}: EngagementStudentsProps) => {
  const user = useContext(LoggedUserContext)

  const handleSubmit = (event: any) => {
    window.PLURALL_TRACKER.track('digital-classes-tab:filter')
    return onFiltersChange(event)
  }

  return (
    <>
      <EngagementFilterBar
        role={EngagementRole.DigitalClasses}
        selectedSchool={selectedSchool}
        filters={filters}
        onSubmit={handleSubmit}
      />
      <Separator className={styles.separator} />

      <div className={styles.wrapIntructions}>
        <LastUpdateEngagement type='Aula Digital' />
        <div className={styles.wrapInfo}>
          <AboutDigitalClassesData />
        </div>
      </div>

      <DigitalClassesWidgets user={user} filters={filters} />

      <EngagementFilterHeading
        title='Duração total de aulas por disciplina'
        role={EngagementRole.DigitalClasses}
        filters={filters}
      />
      <DigitalClassesByDisciplineChart user={user} filters={filters} />

      <EngagementFilterHeading
        title='Lista de aulas digitais'
        role={EngagementRole.DigitalClasses}
        filters={filters}
      />
      <div className={styles.tableWrapper}>
        <EngagementDigitalClassesTable user={user} filters={filters} />
      </div>
    </>
  )
}

export default EngagementDigitalClasses
