import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

const PageContainer = ({ children, className }) => (
  <div className={`${styles.pageContainer} ${className}`}>{children}</div>
)

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default PageContainer
