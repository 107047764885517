import React, { useContext, useEffect, useState } from 'react'
import { Alert } from 'plurall-ui'

import { Loading } from 'components'
import { formatDate, formatTime, isSameDate } from 'utils/formatDate'
import { lastUpdateQuery } from 'graphql/queries'
import { ContextFormat } from 'utils/context'
import { graphqlClient } from 'utils/graphqlClient'

const getMessageForUpdateToday = lastUpdate =>
  `relatórios atualizados hoje, às ${formatTime(
    lastUpdate,
  )}. Próxima atualização prevista para amanhã, às 13:00.`

const getMessageForUpdatedYesterday = (lastUpdate, today) => {
  const expectedDay = today.getHours() < 9 ? 'hoje' : 'amanhã'

  return `relatórios atualizados ontem, às ${formatTime(
    lastUpdate,
  )}. Próxima atualização prevista para ${expectedDay}, às 13:00.`
}

const getMessageDefault = (lastUpdate, today) => {
  const dateOptions = { day: '2-digit', month: '2-digit' }
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
  const expectedDate = today.getHours() < 9 ? today : tomorrow

  return `relatórios atualizados no dia ${formatDate(lastUpdate, dateOptions)}, às ${formatTime(
    lastUpdate,
  )}. Próxima atualização prevista para o dia ${formatDate(expectedDate, dateOptions)}, às 13:00.`
}

const formatUpdateMessage = lastUpdate => {
  const dateUpdate = new Date(lastUpdate)
  const today = new Date()
  const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)

  if (isSameDate(today, dateUpdate)) {
    return getMessageForUpdateToday(lastUpdate)
  } else if (isSameDate(yesterday, dateUpdate)) {
    return getMessageForUpdatedYesterday(lastUpdate, today)
  }
  return getMessageDefault(lastUpdate, today)
}

const LastUpdate = () => {
  const contextFormat = useContext(ContextFormat)

  const [lastUpdate, setLastUpdate] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const getLastUpdate = async () => {
    try {
      const { loading: graphqlLoading, data } = await graphqlClient.query({
        query: lastUpdateQuery,
        variables: { contextFormat },
      })

      setLastUpdate(data.lastUpdate)
      setLoading(graphqlLoading)
    } catch {
      setError(true)
    }
  }

  useEffect(() => {
    getLastUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loading />
  if (error) return null

  return <Alert name='Importante'>{formatUpdateMessage(lastUpdate)}</Alert>
}
export default LastUpdate
