import React from 'react'
import PropTypes from 'prop-types'

import { Heading, Text } from '@plurall/elo-beta'

import styles from './ErrorMessage.module.css'

const ErrorMessage = ({ src, subTitle, title, secondLine, showNoDataState }) => (
  <div className={styles[showNoDataState ? 'no-data' : 'no-results']}>
    <div className={styles['title-wrapper']}>
      <Heading element='h4'>{title}</Heading>
    </div>
    {src && <img src={src} alt='Sem resultados' width='180' />}
    <div className={styles.subtitle}>{subTitle && <Text secondary>{subTitle}</Text>}</div>
    {secondLine && (
      <div className={styles.subtitle}>{subTitle && <Text secondary>{secondLine}</Text>}</div>
    )}
  </div>
)

ErrorMessage.defaultProps = {
  src: `${process.env.REACT_APP_ASSETS_URL}/images/tired.svg`,
  title: 'Corremos e voamos, mas não encontramos o que você busca.',
}

ErrorMessage.propTypes = {
  src: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  secondLine: PropTypes.string,
  showNoDataState: PropTypes.bool,
}

export default ErrorMessage
