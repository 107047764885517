import { loader } from 'graphql.macro'

export const schoolsQuery = loader('./schoolsQuery.graphql')
export const lastUpdateQuery = loader('./lastUpdateQuery.graphql')
export const userTasksQuery = loader('./userTasksQuery.graphql')
export const userTasksDisciplinesQuery = loader('./userTasksDisciplinesQuery.graphql')
export const studentsTotalsQuery = loader('./studentsTotalsQuery.graphql')
export const eoTasksQuery = loader('./eoTasksQuery.graphql')
export const examTasksQuery = loader('./examTasksQuery.graphql')
export const eoStudentsQuery = loader('./eoStudentsQuery.graphql')
export const examStudentsQuery = loader('./examStudentsQuery.graphql')
export const getGradesAndClasses = loader('./getGradesAndClasses.graphql')
