import React, { SFC } from 'react'
import styles from './Letter.module.css'
import { Letter as PlurallLetter } from 'plurall-icons'

interface LetterProps {
  label: string
}

const Letter: SFC<LetterProps> = ({ label }) => (
  <div className={styles.letter}>
    <PlurallLetter label={label} size='small' />
  </div>
)

export default Letter
