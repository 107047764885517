import React, { FunctionComponent } from 'react'

import styles from './ThreeDotsCircle.module.css'

const ThreeDotsCircle: FunctionComponent = () => (
  <div className={styles.circle}>
    <span className={styles.dot}></span>
    <span className={styles.dot}></span>
    <span className={styles.dot}></span>
  </div>
)

export default ThreeDotsCircle
