import React, { useContext, useEffect, useState } from 'react'
import EngagementClient from 'utils/restClient'
import { humanizedMonthAndYear } from 'utils/engagement'
import { EngagementRole } from 'types/enums'
import { Text } from '@plurall/elo'
import { LoggedUserContext } from 'utils/context'
import {
  Card,
  CardItem,
  CardStatus,
  CircleChart,
  CircleFill,
  Evolution,
  Separator,
} from 'components'
import {
  totalAccessedImprovementMonth,
  totalAccessedMonthPercentage,
  totalAccessedPercentage,
} from './helpers'
import { getIds } from 'utils/itemBuilder'
import styles from './styles.module.css'

interface EngagementOverviewProps {
  role: EngagementRole
  filters: EngagementFilter
}

const EngagementOverview = ({ role, filters }: EngagementOverviewProps) => {
  const user = useContext(LoggedUserContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [overview, setOverview] = useState<EngagementOverview>({} as EngagementOverview)

  useEffect(() => {
    const getOverview = async () => {
      setLoading(true)
      const newOverview = await fetchEngagementOverview(user, role, filters)

      if (newOverview) {
        setOverview({
          ...newOverview,
          month: filters.month.id,
          totalAccessedImprovementMonth: totalAccessedImprovementMonth(newOverview),
          totalAccessedMonthPercentage: totalAccessedMonthPercentage(newOverview),
          totalAccessedPercentage: totalAccessedPercentage(newOverview),
        })
        setLoading(false)
      }
    }

    getOverview()
  }, [role, user, filters])

  const label = role === EngagementRole.Professor ? 'Professores' : 'Alunos'

  return (
    <>
      <Card className={styles.card}>
        <Text secondary className={styles.caption} size='big'>
          Acesso Geral
        </Text>
        <Separator />
        {loading ? (
          <CardStatus />
        ) : (
          <div className={styles.items}>
            {renderUserRegistered(label, overview)}
            {renderUserAccessActive(label, overview)}
            {renderUserAccessMonth(label, overview)}
          </div>
        )}
      </Card>
    </>
  )
}

const renderUserRegistered = (label: string, overview: EngagementOverview) => {
  return (
    <CardItem
      id={`${label.toLowerCase()}-total-registered`}
      chart={<CircleFill value={overview.totalRegistered} />}
    >
      <Text secondary>{label} em sua escola</Text>
    </CardItem>
  )
}

const renderUserAccessActive = (label: string, overview: EngagementOverview) => {
  return (
    <CardItem
      id={`${label.toLowerCase()}-total-accessed`}
      chart={
        <CircleChart value={overview.totalAccessed} maximum={overview.totalRegistered} showValue />
      }
    >
      {overview.totalAccessed > 0 && overview.totalRegistered === overview.totalAccessed ? (
        <Text secondary>
          Parabéns! Todos os {label.toLowerCase()} realizaram o primeiro acesso no Plurall.
        </Text>
      ) : (
        <Text secondary>
          {label} que realizaram o primeiro acesso no Plurall&nbsp;
          <strong>({Math.round(overview.totalAccessedPercentage)}%)</strong>.
        </Text>
      )}
    </CardItem>
  )
}

const renderUserAccessMonth = (label: string, overview: EngagementOverview) => {
  const showEvolution = overview.month > 1 && !isNaN(overview.totalAccessedImprovementMonth)
  const labelMonthAndYear = humanizedMonthAndYear(overview.month)

  return (
    <CardItem
      id={`${label.toLowerCase()}-total-accessed-month`}
      chart={
        <CircleChart
          value={overview.totalAccessedMonth}
          maximum={overview.totalRegistered}
          showValue
        />
      }
    >
      <Text secondary>
        {`${label} que acessaram o Plurall em ${labelMonthAndYear}`}&nbsp;
        <strong>({Math.round(overview.totalAccessedMonthPercentage)}%)</strong>.
      </Text>
      {showEvolution && renderUserAccessMonthEvolution(overview.totalAccessedImprovementMonth)}
    </CardItem>
  )
}

const renderUserAccessMonthEvolution = (value: number) => (
  <div className={styles.evolution}>
    <Evolution value={value} label='comparado ao mês anterior.' />
  </div>
)

const fetchEngagementOverview = async (
  user: User,
  role: EngagementRole,
  filters: EngagementFilter,
) => {
  const client = new EngagementClient()
  return await client.fetchEngagementOverview(
    role,
    user.uid,
    filters.school.id,
    filters.month.id,
    getIds(filters.disciplines),
    getIds(filters.grades),
    getIds(filters.classes),
  )
}

export default EngagementOverview
