import React, { useState, useContext, useEffect } from 'react'
import {
  EngagementOverview,
  EngagementRanking,
  EngagementFilterBar,
  LastUpdateEngagement,
  Separator,
  EngagementTable,
  EngagementFilterHeading,
  DoubtsByDisciplineChart,
} from 'components'
import { EngagementRole } from 'types/enums'
import { getIds } from 'utils/itemBuilder'
import { LoggedUserContext } from 'utils/context'
import EngagementClient from 'utils/restClient'
import { useExportLink } from 'hooks'
import {
  exportReportXLSX,
  getFormattedDate,
  showEloToast,
  removeEloToast,
  EXPORT_STATUS_MESSAGE,
} from 'utils'
import styles from './Engagement.module.css'

interface EngagementStudentsProps {
  selectedSchool: School
  filters: EngagementFilter
  onFiltersChange: (filters: EngagementFilter) => void
}
const client = new EngagementClient()

const EngagementStudents = ({
  selectedSchool,
  filters,
  onFiltersChange,
}: EngagementStudentsProps) => {
  const user = useContext(LoggedUserContext)
  const { renderExportLink } = useExportLink()

  const [loading, setLoading] = useState<boolean>(true)
  const [exportLoading, setExportLoading] = useState(false)
  const [engagement, setEngagement] = useState<EngagementStudent[]>([])
  const doubtsAvailable = doubtsIsAvailable(selectedSchool)

  useEffect(() => {
    const getEngagement = async () => {
      setLoading(true)
      const newEngagement = await fetchEngagementStudent(user, filters)

      setEngagement(newEngagement)
      setLoading(false)
    }

    getEngagement()
  }, [user, filters])

  const handleSubmit = (event: any) => {
    window.PLURALL_TRACKER.track('students-tab:filter')
    return onFiltersChange(event)
  }

  const handleExportEngagementStudent = async (event: MouseEvent) => {
    event.preventDefault()
    window.PLURALL_TRACKER.track('students-tab:export')

    const toastId = showEloToast(EXPORT_STATUS_MESSAGE.LOADING)
    try {
      setExportLoading(true)
      const { request } = await client.fetchEngagementStudentExport(
        user.uid,
        filters.school.id,
        filters.month.id,
        getIds(filters.grades),
        getIds(filters.classes),
      )

      const options = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }
      const date = new Date()
      const grades =
        Array.isArray(filters.grades) && filters.grades.length > 1
          ? `${filters.grades.length} séries`
          : filters.grades?.map(grade => grade.value)
      const classes =
        Array.isArray(filters.classes) && filters.classes.length > 1
          ? `${filters.classes.length} turmas`
          : filters.classes?.map(klass => klass?.value)
      const name = `Acessos_por_alunos-${filters.month?.value}-${grades ||
        'Todas as Séries'}-${classes || 'Todas as Turmas'}-${getFormattedDate(date, options)}`

      removeEloToast(toastId)
      showEloToast(EXPORT_STATUS_MESSAGE.SUCCESS, 'positive')

      exportReportXLSX(request?.response, name)
    } catch (error) {
      removeEloToast(toastId)
      showEloToast(EXPORT_STATUS_MESSAGE.ERROR, 'negative')
    } finally {
      setExportLoading(false)
    }
  }

  return (
    <>
      <EngagementFilterBar
        role={EngagementRole.Aluno}
        selectedSchool={selectedSchool}
        filters={filters}
        onSubmit={handleSubmit}
      />
      <Separator className={styles.separator} />
      <LastUpdateEngagement />
      <EngagementFilterHeading title='Alunos' role={EngagementRole.Aluno} filters={filters} />
      <EngagementOverview role={EngagementRole.Aluno} filters={filters} />
      <EngagementRanking role={EngagementRole.Aluno} loading={loading} data={engagement} />
      {doubtsAvailable && (
        <EngagementFilterHeading
          title='Dúvidas dos alunos por disciplina'
          role={EngagementRole.Aluno}
          filters={filters}
        />
      )}
      {doubtsAvailable && <DoubtsByDisciplineChart filters={filters} />}
      <div className={styles['filters-students']}>
        <EngagementFilterHeading
          title='Acessos por Aluno'
          role={EngagementRole.Aluno}
          filters={filters}
        />
      </div>
      {engagement?.length > 0 && !loading && (
        <div className={styles['button-export']}>
          {renderExportLink(
            exportLoading,
            handleExportEngagementStudent,
            'Exportar planilha',
            'Exportando planilha',
            'export-students',
          )}
        </div>
      )}
      <EngagementTable
        role={EngagementRole.Aluno}
        loading={loading}
        data={engagement}
        doubtsAvailable={doubtsAvailable}
      />
    </>
  )
}

const fetchEngagementStudent = async (user: User, filters: EngagementFilter) => {
  return await client.fetchEngagementStudent(
    user.uid,
    filters.school.id,
    filters.month.id,
    getIds(filters.grades),
    getIds(filters.classes),
  )
}

const doubtsIsAvailable = (selectedSchool: School) => {
  const DoubtServiceId = 1
  return selectedSchool.services.some(service => service.id === DoubtServiceId)
}

export default EngagementStudents
