import React, { useState } from 'react'
import { Tabs, PageContainer, EngagementDashboard, EngagementTeachers } from 'components'
import { Route } from 'react-router-dom'
import { getReportBaseUrl } from 'utils/reportType'
import { ReportType } from 'types/enums'
import { availableMonths } from 'utils/engagement'
import { buildItem } from 'utils/itemBuilder'
import EngagementStudents from '../EngagementStudents'
import EngagementDigitalClasses from '../EngagementDigitalClasses'
import styles from './EngagementTabs.module.css'
import NotificationPage from '../../NotificationPage/NotificationPage'
import searchImage from 'images/search.svg'

interface EngagementTabsProps {
  selectedSchool: School
  noResult: boolean
}

const EngagementTabs = ({ selectedSchool, noResult }: EngagementTabsProps) => {
  const filters: EngagementFilter = {
    month: availableMonths().pop() || ({} as Item),
    months: [availableMonths().pop()] as Item[],
    school: buildItem(selectedSchool),
  }
  const [filtersTeachers, setFiltersTeachers] = useState<EngagementFilter>(filters)
  const [filtersStudents, setFiltersStudents] = useState<EngagementFilter>(filters)
  const [filtersDigitalClasses, setFiltersDigitalClasses] = useState<EngagementFilter>(filters)

  const baseUrl = getReportBaseUrl(ReportType.Engajamento)

  const links = [
    {
      exact: true,
      label: 'Visão Geral',
      location: baseUrl,
    },
    {
      exact: true,
      label: 'Professores',
      location: `${baseUrl}/professores`,
    },
    {
      exact: true,
      label: 'Alunos',
      location: `${baseUrl}/alunos`,
    },
    {
      exact: true,
      label: 'Aula Digital',
      location: `${baseUrl}/aula-digital`,
    },
  ]

  return (
    <>
      <Tabs className={styles.sticky} links={links} />

      {noResult ? (
        <NotificationPage
          title='Ainda não é possível visualizar o relatório. As turmas ainda não foram cadastradas e por isso não há dados para serem exibidos.'
          text='Peça ao Administrador da sua escola para cadastrar as turmas e adicionar alunos para realizarem atividades.'
          img={searchImage}
        />
      ) : (
        <>
          <Route
            path={baseUrl}
            exact
            render={() => (
              <PageContainer className={styles.container}>
                <EngagementDashboard filters={filters} />
              </PageContainer>
            )}
          />
          <Route
            path={`${baseUrl}/professores`}
            exact
            render={() => (
              <PageContainer className={styles.container}>
                <EngagementTeachers
                  selectedSchool={selectedSchool}
                  filters={filtersTeachers}
                  onFiltersChange={setFiltersTeachers}
                />
              </PageContainer>
            )}
          />
          <Route
            path={`${baseUrl}/alunos`}
            exact
            render={() => (
              <PageContainer className={styles.container}>
                <EngagementStudents
                  selectedSchool={selectedSchool}
                  filters={filtersStudents}
                  onFiltersChange={setFiltersStudents}
                />
              </PageContainer>
            )}
          />
          <Route
            path={`${baseUrl}/aula-digital`}
            exact
            render={() => (
              <PageContainer className={styles.container}>
                <EngagementDigitalClasses
                  selectedSchool={selectedSchool}
                  filters={filtersDigitalClasses}
                  onFiltersChange={setFiltersDigitalClasses}
                />
              </PageContainer>
            )}
          />
        </>
      )}
    </>
  )
}

export default EngagementTabs
