import React from 'react'
import { ArrowButton, Breadcrumb, Heading, Dropdown } from '@plurall/elo'
import { Link } from 'react-router-dom'
import { PageContainer } from 'components'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import capitalize from 'utils/capitalize'
import { buildItem } from 'utils/itemBuilder'

import styles from './styles.module.css'

const NavBar = ({ breadcrumb, title, backLink, schools, selectedSchool, onSchoolChange }) => {
  const capitalizedBreadcrumb =
    breadcrumb &&
    breadcrumb.map(({ href, text }) => ({
      href,
      text: text && capitalize(text),
    }))

  const searchBar = schools && schools.length > 1 && (
    <div className={styles.searchBar} data-test-id='schools'>
      <Dropdown
        items={schools.map(buildItem)}
        name='Alterar Escola'
        onChange={onSchoolChange}
        selected={selectedSchool && selectedSchool.id}
        position='right'
      />
    </div>
  )

  return (
    <header className={styles.navBar}>
      <PageContainer className={styles.navBarWrapper}>
        <div className={styles.heading}>
          {backLink && (
            <Link to={backLink} className={styles.backButton}>
              <ArrowButton direction='left' />
            </Link>
          )}
          <div>
            {capitalizedBreadcrumb && (
              <div className={classnames({ [styles.breadcrumb]: backLink })}>
                <Breadcrumb content={capitalizedBreadcrumb} />
              </div>
            )}
            <Heading>{capitalize(title)}</Heading>
          </div>
        </div>
        {searchBar}
      </PageContainer>
    </header>
  )
}

NavBar.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  backLink: PropTypes.string,
  title: PropTypes.string.isRequired,
  schools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired | PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  selectedSchool: PropTypes.shape({
    id: PropTypes.number.isRequired | PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onSchoolChange: PropTypes.func,
}

export default NavBar
