export enum TaskType {
  MultipleChoice = 'multiple_choice',
  OpenResponse = 'open_response',
  Read = 'read',
  Video = 'video',
  Essay = 'essay',
  Annuled = 'annuled',
}

export enum TaskTypeDiscipline {
  multiple_choice = 'objectiveQuestions',
  open_response = 'discursiveQuestions',
  read = 'readingActivities',
  video = 'videos',
  essay = 'essay',
}

export enum TaskStatus {
  Wrong = 'wrong',
  Correct = 'correct',
  Void = 'void',
  Answered = 'answered',
}

export enum TaskSequence {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export enum FilterFields {
  disciplines = 'disciplines',
  exams = 'exams',
  standardTests = 'standardTests',
  endDate = 'endDate',
  grade = 'grade',
  klasses = 'klasses',
  lessons = 'lessons',
  lists = 'lists',
  activities = 'activities',
  materials = 'materials',
  notebooks = 'notebooks',
  olimpicoLists = 'olimpicoLists',
  emTempoLists = 'emTempoLists',
  phs = 'phs',
  startDate = 'startDate',
  testTypes = 'testTypes',
}

export enum FormatFilters {
  ADAE = 'ADAEFilters',
  PH = 'PHFilters',
  TCONLINE = 'TCOnlineFilters',
  EM_TEMPO = 'EmTempoFilters',
  ETICO_TEST = 'ETICOTestFilters',
  ETICO = 'ETICOFilters',
  OLIMPICO = 'OLIMPICOFilters',
  PROVA = 'EXAMFilters',
  STANDARD_TEST = 'StandardTestFilters',
  AMPLIE_O_PERCURSO_TEST = 'AmplieOPercursoTestFilters',
  ELEVA_TEST = 'ElevaTestFilters',
  FIBONACCI_TEST = 'FibonacciTestFilters',
  MAXI_TEST = 'MaxiTestFilters',
  PAR_TEST = 'ParTestFilters',
  PITAGORAS_TEST = 'PitagorasTestFilters',
  PREPARATORIO_ENEM = 'PreparotorioEnemFilters',
  ATIIDADES_ENEM = 'AtividadesEnemFilters',
  CONECTE_LIVE = 'ConecteLiveFilters',
  ESTUDO_ORIENTADO_SOMOS = 'EstudoOrientadoSomosFilters',
  TRILHAS_RPP = 'TrilhasRppFilters',
  DESAFIO_DIGITAL = 'DesafioDigitalFilters',
  ROTA_DE_APROFUNDAMENTO = 'RotaDeAprofundamentoFilters',
  REVISA_ENEM = 'RevisaEnemFilters',
  TOP_5 = 'Top5Filters',
}

export enum ReportType {
  Provas = 'provas',
  Olimpico = 'olimpico',
  Engajamento = 'engajamento',
  EstudoOrientado = 'estudo-orientado',
  StandardTest = 'standard-test',
}

export enum EngagementRole {
  Aluno,
  DigitalClasses,
  Professor,
}

export enum Roles {
  Aluno = 'Aluno',
  Professor = 'Professor',
}

export enum SlugType {
  Olimpico = 'reports:olimpico',
  StandardTest = 'standard-tests',
  EstudoOrientado = 'reports:eo',
}
