const romanNumbers = /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/
const prepositions = /\b(?:d[aeo]s?)\b/i
const conjunctions = /\be\b/i
const specialCases = /\bLTDA\b/

const capitalizeWord = (word) => {
  if (word.match(prepositions) || word.match(conjunctions)) {
    return word.toLowerCase()
  }

  if (word.match(romanNumbers) || word.match(specialCases)) {
    return word
  }

  return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
}

const capitalize = str => str.replace(/[A-zÀ-ú]+/g, capitalizeWord)

export default capitalize
