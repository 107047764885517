import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const CircleChart = ({ value, maximum, showValue, percentSign }) => {
  const circumference = 100
  const pi = 3.14159
  const radius = circumference / (2 * pi)
  const diameter = 2 * radius
  const svgBoxLength = diameter + 5
  const initialX = svgBoxLength / 2
  const initialY = (svgBoxLength - diameter) / 2
  const percentage = maximum === 0 ? 0 : (value / maximum) * circumference

  return (
    <div className={styles.chart}>
      <svg viewBox={`0 0 ${svgBoxLength} ${svgBoxLength}`}>
        <path
          className={styles.innerCircle}
          strokeDasharray={`${circumference} ${circumference}`}
          d={`M${initialX} ${initialY}
            a ${radius} ${radius} 0 0 1 0 ${diameter}
            a ${radius} ${radius} 0 0 1 0 -${diameter}`}
        />
        <path
          className={styles.outerCircle}
          strokeDasharray={`${percentage} ${circumference}`}
          d={`M${initialX} ${initialY}
             a ${radius} ${radius} 0 0 1 0 ${diameter}
             a ${radius} ${radius} 0 0 1 0 -${diameter}`}
        />
        <text x={`${svgBoxLength / 2}`} y={`${svgBoxLength / 2}`} className={styles.percentage}>
          {showValue && (percentSign ? `${value.toFixed(1).replace('.', ',')}%` : value)}
        </text>
      </svg>
    </div>
  )
}

CircleChart.propTypes = {
  value: PropTypes.number.isRequired,
  maximum: PropTypes.number,
  percentSign: PropTypes.bool,
  showValue: PropTypes.bool,
}

CircleChart.defaultProps = {
  maximum: 100,
  percentSign: false,
  showValue: false,
}

export default CircleChart
