import Filter from 'components/Filter'
import React from 'react'

interface ClassFilterProps {
  selectedGrade?: Grade
  selected?: Klass[]
  onChange: (klasses: Klass[]) => void
}

const ClassFilter = ({ selectedGrade, selected, onChange }: ClassFilterProps) => {
  const buttonLabels = {
    plural: 'Turmas',
    singular: 'Turma',
  }

  return (
    <Filter
      disabled={!selectedGrade}
      items={selectedGrade ? selectedGrade.classes : []}
      multiSelect
      name='Turma'
      onChange={onChange}
      selected={selected && selected.map(({ id }) => id)}
      buttonLabels={buttonLabels}
    />
  )
}

export default ClassFilter
