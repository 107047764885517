/* eslint-disable quotes */
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@plurall/elo-beta'
import * as Sentry from '@sentry/react'
import { getToken } from 'utils/token'
import { ErrorMessage } from 'components'

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false, eventId: null }
  }

  async componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    const userLocal = localStorage.getItem(`_plurall_user_${getToken()}`)
    let userData = {}

    if (userLocal) {
      const user = JSON.parse(userLocal)
      userData = {
        id: user.somos_id,
        plurall_id: user.plurall_id,
        token: getToken(),
      }
    }

    Sentry.withScope(scope => {
      scope.setExtras({
        ...errorInfo,
        user: userData,
      })
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { hasError, eventId } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <>
          <ErrorMessage
            src={`${process.env.REACT_APP_ASSETS_URL}/images/plurall-buddy/buddy-worried.png`}
            title='Ops! Ocorreu um erro. Caso o erro persista, entre em contato com o suporte.'
          />
          {eventId && (
            <div style={{ textAlign: 'center' }}>
              <Button onClick={() => Sentry.showReportDialog({ eventId })}>
                Reportar Problema
              </Button>
            </div>
          )}
        </>
      )
    }

    return children
  }
}

export default ErrorBoundary
