import OpenTaskModalButton, { OpenTaskModalTrackType } from 'components/Table/OpenTaskModalButton'
import React from 'react'
import { Text, Tooltip } from '@plurall/elo'
import { dashConcatText } from 'utils/tasks'
import {
  examFinishedAtToHumanText,
  examTimeText,
  wasTheExamDelivered,
  wasDeliveredOnFilteredEndDate,
} from 'utils/exams'
import styles from './styles.module.css'

const answersColumn = {
  accessor: ({ name, id, class: { name: className }, grade: { name: gradeName } }: any) => ({
    className,
    gradeName,
    id,
    name,
  }),
  cell: ({ name, id, className, gradeName }: any) => (
    <OpenTaskModalButton
      text='Ver'
      title={name}
      subtitle={dashConcatText([gradeName, className])}
      studentId={id}
      showIcon
      trackType={OpenTaskModalTrackType.button}
    />
  ),
  header: 'Detalhes e respostas',
  key: 'answers',
  visible: true,
  width: 100,
}

const examStatusColumn = (visible: boolean, endDate: Date) => ({
  accessor: 'exams',
  cell: (exams: Exam[]) =>
    wasTheExamDelivered(exams) && wasDeliveredOnFilteredEndDate(exams, endDate) ? (
      <Text>Entregou {examFinishedAtToHumanText(exams)}</Text>
    ) : (
      <Text>
        <span className={styles.studentExamNotFinished}>Não entregou</span>
      </Text>
    ),
  csvColumns: (exams: Exam[]) => ({
    exam_status: wasTheExamDelivered(exams)
      ? `Entregou ${examFinishedAtToHumanText(exams)}`
      : 'Não entregou',
  }),
  csvHeaders: () => ({
    exam_status: 'Entrega',
  }),
  header: 'Entrega',
  key: 'exam_status',
  sortable: false,
  visible,
  width: 180,
})

const classColumn = (visible: boolean) => ({
  accessor: 'class',
  cell: ({ name }: { [key: string]: string }) => <Text>{name}</Text>,
  csvColumns: ({ name }: { [key: string]: string }) => ({ class_name: name }),
  csvHeaders: () => ({ class_name: 'Turma' }),
  header: 'Turma',
  key: 'className',
  sortable: false,
  visible,
  width: 180,
})

const numberColumn = {
  accessor: (_: any, index: number) => index + 1,
  header: 'Nº',
  key: 'number',
  visible: true,
  width: 50,
}

const nameColumn = {
  accessor: ({ name, id, class: { name: className }, grade: { name: gradeName } }: any) => ({
    className,
    gradeName,
    id,
    name,
  }),
  cell: ({ name, id, className, gradeName }: any) => (
    <div className={styles.truncatedWrapper}>
      <Tooltip content={name} position='right'>
        <Text className={styles.truncatedContent} element='span'>
          <OpenTaskModalButton
            title={name}
            subtitle={dashConcatText([gradeName, className])}
            text={name}
            studentId={id}
            showIcon={false}
            trackType={OpenTaskModalTrackType.name}
          />
        </Text>
      </Tooltip>
    </div>
  ),
  csvColumns: ({ name }: any) => ({
    student_name: name,
  }),
  csvHeaders: () => ({
    student_name: 'Nome',
  }),
  header: 'Nome',
  key: 'name',
  sortFunction: (rowA: any, rowB: any) => rowA.name.localeCompare(rowB.name),
  sortable: true,
  visible: true,
  width: 268,
}

const examTimeColumn = (visible: boolean) => ({
  accessor: 'exams',
  cell: (exams: Exam[]) => <Text>{examTimeText(exams)}</Text>,
  csvColumns: (exams: Exam[]) => ({
    exam_time: examTimeText(exams),
  }),
  csvHeaders: () => ({
    exam_time: 'Tempo de realização',
  }),
  header: 'Tempo de realização',
  key: 'exam_time',
  sortable: false,
  visible,
  width: 140,
})

export { answersColumn, classColumn, examStatusColumn, examTimeColumn, nameColumn, numberColumn }
