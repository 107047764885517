import * as Sentry from '@sentry/react'

import { getToken } from './token'

const {
  REACT_APP_NODE_ENV,
  REACT_APP_SENTRY_CLIENT,
  REACT_APP_SENTRY_ERROR_RATE,
  REACT_APP_SENTRY_TRACES_RATE,
} = process.env
const errorBlacklist = [
  'Failed to fetch',
  'GraphQL error: Authorization token',
  'getGraphQLActivesYears: usuário sem token.',
  'Load failed',
  'Network Error',
  'NetworkError',
  'Request failed with status code',
  'gtag',
  'Java exception was raised during method invocation',
  'UnknownError: Connection to Indexed Database server lost. Refresh the page to try again',
  'QuotaExceededError: Encountered full disk while opening backing store for indexedDB.open.',
  'GraphQL error: Tente acessar novamente na próxima atualização, amanhã após as 9 horas da manhã.',
  'getGraphQLPerson: usuário sem token.',
  'TimeoutError: Transaction timed out due to inactivity.',
]

let environment = REACT_APP_NODE_ENV
if (environment === 'pull_request' || environment === 'local') {
  environment = 'staging'
}

const beforeSendFiltering = (event, hint) => {
  if (hint?.originalException) {
    const message = `${hint.originalException?.message || hint?.originalException || ''}`
    const foundBlackListedTerm = !!errorBlacklist.filter(error =>
      message
        .trim()
        .toLowerCase()
        .includes(`${error || ''}`.trim().toLowerCase()),
    ).length
    if (foundBlackListedTerm) {
      return null
    }
  }
  return event
}

const setUser = () => {
  const userLocal = localStorage.getItem(`_plurall_user_${getToken()}`)
  if (userLocal) {
    const user = JSON.parse(userLocal)
    Sentry.setUser({
      email: user.mail,
      plurall_id: user.plurall_id,
      somos_id: user.somos_id,
    })
  }
}

const routes = [
  { path: '/' },
  { path: '/estudo-orientado' },
  { path: '/estudo-orientado/escolas/:id/' },
  { path: '/estudo-orientado/escolas/:id/por-tarefa' },
  { path: '/provas' },
  { path: '/provas/escolas/:id' },
  { path: '/standard-test' },
  { path: '/standard-test/escolas/:id/' },
  { path: '/olimpico' },
  { path: '/olimpico/escolas/:id/' },
  { path: '/escolas/:id' },
  { path: '/escolas/:id/por-tarefa' },
]

export function initSentry(history) {
  if (REACT_APP_SENTRY_CLIENT) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_CLIENT,
      environment,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes),
        }),
      ],
      sampleRate: +REACT_APP_SENTRY_ERROR_RATE,
      tracesSampleRate: +REACT_APP_SENTRY_TRACES_RATE,
      beforeSend: beforeSendFiltering,
      ignoreErrors: [
        'Failed to fetch',
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
      ],
    })
    setUser()
  }
}
