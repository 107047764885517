import Cell from 'components/NewTable/Cell'
import ProgressCell from 'components/NewTable/ProgressCell'
import React from 'react'
import styles from './TasksTable.module.css'
import Table from 'components/NewTable'
import { Card, Loading, CustomTabs } from 'components'

// @ts-ignore
import { Text } from '@plurall/elo-beta'
import { Icon, Checkbox, Tooltip } from '@plurall/elo'
import OpenQuestionDetailsModal from './OpenQuestionDetailsModal'
import { TRACKING_EVENT_NAME } from 'utils'
import { isReportEO, isReportOlimpico, isReportStandardTests } from 'utils/reportType'
import { dashConcatText } from 'utils/tasks'
import { TaskType } from 'types/enums'
import { useExportLink } from 'hooks/useExportLink'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
interface QuestionsTableProps {
  questions: Question[]
  contextFormat?: string[]
  gradeName?: string
  filters?: any
  fetchMore: any
  limit: number
  loading: boolean
  handleExportTasks: (event: MouseEvent) => void
  loadingExport: boolean
  fetchMoreShow: boolean
  offsetReset: boolean
  handleSortChange: (id: string, desc: boolean) => void
  taskTypesFiltersCheckbox: TaskTypesFiltersCheckboxParams[]
  setTaskTypesFilter: any
  taskTypesFilter: any
  setTaskTypesFilterscheckbox: any
  emptyQuestions: boolean
  setAnnulledCheckFilter: any
  hasAnnulledQuestions: boolean
}

interface QuestionsTableState {
  filter: string
  fetchMoreShow: boolean
}

const getTooltipCheckbox = (tipoCheck: string) =>
  `Selecione essa opção para exibir o desempenho da(s) turma(s) nas ${tipoCheck}`

const getNameQuestionType = (taskType: TaskType) => {
  switch (taskType) {
    case TaskType.MultipleChoice:
      return 'Objetiva'
    case TaskType.OpenResponse:
      return 'Discursivas'
    case TaskType.Video:
      return 'Vídeo'
    case TaskType.Read:
      return 'Leitura'
    case TaskType.Essay:
      return 'Redação'
    default:
      return 'Desconhecido'
  }
}

const getHeaders = (
  taskTypesFiltersCheckbox: TaskTypesFiltersCheckboxParams[],
  contextFormat?: string[],
): TableHeader[] => {
  const checkedMultipleChoice = !!taskTypesFiltersCheckbox.find(
    (check: TaskTypesFiltersCheckboxParams) => check.key.includes(TaskType.MultipleChoice),
  )?.checked
  const checkedOpenResponse = !!taskTypesFiltersCheckbox.find(
    (check: TaskTypesFiltersCheckboxParams) => check.key.includes(TaskType.OpenResponse),
  )?.checked
  const checkedEssay = !!taskTypesFiltersCheckbox.find((check: TaskTypesFiltersCheckboxParams) =>
    check.key.includes(TaskType.Essay),
  )?.checked
  const checkedAnnulled = !!taskTypesFiltersCheckbox.find((check: TaskTypesFiltersCheckboxParams) =>
    check.key.includes(TaskType.Annuled),
  )?.checked

  const hasCheckedTasksTypes = taskTypesFiltersCheckbox.filter(item => item.checked).length > 1

  const visible = visibleContextDoubts(contextFormat)

  return [
    { id: 'number', label: 'Nº', width: 80, sortable: false },
    { id: 'name', label: 'Questão', width: 150, sortable: true },
    { id: 'discipline-name', label: 'Disciplina', width: 200, sortable: true },
    { id: 'activity-nameAndType', label: 'Atividade', width: 250, sortable: true },
    { id: 'class-name', label: 'Turma', width: 150, sortable: true },
    {
      id: 'taskSummary-countRespondents',
      label: 'Alunos respondentes',
      sortable: true,
      width: 200,
    },
    {
      id: 'stats-doubts-doubtsPercentagePretty',
      label: 'Alunos que enviaram ou consultaram no mínimo uma dúvida',
      sortable: true,
      visible: visible && (checkedMultipleChoice || checkedOpenResponse || checkedEssay),
      width: 225,
    },
    {
      id: 'taskSummary-respondentsRatePretty',
      label: 'Taxa de acerto da turma',
      sortable: true,
      visible: checkedMultipleChoice || checkedAnnulled,
      width: 225,
    },
    {
      id: 'type',
      label: 'Tipo de questão',
      sortable: true,
      visible: visible && hasCheckedTasksTypes,
      width: 150,
    },
  ]
}

const visibleContextDoubts = (contextFormat?: string[]): boolean => {
  return (
    isReportEO(contextFormat) &&
    !isReportOlimpico(contextFormat) &&
    !isReportStandardTests(contextFormat)
  )
}

class TasksTable extends React.Component<QuestionsTableProps, QuestionsTableState> {
  constructor(props: Readonly<QuestionsTableProps>) {
    super(props)
    this.state = {
      filter: 'allTasks',
    } as QuestionsTableState
  }

  public render() {
    const { questions, emptyQuestions } = this.props

    if (emptyQuestions) {
      this.trackEmptyState()
    }

    const tasksByFilterType = this.tasksByFilterType(questions)
    const filteredTasks = this.filteredTasks(tasksByFilterType)
    const studentsTasks = filteredTasks.filter((task: Question) => task.taskSummary)

    return (
      <div className={styles.table}>
        {!emptyQuestions && this.renderTableFilter(this.tasksTableFilterOptions())}
        <Card>{this.renderCard(filteredTasks, studentsTasks)}</Card>
      </div>
    )
  }

  private trackEmptyState() {
    window.PLURALL_TRACKER.track(`error-page:no_data`)
  }

  private handleFilterTaskType = (id: string): void => {
    const { setTaskTypesFilter, setTaskTypesFilterscheckbox, setAnnulledCheckFilter } = this.props
    const isAnnulledType = id === TaskType.Annuled
    let checkedInputs: TaskType[] = []
    let newTaskTypesFiltersCheckbox: TaskTypesFiltersCheckboxParams[]

    newTaskTypesFiltersCheckbox = this.dealingWithCheckboxStatus(id)
    this.doesNotAllowTypesToRepeat(newTaskTypesFiltersCheckbox, checkedInputs)
    if (newTaskTypesFiltersCheckbox.every(filter => !filter.checked)) {
      checkedInputs = []
      this.redialTheFirstOption(newTaskTypesFiltersCheckbox, checkedInputs)
    }
    if (isAnnulledType) {
      newTaskTypesFiltersCheckbox = this.uncheckOptions()
      checkedInputs = [TaskType.Annuled]
    }

    setAnnulledCheckFilter(this.filterAnnulledQuestion(checkedInputs, isAnnulledType))
    setTaskTypesFilterscheckbox(...[newTaskTypesFiltersCheckbox])
    setTaskTypesFilter(checkedInputs)
  }

  private filterAnnulledQuestion = (checkedInputs: TaskType[], isAnnulledType: boolean) => {
    if (isAnnulledType) {
      return checkedInputs?.length > 1 ? [0, 1] : [1]
    }
    return [0]
  }

  private uncheckOptions = () => {
    const { taskTypesFiltersCheckbox } = this.props
    return taskTypesFiltersCheckbox.map(type => ({ ...type, checked: false }))
  }

  private dealingWithCheckboxStatus(id: string) {
    const { taskTypesFiltersCheckbox } = this.props

    return taskTypesFiltersCheckbox.map(filter => {
      const newFilter = { ...filter }

      if (newFilter.id === id) {
        newFilter.checked = !filter.checked
        this.setState({ filter: 'allTasks' })
        window.PLURALL_TRACKER.track(TRACKING_EVENT_NAME.QUESTION_TAB.CLICK_CHECKBOX, {
          taskType: filter.key,
        })
      }
      return newFilter
    })
  }

  private doesNotAllowTypesToRepeat(
    newTaskTypesFiltersCheckbox: TaskTypesFiltersCheckboxParams[],
    checkedInputs: TaskType[],
  ) {
    newTaskTypesFiltersCheckbox.forEach(filter => {
      if (filter.checked && !filter.key.some(key => checkedInputs.includes(key))) {
        checkedInputs.push(...filter.key)
      }
    })
  }

  private redialTheFirstOption(
    newTaskTypesFiltersCheckbox: TaskTypesFiltersCheckboxParams[],
    checkedInputs: TaskType[],
  ) {
    if (newTaskTypesFiltersCheckbox.length > 0) {
      newTaskTypesFiltersCheckbox[0].checked = true
      checkedInputs.push(...newTaskTypesFiltersCheckbox[0].key)
    }
  }

  private tasksByFilterType(tasks: Question[]) {
    return {
      allTasks: tasks,
      questionsThatAllStudentsAnswered: tasks.filter(
        (task: Question) => task.taskSummary?.countRespondents === task.taskSummary?.countStudents,
      ),
      questionsWithDoubts: tasks.filter(
        (task: Question) => task.stats && task.stats.doubts.read > 0,
      ),
      questionsWithNoneAnswered: tasks.filter(
        (task: Question) => task.taskSummary?.countRespondents === 0,
      ),
      questionsWithZeroHhits: tasks.filter(
        (task: Question) =>
          task.type === 'multiple_choice' &&
          // tslint:disable-next-line: radix
          task.taskSummary?.countRespondents > 0 &&
          task.taskSummary?.respondentsRatePretty === '0%',
      ),
    }
  }

  private filteredTasks(tasksByFilterType: any) {
    const { filter } = this.state
    return tasksByFilterType[filter]
  }

  private handleFetchMore = (offset: number) => {
    const { fetchMore } = this.props

    fetchMore(offset)
  }

  private renderTaskTypesFilterscheckbox() {
    const { taskTypesFiltersCheckbox } = this.props
    return (
      <>
        {taskTypesFiltersCheckbox?.map(filter => {
          return (
            <Tooltip content={getTooltipCheckbox(filter.typeTooltip)} position='top'>
              <div className={styles.checkbox}>
                <Checkbox
                  checked={filter.checked}
                  dataTestId={`checkBoxFilter${filter.id}`}
                  key={filter.label}
                  label={filter.label}
                  name={filter.id}
                  value={filter.label}
                  onChange={() => this.handleFilterTaskType(filter.id)}
                />
              </div>
            </Tooltip>
          )
        })}
      </>
    )
  }

  private renderTableSubheader() {
    return (
      <div className={styles.tableCaptionWrapper}>
        <div className={styles.tableFilterColumns}>{this.renderTaskTypesFilterscheckbox()}</div>
        <div className={styles.caption}>
          <Text bold shy element='span'>
            Legenda:
          </Text>

          <span className={styles.icon}>
            <Icon type='statsPositive' size='small' />
          </span>

          <Text shy element='span'>
            Igual ou acima de 50%
          </Text>

          <span className={styles.icon}>
            <Icon type='statsNegative' size='small' />
          </span>

          <Text shy element='span'>
            Abaixo de 50%
          </Text>
        </div>
      </div>
    )
  }

  private handleFilterTypeChange = (filter: string) => {
    this.handleFilterTaskType(filter)
    if (filter !== TaskType.Annuled) {
      this.setState({ filter })
    }
    window.PLURALL_TRACKER.track(TRACKING_EVENT_NAME.QUESTION_TAB.FILTER_QUESTION_BEHAVIOR, {
      filter,
    })
  }

  private getTabName = (tabId: string) => {
    switch (tabId) {
      case 'allTasks':
        return 'Todas as questões'
      case 'questionsWithDoubts':
        return 'Com dúvidas'
      case 'questionsWithNoneAnswered':
        return 'Não realizadas'
      case 'questionsThatAllStudentsAnswered':
        return 'Realizadas (Todos os alunos)'
      case 'questionsWithZeroHhits':
        return 'Sem acertos'
      default:
        return ''
    }
  }

  private links = (items: Array<{ id: string; value: string }>): CustomTabProps[] => {
    const { hasAnnulledQuestions } = this.props
    const tabLinks = items.map(item => ({
      label: this.getTabName(item.id),
      key: item.id,
    }))
    return hasAnnulledQuestions
      ? [...tabLinks, { label: 'Anuladas', key: TaskType.Annuled }]
      : tabLinks
  }

  private renderTableFilter(items: Array<{ id: string; value: string }>) {
    const { renderExportLink } = useExportLink()
    const { loadingExport, handleExportTasks } = this.props
    return (
      <div>
        <div className={styles.tableFilter}>
          <div className={styles.dropdown}>
            <CustomTabs
              tabs={this.links(items)}
              tabsClass='styles.tabs'
              onClick={this.handleFilterTypeChange}
            ></CustomTabs>
          </div>
          <div className={styles['button-export']}>
            {renderExportLink(
              loadingExport,
              handleExportTasks,
              'Exportar relatório',
              'Exportando relatório',
              'export-tasks',
            )}
          </div>
        </div>
        <div className={styles.filterDivider}></div>
      </div>
    )
  }

  private renderCard(filteredTasks: Question[], studentsTasks: Question[]) {
    const { emptyQuestions, taskTypesFiltersCheckbox, contextFormat, loading } = this.props
    const headers = getHeaders(taskTypesFiltersCheckbox, contextFormat)

    if (!loading && emptyQuestions) {
      return (
        <ErrorMessage
          src=''
          subTitle='Não há resultados para os filtros selecionados.'
          title='Nenhum resultado encontrado'
          secondLine={
            emptyQuestions ? 'Selecione outras opções de filtros e clique no botão "Buscar".' : ''
          }
          showNoDataState
        />
      )
    }
    if (!loading && studentsTasks.length === 0) {
      return (
        <ErrorMessage
          src=''
          subTitle='Não há alunos ativados nas turmas selecionadas.'
          title='Nenhum resultado encontrado'
          secondLine='Oriente a ativação das contas dos alunos para visualizar os dados ou selecione outras opções de filtros e clique no botão "Buscar".'
          showNoDataState
        />
      )
    }
    return this.renderCardTable(headers, filteredTasks)
  }

  private renderCardTable(headers: TableHeader[], filteredTasks: Question[]) {
    const {
      gradeName,
      filters,
      limit,
      fetchMoreShow,
      offsetReset,
      handleSortChange,
      taskTypesFilter,
      loading,
    } = this.props
    const totalCount = fetchMoreShow ? filteredTasks.length * 2 : filteredTasks.length
    const hasTasksTypes = taskTypesFilter?.length > 1
    return (
      <>
        {loading && <Loading />}
        {this.renderTableSubheader()}
        <Table
          scroll
          data={filteredTasks}
          headers={headers}
          limit={limit}
          totalCount={totalCount}
          onPageChange={this.handleFetchMore}
          offsetReset={offsetReset}
          onSortChange={handleSortChange}
        >
          {(
            {
              id,
              class: { name: className },
              discipline,
              name,
              taskSummary,
              stats,
              activity,
              type,
            }: any,
            index: number,
          ) => (
            <>
              <Cell width={headers[0].width}>{index + 1}</Cell>
              {this.renderCellTask(
                headers,
                id,
                name,
                filters,
                dashConcatText([name, discipline.name, activity.name]),
                dashConcatText([
                  gradeName,
                  className,
                  activity.material.name,
                  activity.notebook.name,
                  activity.type,
                ]),
                type,
              )}
              <Cell width={headers[2].width}>{discipline.name.replace(/\s+,/g, ',')}</Cell>
              <Cell width={headers[3].width}>{activity.name}</Cell>
              <Cell width={headers[4].width}>{className}</Cell>
              {this.renderCellStudents(headers, taskSummary)}
              {stats && this.renderCellDoubts(headers, type, stats)}
              {this.renderCellPerformance(headers, type, taskSummary)}
              {hasTasksTypes && this.renderCellQuestionType(headers, type)}
            </>
          )}
        </Table>
      </>
    )
  }

  private renderCellTask(
    headers: TableHeader[],
    id: string,
    name: string,
    filters: any,
    tilte: string,
    subtitle: string,
    type: string,
  ) {
    return (
      <OpenQuestionDetailsModal
        taskId={id}
        name={name}
        filters={filters}
        title={tilte}
        subtitle={subtitle}
        type={type}
        width={headers[1].width}
      />
    )
  }

  private renderCellStudents(headers: TableHeader[], taskSummary: any) {
    return (
      <ProgressCell
        current={taskSummary ? taskSummary.countRespondents : 0}
        hideProgress
        total={taskSummary ? taskSummary.countStudents : 0}
        width={headers[5].width}
      />
    )
  }

  private renderCellQuestionType(headers: TableHeader[], type: TaskType) {
    return <Cell width={headers[8].width}>{getNameQuestionType(type)}</Cell>
  }

  private renderCellDoubts(headers: TableHeader[], type: TaskType, stats: any) {
    if (type === TaskType.Video || type === TaskType.Read) {
      return (
        headers[6].visible && (
          <Cell width={headers[6].width}>{stats.doubts.doubtsPercentagePretty}</Cell>
        )
      )
    }
    return (
      headers[6].visible && (
        <ProgressCell
          current={stats.doubts.read + stats.doubts.sent}
          hideProgress
          total={stats.students ? stats.students.total : 0}
          width={headers[6].width}
        />
      )
    )
  }

  private renderCellPerformance(headers: TableHeader[], type: TaskType, taskSummary: any) {
    if (taskSummary?.respondentsRatePretty === '-- Questão anulada') {
      return (
        headers[7].visible && (
          <Cell width={headers[7].width}>{taskSummary?.respondentsRatePretty}</Cell>
        )
      )
    }

    if (type === TaskType.MultipleChoice) {
      return (
        headers[7].visible && (
          <ProgressCell
            current={taskSummary ? taskSummary.correctAnswers : 0}
            invert
            total={taskSummary ? taskSummary.countRespondents : 0}
            showIcon
            width={headers[7].width}
          />
        )
      )
    }

    const text =
      type === TaskType.Essay
        ? '-- Não há correção para questões de redação'
        : taskSummary?.respondentsRatePretty

    return headers[7].visible && <Cell width={headers[7].width}>{text}</Cell>
  }

  private tasksTableFilterOptions = () => {
    const { contextFormat } = this.props
    const visibleDoubts = visibleContextDoubts(contextFormat)
    const tableFilterOptions = [
      {
        id: 'allTasks',
        value: 'Todas as questões',
      },
      {
        id: 'questionsWithDoubts',
        value: 'Questões que tiveram alguma dúvida',
      },
      {
        id: 'questionsWithNoneAnswered',
        value: 'Questões que nenhum aluno realizou',
      },
      {
        id: 'questionsThatAllStudentsAnswered',
        value: 'Questões que todos os alunos realizaram',
      },
      {
        id: 'questionsWithZeroHhits',
        value: 'Questões que nenhum aluno acertou',
      },
    ]
    if (!visibleDoubts) {
      tableFilterOptions.splice(1, 1)
    }
    return tableFilterOptions
  }
}

export default TasksTable
