import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Link } from '@plurall/elo'

import styles from './styles.module.css'

const ActionDescriptor = props => (
  <div className={styles.actionDescriptor}>
    <Link secondary onClick={props.onClick} className={styles.text}>
      {props.text}
    </Link>

    {props.icon && (
      <Icon size='small' type={props.icon} onClick={props.onClick} className={styles.icon} />
    )}
  </div>
)

ActionDescriptor.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default ActionDescriptor
