import { TaskType } from 'types/enums'
import {
  percentageString,
  renderPercentageComplete,
  renderTasksDone,
  sortByCorrect,
  sortByCorrectPerDone,
  standardSort,
} from './helpers'

const taskColumnMultipleChoice = (totals, visible, taskType = TaskType.MultipleChoice) => ({
  key: taskType,
  header: 'Questões Objetivas',
  visible,
  align: 'center',
  typeTooltip: 'objetivas',

  columns: [
    {
      key: 'done_over_available_object_questions',
      header: 'Feitas / Disponíveis',
      parentKey: taskType,
      sortable: true,
      visible,
      accessor: ({ studentTotals: { objectiveQuestionsDone } }) =>
        ({ objectiveQuestionsDone } || 0),

      cell: ({ objectiveQuestionsDone }) =>
        renderTasksDone(objectiveQuestionsDone || 0, totals?.objectiveQuestions || 0, true, true),
      csvColumns: ({ objectiveQuestionsDone }) => ({
        done_available_questions: objectiveQuestionsDone,
        done_available_questions_total: totals.objectiveQuestions,
        done_available_questions_percent: percentageString(
          objectiveQuestionsDone,
          totals.objectiveQuestions,
        ),
      }),
      csvHeaders: () => ({
        done_available_questions: 'Questões Objetivas Feitas',
        done_available_questions_total: 'Questões Objetivas Disponíveis',
        done_available_questions_percent: '% Questões Objetivas Feitas',
      }),
      sortFunction: (rowA, rowB) =>
        standardSort(
          rowA.studentTotals.objectiveQuestionsDone,
          rowB.studentTotals.objectiveQuestionsDone,
        ),
      width: 180,
    },
    {
      key: 'correct_over_done_objective_questions',
      header: 'Acertos nas Feitas',
      parentKey: taskType,
      sortable: true,
      visible,
      accessor: ({ studentTotals: { objectiveQuestionsCorrect, objectiveQuestionsDone } }) => ({
        objectiveQuestionsCorrect,
        objectiveQuestionsDone,
      }),
      cell: ({ objectiveQuestionsCorrect, objectiveQuestionsDone }) =>
        renderPercentageComplete(objectiveQuestionsCorrect, objectiveQuestionsDone, 50),
      csvColumns: ({ objectiveQuestionsCorrect, objectiveQuestionsDone }) => ({
        correct_done_questions_percent: percentageString(
          objectiveQuestionsCorrect,
          objectiveQuestionsDone,
        ),
      }),
      csvHeaders: () => ({
        correct_done_questions_percent: '% Acertos nas Feitas',
      }),
      sortFunction: sortByCorrectPerDone,
      width: 180,
    },
    {
      key: 'correct_over_available_objective_questions',
      header: 'Acertos nas Disponíveis',
      parentKey: taskType,
      sortable: true,
      visible,
      accessor: ({ studentTotals: { objectiveQuestionsCorrect } }) => ({
        objectiveQuestionsCorrect,
      }),
      cell: ({ objectiveQuestionsCorrect }) =>
        renderPercentageComplete(objectiveQuestionsCorrect, totals.objectiveQuestions, 50),
      csvColumns: ({ objectiveQuestionsCorrect }) => ({
        correct_available_questions_percent: percentageString(
          objectiveQuestionsCorrect,
          totals.objectiveQuestions,
        ),
      }),
      csvHeaders: () => ({
        correct_available_questions_percent: '% Acertos nas Disponíveis',
      }),
      sortFunction: sortByCorrect,
      width: 180,
    },
  ],
})

const taskColumnOpenResponse = (totals, visible, taskType = TaskType.OpenResponse) => ({
  key: taskType,
  header: 'Questões Discursivas',
  visible,
  typeTooltip: 'discursivas',
  columns: [
    {
      key: 'done_over_available_discursive_questions',
      header: 'Feitas / Disponíveis',
      parentKey: taskType,
      sortable: true,
      visible,
      accessor: ({ studentTotals: { discursiveQuestionsDone } }) => ({ discursiveQuestionsDone }),
      cell: ({ discursiveQuestionsDone }) =>
        renderTasksDone(discursiveQuestionsDone, totals.discursiveQuestions, true, true),
      csvColumns: ({ discursiveQuestionsDone }) => ({
        discursive_questions: discursiveQuestionsDone,
        discursive_questions_total: totals.discursiveQuestions,
        discursive_questions_total_percent: percentageString(
          discursiveQuestionsDone,
          totals.discursiveQuestions,
        ),
      }),
      csvHeaders: () => ({
        discursive_questions: 'Questões Discursivas Feitas',
        discursive_questions_total: 'Questões Discursivas Disponíveis',
        discursive_questions_total_percent: '% Questões Discursivas Feitas',
      }),
      sortFunction: (rowA, rowB) =>
        standardSort(
          rowA.studentTotals.discursiveQuestionsDone,
          rowB.studentTotals.discursiveQuestionsDone,
        ),
      width: 180,
    },
  ],
})

const taskColumnVideo = (totals, visible, taskType = TaskType.Video) => ({
  key: taskType,
  header: 'Vídeos das aulas',
  visible,
  typeTooltip: 'de vídeo',
  columns: [
    {
      key: 'watched_over_available',
      header: 'Vistos / Disponíveis',
      parentKey: taskType,
      sortable: true,
      visible,
      accessor: ({ studentTotals: { videosWatched } }) => ({ videosWatched }),
      cell: ({ videosWatched }) => renderTasksDone(videosWatched, totals.videos, true, true),
      csvColumns: ({ videosWatched }) => ({
        watched_value: videosWatched,
        watched_total: totals.videos,
        watched_percent: percentageString(videosWatched, totals.videos),
      }),
      csvHeaders: () => ({
        watched_value: 'Vídeos das aulas Vistos',
        watched_total: 'Vídeos das aulas Disponíveis',
        watched_percent: '% Vídeos das aulas Vistos',
      }),
      sortFunction: (rowA, rowB) =>
        standardSort(rowA.studentTotals.videosWatched, rowB.studentTotals.videosWatched),
      width: 150,
    },
  ],
})

const taskColumnRead = (totals, visible, taskType = TaskType.Read) => ({
  key: taskType,
  header: 'Questões de Leitura',
  visible,
  typeTooltip: 'de leitura',
  columns: [
    {
      key: 'done_over_available_reading_activities',
      header: 'Vistas / Disponíveis',
      parentKey: taskType,
      sortable: true,
      visible,
      accessor: ({ studentTotals: { readingActivitiesDone } }) => ({ readingActivitiesDone }),
      cell: ({ readingActivitiesDone }) =>
        renderTasksDone(readingActivitiesDone, totals.readingActivities, true, true),
      csvColumns: ({ readingActivitiesDone }) => ({
        study_questions: readingActivitiesDone,
        study_questions_totals: totals.readingActivities,
        study_questions_totals_percent: percentageString(
          readingActivitiesDone,
          totals.readingActivities,
        ),
      }),
      csvHeaders: () => ({
        study_questions: 'Questões de Leitura Vistas',
        study_questions_totals: 'Questões de Leitura Disponíveis',
        study_questions_totals_percent: '% Questões de Leitura Vistas',
      }),
      sortFunction: (rowA, rowB) =>
        standardSort(
          rowA.studentTotals.readingActivitiesDone,
          rowB.studentTotals.readingActivitiesDone,
        ),
      width: 150,
    },
  ],
})

const taskColumnEssay = (totals, visible, taskType = TaskType.Essay) => ({
  key: taskType,
  header: 'Questões de Redação',
  visible,
  typeTooltip: 'de redação',
  columns: [
    {
      key: 'done_over_available_essay',
      header: 'Feitas / Disponíveis',
      parentKey: taskType,
      sortable: true,
      visible,
      accessor: ({ studentTotals: { essayDone } }) => ({ essayDone }),
      cell: ({ essayDone }) => renderTasksDone(essayDone, totals.essay, false, false),
      csvColumns: ({ essayDone }) => ({
        essay_done: essayDone,
        essay_totals: totals.essay,
        essay_totals_percent: percentageString(essayDone, totals.essay),
      }),
      csvHeaders: () => ({
        essay_done: 'Questões de Redação Feitas',
        essay_totals: 'Questões de Redação Disponíveis',
        essay_totals_percent: '% Questões de Redação Feitas',
      }),
      sortFunction: (rowA, rowB) =>
        standardSort(rowA.studentTotals.essayDone, rowB.studentTotals.essayDone),
      width: 150,
    },
  ],
})

const taskColumnMultipleChoiceAndOpenResponse = (totalQuestions, visible) => ({
  key: `${TaskType.MultipleChoice}_${TaskType.OpenResponse}`,
  header: 'Questões Objetivas e Discursivas',
  visible,
  typeTooltip: 'objetivas e discursivas',
  dependencyKey: [TaskType.MultipleChoice, TaskType.OpenResponse],
  columns: [
    {
      key: 'done_over_available_object_and_discursive_questions',
      header: 'Total de Feitas / Disponíveis',
      parentKey: `${TaskType.MultipleChoice}_${TaskType.OpenResponse}`,
      sortable: true,
      visible,
      accessor: ({
        studentTotals: { objectiveQuestionsDone },
        studentTotals: { discursiveQuestionsDone },
      }) => ({ objectiveQuestionsDone, discursiveQuestionsDone }),
      cell: ({ objectiveQuestionsDone, discursiveQuestionsDone }) =>
        renderTasksDone(
          (objectiveQuestionsDone || 0) + (discursiveQuestionsDone || 0),
          totalQuestions,
          true,
          true,
        ),
      csvColumns: ({ objectiveQuestionsDone, discursiveQuestionsDone }) => ({
        object_and_discursive_questions: objectiveQuestionsDone + discursiveQuestionsDone,
        object_and_discursive_questions_total: totalQuestions,
        object_and_discursive_questions_total_percent: percentageString(
          objectiveQuestionsDone + discursiveQuestionsDone,
          totalQuestions,
        ),
      }),
      csvHeaders: () => ({
        object_and_discursive_questions: 'Questões Objetivas e Discursivas Feitas',
        object_and_discursive_questions_total: 'Questões Objetivas e Discursivas Disponíveis',
        object_and_discursive_questions_total_percent: '% Questões Objetivas e Discursivas Feitas',
      }),
      sortFunction: (rowA, rowB) =>
        standardSort(
          rowA.studentTotals.discursiveQuestionsDone + rowA.studentTotals.objectiveQuestionsDone,
          rowB.studentTotals.discursiveQuestionsDone + rowB.studentTotals.objectiveQuestionsDone,
        ),
      width: 215,
    },
  ],
})

const taskColumn = (taskType, totals, visible) => {
  switch (taskType) {
    case TaskType.MultipleChoice:
      return taskColumnMultipleChoice(totals, visible)
    case TaskType.OpenResponse:
      return taskColumnOpenResponse(totals, visible)
    case TaskType.Video:
      return taskColumnVideo(totals, visible)
    case TaskType.Read:
      return taskColumnRead(totals, visible)
    case TaskType.Essay:
      return taskColumnEssay(totals, visible)
    default:
      throw new Error(`Unknown Task Type '${taskType}'`)
  }
}

export { taskColumn, taskColumnMultipleChoiceAndOpenResponse }
