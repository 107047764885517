import React, { FunctionComponent, useState, ReactNode } from 'react'
import { Text, Icon } from '@plurall/elo'
import classNames from 'classnames'
import styles from './Header.module.css'

export interface HeaderProps {
  children?: ReactNode
  id: string
  small?: boolean
  sortable?: boolean
  sortingActive?: boolean
  onSortingChange?: (id: string, desc: boolean) => void
  width?: number
}

interface SortIcon {
  id: string
  active: boolean
  desc: boolean
}

const renderSortIcon = ({ id, active, desc }: SortIcon) => {
  let sortType: string

  if (active) {
    sortType = desc ? 'orderDescending' : 'orderAscending'
  } else {
    sortType = 'order'
  }

  return <Icon data-test-id={`${id}-sort`} className={styles.sort} type={sortType} size='small' />
}

export const Header: FunctionComponent<HeaderProps> = ({
  children,
  id,
  small = false,
  sortable,
  sortingActive = false,
  onSortingChange,
  width,
}: HeaderProps) => {
  const [desc, setOrder] = useState(false)

  return (
    <th data-test-id={id}
      onClick={() => {
        if (sortable && onSortingChange) {
          onSortingChange(id, !desc)
          setOrder(!desc)
        }
      }}
      className={classNames(styles.header, { [styles.sortable]: sortable }, { [styles.small]: small })}
      style={{ maxWidth: width, minWidth: width }}
    >
      <div className={styles.cellContent}>
        {typeof children === 'function'
          ? children()
          : <Text secondary element='span'>
              {children}
            </Text>
        }
        {sortable && renderSortIcon({ id, active: sortingActive, desc })}
      </div>
    </th>
  )
}
