/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Redirect } from 'react-router'

import { NavBar, Loading } from 'components'
import { schoolsQuery } from 'graphql/queries'
import NotificationPage from 'views/NotificationPage'
import notFoundImage from 'images/not-found.svg'
import {
  getReportTypeFromPath,
  getReportContextFormat,
  getReportTitle,
  getReportBaseUrl,
} from 'utils/reportType'
import { graphqlClient } from 'utils/graphqlClient'

import SchoolSelector from './SchoolSelector'
import styles from './SchoolSelection.module.css'

const SchoolSelection = ({ match }) => {
  const reportType = getReportTypeFromPath(match.path)
  const contextFormat = getReportContextFormat(reportType)
  const title = getReportTitle(reportType)
  const baseUrl = getReportBaseUrl(reportType)

  const [schools, setSchools] = useState([])
  const [noSchools, setNoSchools] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    sessionStorage.setItem('chosenPath', reportType)
  }, [reportType])

  const getSchools = async () => {
    try {
      const { loading: graphqlLoading, data } = await graphqlClient.query({
        query: schoolsQuery,
        variables: { contextFormat },
      })

      if (!data.me.schools) {
        setNoSchools(true)
      } else {
        setSchools(data.me.schools)
      }

      setLoading(graphqlLoading)
    } catch {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSchools()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loading />

  if (noSchools || error) {
    return (
      <NotificationPage
        title='Ainda não é possível visualizar o relatório. Não encontramos suas turmas para esse contexto'
        text='Verifique com o Administrador da sua escola se existem turmas para esse relatório e tente acessar na próxima atualização, amanhã após às 9 horas da manhã.'
        img={notFoundImage}
      />
    )
  }

  if (schools.length === 1) {
    return <Redirect to={`${baseUrl}/escolas/${schools[0].id}`} />
  }

  return (
    <>
      <NavBar title={title} />

      <div className={styles.selection}>
        <SchoolSelector baseUrl={baseUrl} schools={schools} />
      </div>
    </>
  )
}

export default SchoolSelection
