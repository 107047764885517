import React, { ReactNode, useState, useEffect, FC } from 'react'
import styles from './NewTable.module.css'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { Header } from './Header'
import defaultSort from 'utils/defaultSort'
import { Link } from '@plurall/elo'
import { ReportType } from 'types/enums'
import classNames from 'classnames'

interface NewTableProps {
  children: (data: TableData, index?: any) => ReactNode
  data: TableData[]
  defaultSortBy?: string
  limit?: number
  headers: TableHeader[]
  type?: ReportType
  scroll?: boolean
  onPageChange?: (offset: number) => void
  onSortChange?: (id: string, desc: boolean) => void
  totalCount?: number
  modal?: boolean
  offsetReset?: boolean
}

const NewTable: FC<NewTableProps> = ({
  children,
  data,
  defaultSortBy = '',
  limit = 25,
  headers,
  type = ReportType.EstudoOrientado,
  scroll = false,
  onSortChange,
  onPageChange,
  totalCount = data.length,
  modal,
  offsetReset,
}) => {
  const [sortedData, setSortedData] = useState<TableData[]>([...data])
  const [offset, setOffset] = useState<number>(limit)
  const [sortBy, setSorting] = useState<string>(defaultSortBy)

  useEffect(() => {
    setSortedData([...data])
  }, [data])

  useEffect(() => {
    if (offsetReset) {
      setOffset(limit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetReset])

  const handleSortingChange = (id: string, desc: boolean) => {
    setSorting(id)

    if (onSortChange) {
      onSortChange(id, desc)
    } else {
      setSortedData(
        desc ? [...data].sort(defaultSort(id)).reverse() : [...data].sort(defaultSort(id)),
      )
    }
  }

  const handlePaginationChange = (event: MouseEvent) => {
    const newOffset = offset + limit > totalCount ? totalCount : offset + limit

    event.preventDefault()
    if (onPageChange) {
      onPageChange(offset)
    }
    setOffset(newOffset)
  }

  const renderHeader = () => (
    <thead
      className={classNames(styles.header, {
        [styles.small]: type === ReportType.Engajamento && !modal,
        [styles.modal]: modal,
      })}
    >
      <tr>
        {headers.map(
          ({ id, label, width, sortable, visible = true }) =>
            visible && (
              <Header
                id={id}
                key={id}
                small={type === ReportType.Engajamento}
                width={width}
                sortable={sortable}
                sortingActive={sortBy === id}
                onSortingChange={handleSortingChange}
              >
                {label}
              </Header>
            ),
        )}
      </tr>
    </thead>
  )

  const renderBody = () => (
    <tbody>
      {children &&
        pagedData.map((row, index) => (
          <tr key={index} data-test-id={`table-row-${index + 1}`}>
            {children(row, index)}
          </tr>
        ))}
    </tbody>
  )

  const renderFooter = () => {
    const more = totalCount - offset

    return (
      more > 0 && (
        <div className={styles.more} data-test-id='more'>
          <Link secondary onClick={handlePaginationChange}>
            Carregar mais resultados
          </Link>
        </div>
      )
    )
  }

  const pagedData = sortedData.length > offset ? sortedData.slice(0, offset) : sortedData

  if (scroll) {
    return (
      <div className={styles.container}>
        <ScrollSync>
          <>
            <ScrollSyncPane>
              <div className={styles.headerContainer}>
                <table className={styles.table}>{renderHeader()}</table>
              </div>
            </ScrollSyncPane>
            <ScrollSyncPane>
              <div className={styles.bodyContainer}>
                <table className={styles.table}>{renderBody()}</table>
              </div>
            </ScrollSyncPane>
          </>
        </ScrollSync>
        {renderFooter()}
      </div>
    )
  } else {
    return (
      <>
        <table className={styles.table}>
          {renderHeader()}
          {renderBody()}
        </table>
        {renderFooter()}
      </>
    )
  }
}

export default NewTable
