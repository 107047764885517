import React, { useState, useEffect } from 'react'
import { Card, CardStatus, Separator, EngagementPositionRanking } from 'components'
import { Text, Link } from '@plurall/elo'
import { EngagementRole } from 'types/enums'
import defaultSort from 'utils/defaultSort'
import styles from './EngagementRanking.module.css'

interface EngagementRankingProps {
  data: EngagementTeacher[] | EngagementStudent[]
  role: EngagementRole
  loading: boolean
  limit?: number
}

const EngagementRanking = ({ data, loading, role, limit = 5 }: EngagementRankingProps) => {
  const [ranking, setRanking] = useState<EngagementRanking>({} as EngagementRanking)
  const [expanded, setExpanded] = useState<boolean>(false)

  const label = role === EngagementRole.Professor ? 'Professores' : 'Alunos'
  const showLoadMore =
    showLoadMoreByPosition(ranking.first, limit) ||
    showLoadMoreByPosition(ranking.second, limit) ||
    showLoadMoreByPosition(ranking.third, limit)

  useEffect(() => {
    const newRanking = getRanking(data)
    setRanking(newRanking)
  }, [role, data])

  const handleLoadMoreClick = (event: MouseEvent) => {
    event.preventDefault()
    setExpanded(!expanded)
  }

  const renderRanking = () =>
    ranking.first ? (
      <>
        <div className={styles.items}>
          <EngagementPositionRanking
            data={ranking.first}
            position={1}
            role={role}
            expanded={expanded}
            limit={limit}
          />
          {ranking.second && (
            <EngagementPositionRanking
              data={ranking.second}
              position={2}
              role={role}
              expanded={expanded}
              limit={limit}
            />
          )}
          {ranking.third && (
            <EngagementPositionRanking
              data={ranking.third}
              position={3}
              role={role}
              expanded={expanded}
              limit={limit}
            />
          )}
        </div>
        {showLoadMore && (
          <>
            <Separator />
            <div className={styles.more} data-test-id='ranking-more'>
              <Link secondary onClick={handleLoadMoreClick}>
                {expanded ? 'Ocultar' : `Exibir todos os ${label.toLocaleLowerCase()}`}
              </Link>
            </div>
          </>
        )}
      </>
    ) : (
      <CardStatus
        status='not-found'
        message='Não há registro de acessos para o período selecionado.'
      />
    )

  return (
    <Card className={styles.card}>
      <Text secondary className={styles.caption} size='big'>
        {label} com mais acessos
      </Text>
      <Separator />
      {loading ? <CardStatus /> : renderRanking()}
    </Card>
  )
}

const getRanking = (data: EngagementTeacher[] | EngagementStudent[]) => {
  const uniqTotals = getUniqTotals(data)
  if (!uniqTotals.length) {
    return {} as EngagementRanking
  }

  return {
    first: groupRankingByTotal(uniqTotals[0], data),
    second: uniqTotals.length > 1 ? groupRankingByTotal(uniqTotals[1], data) : undefined,
    third: uniqTotals.length > 2 ? groupRankingByTotal(uniqTotals[2], data) : undefined,
  }
}

const getUniqTotals = (data: EngagementAccess[]) => {
  const totals = data.filter(({ total }) => total > 0).map(({ total }) => total)
  return [...new Set<number>(totals)].sort((a, b) => b - a)
}

const groupRankingByTotal = (total: number, data: EngagementAccess[]) => {
  const dataFiltered = data.filter(engagement => engagement.total === total)
    .sort(defaultSort('name'))

  return { engagements: dataFiltered, total } as EngagementPositionRanking
}

const showLoadMoreByPosition = (positionRanking: EngagementPositionRanking | undefined, limit: number) =>
  positionRanking && positionRanking.engagements && positionRanking.engagements.length > limit

export default EngagementRanking
