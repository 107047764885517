import React from 'react'
import gql from 'graphql-tag'

import { DateFilter, Filter } from 'components'
import { graphqlClient } from 'utils/graphqlClient'
import {
  buildQueryEoFiltersFromFields,
  sortByFieldOrder,
  queryEoFiltersPossibleTypes,
} from 'utils/queryBuilder'
import { FormatFilters } from 'types/enums'

import { FieldHandler, FieldHandlerInterface } from './FieldHandler'

interface ExtraFiltersProps {
  contextFormat?: string[]
  schoolId?: string | number
  classIds: Klass[]
  eoFormat: string
  filters: any
  gradeId: string
  onChange: (filters: any) => void
  onLoadFilters: (fieldNames: string[]) => void
  onLoading: (loading: boolean) => void
}

interface ExtraFiltersState {
  data: any
  fields: string[]
  updateFilter: boolean
}

interface EoFiltersInterface {
  [key: string]: any[]
}

interface DataInterface {
  me: {
    eoFilters: EoFiltersInterface
  }
}

interface PossibleTypeInterface {
  name: string
  fields: [{ name: string }]
}

class ExtraFilters extends React.Component<ExtraFiltersProps, ExtraFiltersState> {
  public state = {} as ExtraFiltersState

  constructor(props: Readonly<ExtraFiltersProps>) {
    super(props)
    this.props.onLoading(true)
  }

  public componentDidMount() {
    this.getFilters()
  }

  public componentDidUpdate(prevProps: ExtraFiltersProps) {
    const shouldUpdateFormat = prevProps.eoFormat !== this.props.eoFormat

    if (shouldUpdateFormat) {
      this.setState({ fields: [], updateFilter: true })
      this.props.onLoading(true)

      this.getFilters()
    }
  }

  public async getFilters() {
    const { contextFormat } = this.props

    const {
      data: {
        __type: { possibleTypes },
      },
    } = await graphqlClient.query({
      query: gql(queryEoFiltersPossibleTypes),
      variables: { contextFormat },
    })

    if (!possibleTypes) {
      this.setState({ fields: [] })
      this.props.onLoading(false)
      return
    }

    const format = this.getFormat()
    const fields = this.getFields(format, possibleTypes)
    const { schoolId, classIds, gradeId } = this.props

    const { data, loading } = await graphqlClient.query({
      query: gql(buildQueryEoFiltersFromFields(format, fields)),
      variables: {
        classIds: classIds.map(klass => klass.id),
        contextFormat,
        gradeIds: [gradeId],
        schoolId,
      },
    })

    if (loading) {
      return
    }

    if (this.state.updateFilter) {
      fields.map((field: string) => {
        if (field === 'materials') {
          const filteredItems = data?.me?.eoFilters[field]
          return this.props.onChange({ [field]: filteredItems })
        }
        return null
      })
    }

    this.setState({ data, fields, updateFilter: false })
    this.props.onLoading(false)
    this.props.onLoadFilters(fields)
  }

  public render() {
    const { data, fields, updateFilter } = this.state
    const { filters } = this.props
    const filteredData: any = {}
    const visible: any = {}

    if (!data || !fields) {
      return null
    }

    const format = this.getFormat()
    const showData = this.showDateFilters(format) && !updateFilter
    const fieldHandler = FieldHandler(visible, filters, fields)

    const applyFilters = this.applyFilters(data, filteredData)
    const renderField = this.renderFilterForField(filteredData)

    return (
      <>
        {fields.map((field: string) => {
          const handler = fieldHandler(field) as FieldHandlerInterface
          if (!!handler) {
            applyFilters(handler)
          }
          this.confirmVisibleCheckeds(visible, filters, field)
          return renderField(field, handler)
        })}
        {showData && this.renderDateFields()}
      </>
    )
  }

  private getFormat = () => {
    const eoFormatClass = this.props.eoFormat as keyof typeof FormatFilters
    const formatFilters = FormatFilters[eoFormatClass]

    return formatFilters ? formatFilters : FormatFilters.ADAE
  }

  private getFields = (format: FormatFilters, possibleTypes: PossibleTypeInterface[]) => {
    const type = possibleTypes.find(
      (possibleType: { name: any }) => possibleType.name === format.toString(),
    )
    return type ? type.fields.map((field: any) => field.name).sort(sortByFieldOrder) : []
  }

  private showDateFilters = (format: FormatFilters) => {
    return format !== FormatFilters.PH
  }

  private confirmVisibleCheckeds = (visible: any, filters: any, field: string) => {
    const visibleItems =
      !(visible[field] && Array.isArray(visible[field])) ||
      visible[field].map((item: any) => item.id)
    const filteredItems = filters[field]
    if (Array.isArray(visibleItems) && Array.isArray(filteredItems)) {
      const enabledItems = filteredItems.filter((item: any) => visibleItems.includes(item.id))
      if (JSON.stringify(filteredItems) !== JSON.stringify(enabledItems)) {
        this.props.onChange({ [field]: enabledItems })
      }
    }
  }

  private renderDateFields = () => (
    <>
      <DateFilter
        errorMessage='A data de início deve ser menor ou igual a data de término'
        label='Data de Início'
        onChange={value => this.onChange('startDate', value)}
        upperLimit={this.props.filters.endDate}
        value={this.props.filters.startDate}
      />
      <DateFilter
        endOfDay
        errorMessage='A data de término deve ser maior ou igual a data de início'
        label='Data de Término'
        lowerLimit={this.props.filters.startDate}
        onChange={value => this.onChange('endDate', value)}
        value={this.props.filters.endDate}
      />
    </>
  )

  private onChange = (fieldName: any, value: string | Date | undefined) => {
    this.props.onChange({ [fieldName]: value })
  }

  private applyFilters = (data: DataInterface, filteredData: EoFiltersInterface) => (
    fieldHandler: FieldHandlerInterface,
  ) => {
    filteredData[fieldHandler.field] = !!fieldHandler.preFilter
      ? fieldHandler.preFilter((data.me.eoFilters as any)[fieldHandler.field])
      : data.me.eoFilters[fieldHandler.field]
  }

  private renderFilterForField = (data: any) => (
    fieldName: string,
    fieldHandler: FieldHandlerInterface,
  ) => {
    const { filters } = this.props

    const isMultiSelection = fieldHandler && fieldHandler.multiSelect
    const sortById = fieldHandler && fieldHandler.sortById
    const labels = fieldHandler && fieldHandler.buttonLabels

    const filter = filters[fieldName]
    const selected =
      filter && isMultiSelection ? filter.map(({ id }: any) => id) : filter && filter.id

    return (
      <Filter
        key={fieldHandler ? fieldHandler.label! : fieldName}
        autoSelectSingleItem={true}
        items={data[fieldName]}
        multiSelect={isMultiSelection}
        name={fieldHandler ? fieldHandler.label! : fieldName}
        onChange={value => this.onChange(fieldName, value)}
        selected={selected}
        sortAsNumeric={sortById}
        buttonLabels={labels}
      />
    )
  }
}

export default ExtraFilters
