import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import SomosClient from 'utils/client'
import NotificationPage from 'views/NotificationPage'
import EngagementClient from 'utils/restClient'
import { LoggedUserContext } from 'utils/context'
import { getReportTitle } from 'utils/reportType'
import { Loading, NavBar } from 'components'
import { Roles, ReportType } from 'types/enums'
import EngagementTabs from './Tabs/EngagementTabs'
import { clearToken } from 'utils/token'

const Engagement = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User>({} as User)
  const [schools, setSchools] = useState<School[]>([])
  const [selectedSchool, setSelectedSchool] = useState<School>({} as School)
  const [noResult, setNoResult] = useState<boolean>(false)
  const [hasAccess, setAccess] = useState<boolean>(true)

  const handleSchoolChange = (schoolId: string | number) => {
    const school = schools.find((s: School) => s.id === schoolId) || ({} as School)
    if (!school) {
      clearToken()
      window.location.reload()
    }

    setLoading(true)
    fetchSelectedSchoolData(school, setLoading, setSelectedSchool, setNoResult, setAccess)
  }

  useEffect(() => {
    fetchUserProfile(setLoading, setUser, setSchools, setSelectedSchool, setNoResult, setAccess)
  }, [])

  if (loading) return <Loading />

  if (hasAccess) {
    return (
      <NotificationPage
        title='Você não possui acesso a essa página.'
        text=' O Relatório de Engajamento está disponível somente para coordenadores e administradores de escolas.'
      />
    )
  }

  const title = getReportTitle(ReportType.Engajamento)
  const breadcrumb = [{ text: selectedSchool.name }]

  return (
    <LoggedUserContext.Provider value={user}>
      <NavBar
        breadcrumb={breadcrumb}
        title={title}
        schools={schools}
        selectedSchool={selectedSchool}
        onSchoolChange={handleSchoolChange}
      />
      <EngagementTabs selectedSchool={selectedSchool} noResult={noResult} />
    </LoggedUserContext.Provider>
  )
}

const fetchUserProfile = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  setUser: Dispatch<SetStateAction<User>>,
  setSchools: Dispatch<SetStateAction<School[]>>,
  setSelectedSchool: Dispatch<SetStateAction<School>>,
  setNoResult: Dispatch<SetStateAction<boolean>>,
  setAccess: Dispatch<SetStateAction<boolean>>,
) => {
  const v3Client = new SomosClient({ pmVersion: 2 })
  const clientEngagement = new EngagementClient()

  const user = await v3Client.getProfile()

  const isStudent = user.roles.some((user: { name: Roles }) => user.name === Roles.Aluno)
  setAccess(isStudent)

  if (user && user.schools.length > 0) {
    const schools = await clientEngagement.fetchSchools()
    if (schools?.errors) {
      const error = schools.errors
      return (window.location.href = `/error?title=${error.title}&message=${error.message}&status=${error.status}`)
    }

    setUser(user)

    if (schools.length > 0) {
      setSchools(schools)
      fetchSelectedSchoolData(schools[0], setLoading, setSelectedSchool, setNoResult, setAccess)
      return
    }
  }

  setLoading(false)
}

const fetchSelectedSchoolData = async (
  selectedSchool: School,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setSelectedSchool: Dispatch<SetStateAction<School>>,
  setNoResult: Dispatch<SetStateAction<boolean>>,
  setAccess: Dispatch<SetStateAction<boolean>>,
) => {
  const client = new EngagementClient()

  const schoolData = await client.fetchSchoolData(selectedSchool.id)

  if (schoolData?.error?.response?.status === 403) {
    setAccess(true)
  } else {
    setAccess(false)
  }

  if (schoolData?.error?.response?.status === 404) {
    setNoResult(true)
    setSelectedSchool({
      ...selectedSchool,
    })
  } else {
    setAccess(false)
    setNoResult(false)
    setSelectedSchool({
      ...selectedSchool,
      classes: schoolData.classes,
      disciplines: schoolData.disciplines,
      grades: schoolData.grades,
    })
  }
  setLoading(false)
}

export default Engagement
