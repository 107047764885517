import classNames from 'classnames/bind'
import Letter from '../Letter'
import React, { FunctionComponent } from 'react'
import styles from './Answer.module.css'
import { Icon, Text } from 'plurall-ui'
import { TaskSequence, TaskStatus } from 'types/enums'

const cx = classNames.bind(styles)

interface AnswerProps {
  answer: string
  attempt?: number
  image?: string
  lastAttempt?: boolean
  showAttemptLabel?: boolean
  sequence?: TaskSequence
  status: TaskStatus
  correct?: boolean
}

const Answer: FunctionComponent<AnswerProps> = ({
  sequence,
  attempt,
  answer,
  image,
  status,
  lastAttempt = false,
  showAttemptLabel,
  correct = false,
}) => {
  status = status === TaskStatus.Answered
    ? (attempt && correct ? TaskStatus.Correct : TaskStatus.Wrong)
    : status

  const multipleChoice = !!sequence
  const classes = cx('answer', status, { multipleChoice, lastAttempt })

  return (
    <div className={classes}>
      {multipleChoice &&
        <div className={styles.icon}>
          {renderIcon(correct, lastAttempt, sequence, status)}
        </div>}
      <div className={styles.content}>
        {renderHeader(multipleChoice, correct, showAttemptLabel, attempt)}
        {renderImage(image)}
        {renderText(multipleChoice, answer)}
      </div>
    </div>
  )
}

const renderIcon = (
  correct: boolean = false,
  lastAttempt: boolean = false,
  sequence?: TaskSequence,
  status: TaskStatus = TaskStatus.Void,
) => {
  if (status === TaskStatus.Void || !lastAttempt) {
    return correct ? <Icon type='success' /> : <Letter label={sequence || ''} />
  }

  return status === TaskStatus.Correct ? <Icon type='success' /> : <Icon type='error' />
}

const renderHeader = (
  multipleChoice: boolean,
  correct: boolean,
  showAttemptLabel: boolean = false,
  attempt?: number,
) => {
  if (!multipleChoice) return <Text className={styles.answerStudentHeader} bold element='span'>Resposta do aluno</Text>
  if (showAttemptLabel && attempt) return <Text bold className={styles.attempt}>{attempt}ª Tentativa do aluno</Text>
  if (!!correct) return <Text bold className={styles.attempt}>Alternativa correta</Text>
}

const renderImage = (image?: string) => (
  !!image &&
  <div className={styles.answerStudentImg}>
    <img className={styles.image} alt='Imagem como resposta' src={image.replace(/-staging/g, '')} />
  </div>
)

const renderText = (multipleChoice: boolean = false, answer?: string) => (
  !!answer &&
  <Text className={classNames({[styles.text]: true, [styles.answerStudent]: !multipleChoice})}>
    <span dangerouslySetInnerHTML={{ __html: answer }} />
  </Text>
)

export default Answer
