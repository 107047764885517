import PlurallTabs, { Link } from 'plurall-tabs';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { PageContainer } from 'components';
import classnames from 'classnames'
import styles from './Tabs.module.css';

interface TabsProps {
  links: Link[]
  className?: string
}

const tabsTracker = (tabLabel: string) => {
  switch (tabLabel) {
    case 'Visão Geral':
      return 'dashboard-tab:click'
    case 'Professores':
      return 'teachers-tab:click'
    case 'Alunos':
      return 'students-tab:click'
    case 'Aula Digital':
      return 'digital-classes-tab:click'
    default:
      return 'tab:click'
  }
}

const Tabs = ({ links, className }: TabsProps) => (
  <div className={classnames(className, styles.tabs)}>
    <PageContainer>
      <PlurallTabs
        colorDefault='#655aa3'
        colorActive='#655aa3'
        fontLighter
        linkComponent={NavLink}
        links={links}
        onClick={(tabLabel: string) => window.PLURALL_TRACKER.track(tabsTracker(tabLabel))}
      />
    </PageContainer>
  </div>
)

export default Tabs
