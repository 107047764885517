import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Text } from '@plurall/elo-beta'

import styles from './styles.module.css'

const labelify = (filterValues, textSuffix, maxLength) =>
  filterValues.length > maxLength
    ? `${filterValues.length} ${textSuffix}`
    : filterValues.map(o => o.value).join(' e ')

class FilterHeading extends React.PureComponent {
  render() {
    const { filters, headingText } = this.props

    const classDescription = labelify(filters.klasses, 'Turmas', 2)

    const gradeClassNames = `${filters.grade[0].value} - ${classDescription}`

    const filterDisciplinesNames = filters.disciplines
      ? ` - ${labelify(filters.disciplines, 'Disciplinas', 2)}`
      : ' - Todas as Disciplinas'

    return (
      <div className={styles.heading}>
        <Heading element='h2' noParagraph>
          {headingText}
        </Heading>
        <Text shy size='t3'>
          {gradeClassNames}
          {filterDisciplinesNames}
        </Text>
      </div>
    )
  }
}

FilterHeading.propTypes = {
  filters: PropTypes.shape().isRequired,
  headingText: PropTypes.string,
}

FilterHeading.defaultProps = {
  headingText: 'Desempenho por aluno',
}

export default FilterHeading
