import ClientOAuth2 from 'client-oauth2'

const getOauthClient = url =>
  new ClientOAuth2({
    accessTokenUri: process.env.REACT_APP_SOMOS_ID_ACCESS_TOKEN_URL,
    authorizationUri: process.env.REACT_APP_SOMOS_ID_AUTHORIZATION_URL,
    clientId: process.env.REACT_APP_SOMOS_ID_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback?redirectTo=${url}`,
    scopes: [],
  })

export { getOauthClient }
