const numericSort = accessor => (a, b) => {
  const valA =
    typeof a[accessor] === 'string' ? parseInt(a[accessor].match(/\d+/), 10) : a[accessor]
  const valB =
    typeof b[accessor] === 'string' ? parseInt(b[accessor].match(/\d+/), 10) : b[accessor]

  return valA - valB
}

export default numericSort
