import { fieldMap } from './queryBuilder'

const convertFiltersToQueryVariables = (filters: any) => {
  const variables = {} as any

  Object.entries(filters).forEach(([key, value]: any): void => {
    if (!value) return

    const fieldInfo = fieldMap[key]
    const paramName = fieldInfo.queryParamName as any

    if (fieldInfo.queryType === 'search') {
      variables[paramName] = value
    } else if (fieldInfo.multiSelect) {
      if (key === 'activities' || key === 'lists' || key === 'emTempoLists') {
        const arrayActivities: any[] = []
        value.forEach((items: { testIds: any }) => {
          arrayActivities.push(...items.testIds)
        })
        variables[paramName] = arrayActivities
      } else {
        variables[paramName] = value.map((v: any) => v.id)
      }
    } else {
      variables[paramName] = value.id
    }

    return
  })

  return variables
}

export { convertFiltersToQueryVariables }
