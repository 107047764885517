import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { reportContexts } from 'utils/reportType'
import maintenanceImage from 'images/maintenance.svg'
import { PrivateRoute, Layout } from '../components'
import NotificationPage from './NotificationPage'
import SchoolSelection from './SchoolSelection'
import { Report } from './Report'
import Engagement from './Engagement'

class App extends React.PureComponent {
  render() {
    const isInMaintenance = false
    const { eng, eo, provas, olimpico, standard_test: standardTest } = reportContexts()
    return (
      <Layout>
        {isInMaintenance ? (
          <NotificationPage
            title='Estamos fazendo melhorias em nosso relatório.'
            text='Em breve estaremos de volta.'
            img={maintenanceImage}
          />
        ) : (
          <Switch>
            <PrivateRoute exact path='/' component={Report} reportContext={eo} />
            <PrivateRoute exact path='/estudo-orientado' component={Report} reportContext={eo} />
            <PrivateRoute
              path='/estudo-orientado/escolas/:id/'
              component={Report}
              reportContext={eo}
            />

            <PrivateRoute exact path='/provas' component={SchoolSelection} reportContext={provas} />
            <PrivateRoute path='/provas/escolas/:id/' component={Report} reportContext={provas} />

            <PrivateRoute
              exact
              path='/standard-test'
              component={SchoolSelection}
              reportContext={standardTest}
            />
            <PrivateRoute
              path='/standard-test/escolas/:id/'
              component={Report}
              reportContext={standardTest}
            />

            <PrivateRoute
              exact
              path='/olimpico'
              component={SchoolSelection}
              reportContext={olimpico}
            />
            <PrivateRoute
              path='/olimpico/escolas/:id/'
              component={Report}
              reportContext={olimpico}
            />

            <PrivateRoute path='/engajamento' component={Engagement} reportContext={eng} />

            <Route render={() => <Redirect to='/' />} />
          </Switch>
        )}
      </Layout>
    )
  }
}

export default App
