import React, { useEffect, useState } from 'react'
import { convertFiltersToQueryVariables } from 'utils/convert'
import { FilterContext } from 'utils/context'
import {
  lastUpdateQuery,
  studentsTotalsQuery,
  eoStudentsQuery,
  examStudentsQuery,
} from 'graphql/queries'
import { Averages, EOTable, FilterHeading, LastUpdate, Loading } from 'components'
import { isReportEO } from 'utils/reportType'
import { graphqlClient } from 'utils/graphqlClient'

interface StudentsProps {
  contextFormat?: string[]
  filters: any
  allFilters: any
}

// tslint:disable-next-line: interface-name
interface IStudentsTotals {
  averages: {
    objectivesAverage: number
    doneAverage: number
    correct: number
    done: number
    objectivesDone: number
    // tslint:disable-next-line: trailing-comma
    objectivesCorrect: number
  }
  totals: {
    objectiveQuestions: number
    discursiveQuestions: number
    readingActivities: number
    videos: number
    // tslint:disable-next-line: trailing-comma
    essay: number
  }
  taskTypes: string[]
}

// tslint:disable-next-line: interface-name
interface ILastUpdate {
  lastUpdate: string
}

const Students = ({ contextFormat, filters, allFilters }: StudentsProps) => {
  const variables = convertFiltersToQueryVariables(filters)

  contextFormat = variables?.contextFormat ? [variables.contextFormat] : contextFormat

  const [studentsTotals, setStudentsTotals] = useState<IStudentsTotals>({} as IStudentsTotals)
  const [loadingStudentsTotals, setLoadingStudentsTotals] = useState(true)

  const [students, setStudents] = useState([])
  const [loadingStudents, setLoadingStudents] = useState(true)

  const [lastUpdate, setLastUpdate] = useState<ILastUpdate>({} as ILastUpdate)
  const [loadingUpdate, setLoadingUpdate] = useState(true)

  const [error, setError] = useState(false)
  const [emptyStudents, setEmptyStudents] = useState(false)

  const [selectedCheckboxFilters, setSelectedCheckboxFilters] = useState({})

  const getStudentsTotals = async () => {
    try {
      setLoadingStudentsTotals(true)

      const { loading, data } = await graphqlClient.query({
        query: studentsTotalsQuery,
        variables: {
          ...variables,
          contextFormat,
        },
      })

      setStudentsTotals(data.me)
      setLoadingStudentsTotals(loading)
    } catch {
      setError(true)
    }
  }

  const getStudents = async () => {
    try {
      setLoadingStudents(true)
      const { loading, data } = await graphqlClient.query({
        query: isReportEO(contextFormat) ? eoStudentsQuery : examStudentsQuery,
        variables: {
          ...variables,
          contextFormat,
        },
      })

      if (!!data.me?.students?.length === false || data.me?.students?.length === 0) {
        setEmptyStudents(true)
      } else {
        setEmptyStudents(false)
      }

      setStudents(data.me?.students || [])
      setLoadingStudents(loading)
    } catch {
      setError(true)
    }
  }

  const getLastUpdate = async () => {
    try {
      setLoadingUpdate(true)

      const { loading, data } = await graphqlClient.query({
        query: lastUpdateQuery,
        variables: {
          ...variables,
          contextFormat,
        },
      })

      setLastUpdate(data)
      setLoadingUpdate(loading)
    } catch {
      setError(true)
    }
  }

  useEffect(() => {
    getStudentsTotals()
    getStudents()
    getLastUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  if (loadingStudentsTotals || loadingStudents || loadingUpdate) return <Loading />
  if (error) return null

  return (
    <>
      <LastUpdate />
      <FilterHeading filters={filters} />
      {(studentsTotals.totals.discursiveQuestions !== 0 ||
        studentsTotals.totals.essay !== 0 ||
        studentsTotals.totals.objectiveQuestions !== 0 ||
        studentsTotals.totals.readingActivities !== 0 ||
        studentsTotals.totals.videos !== 0) && (
        <Averages
          averages={studentsTotals.averages}
          totals={studentsTotals.totals}
          filters={filters}
        />
      )}
      <FilterContext.Provider value={{ filters, selectedCheckboxFilters }}>
        <EOTable
          students={students}
          totals={studentsTotals.totals}
          taskTypes={studentsTotals.taskTypes}
          filters={filters}
          update={lastUpdate}
          allFilters={allFilters}
          emptyStudents={emptyStudents}
          setSelectedCheckboxFilters={setSelectedCheckboxFilters}
        />
      </FilterContext.Provider>
    </>
  )
}

export default Students
