import { TaskList } from 'components'
import { Icon, Link, Text } from '@plurall/elo'
import React, { SFC, useState } from 'react'
import styles from './OpenTaskModalButton.module.css'

export enum OpenTaskModalTrackType {
  name = 'name',
  button = 'button',
}

interface OpenTaskModalButtonProps {
  text: string
  title: string
  subtitle: string
  studentId: number
  showIcon: boolean
  trackType: OpenTaskModalTrackType
}

const OpenTaskModalButton: SFC<OpenTaskModalButtonProps> = ({
  text,
  title,
  subtitle,
  studentId,
  showIcon,
  trackType,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = (event: MouseEvent) => {
    event.preventDefault()
    window.PLURALL_TRACKER.track(`student-modal-link-${trackType}`)
    setIsOpen(true)
  }
  const handleClose = () => setIsOpen(false)

  return (
    <>
      <Link size='normal' secondary onClick={handleOpen} dataTestId={text}>
        <Text element='span' secondary>
          {text}
        </Text>
        {showIcon && <Icon className={styles.icon} type='externalLink' size='small' />}
      </Link>

      {isOpen && (
        <TaskList studentId={studentId} title={title} subtitle={subtitle} onClose={handleClose} />
      )}
    </>
  )
}

export default OpenTaskModalButton
