import React, { useState, useEffect } from 'react'
import { Button } from '@plurall/elo'

import { ComponentDrawer, PageContainer, Loading } from 'components'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { getGradesAndClasses } from 'graphql/queries'

import { useSelectedSchoolContext } from 'contexts'
import { getEOFormat } from 'utils/reportType'
import currentYear from 'utils/currentYear'

import { useQuery } from 'react-apollo-hooks'

import GradeFilter from './GradeFilter'
import ClassFilter from './ClassFilter'
import ExtraFilters from './ExtraFilters'
import EOFormatFilter from './EOFormatFilter'

import styles from './FilterBar.module.css'
interface FilterBarProps {
  contextFormat?: string[]
  onSubmit: (filters: any) => void
  onLoadFilters: (filters: any) => void
  hasError: (hasError: any) => void
}
interface GraphQLError {
  code: string
  details: string
  message: string
  status: number
  title: string
}

const CODIGO_ERRORS = [
  'unauthorized_role',
  'user_created_after_reports_last_update',
  'reports_data_user_not_found',
  'reports_data_classes_not_found',
]

const initialDates = {
  endDate: new Date(),
  startDate: new Date(currentYear(), 0, 1),
}

const FilterBar = ({ contextFormat, onSubmit, onLoadFilters, hasError }: FilterBarProps) => {
  const { selectedSchool } = useSelectedSchoolContext()
  const schoolId = selectedSchool.id
  const [loadingExtraFilters, setLoadingExtraFilters] = useState<boolean>(false)
  const [filters, setFilters] = useState({ ...initialDates } as any)
  const [eoFormats, setEoFormats] = useState({} as any)
  const [allFilters, setAllFilters] = useState({} as any)
  const [isHidden, setIsHidden] = useState(true)
  const { grade, klasses, eoFormat, ...extraFilters } = filters
  const hasEoFormats = eoFormats?.length > 1
  const isButtonEnabled = hasEoFormats ? !!eoFormat : !!grade && !!klasses && !!klasses.length

  const { loading, data, error } = useQuery(getGradesAndClasses, {
    errorPolicy: 'all',
    variables: { schoolId, contextFormat },
  })

  const errorTracker = (errorsgraphQL: GraphQLError[]) => {
    errorsgraphQL?.forEach(errorgraphQL => {
      if (CODIGO_ERRORS.includes(errorgraphQL.code)) {
        window.PLURALL_TRACKER.track(`graphqlerror:${errorgraphQL.code}`)
      }
    })
  }

  const errorList = (errorsgraphQL: GraphQLError[]) => {
    if (errorsgraphQL && errorsgraphQL.length > 0) {
      return errorsgraphQL.map(erro => {
        return (erro as unknown) as GraphQLError
      })
    }
  }

  const errorMessage = (errorsgraphQL: GraphQLError[]) => {
    return (
      <div className={styles['error-message']}>
        <ErrorMessage
          src=''
          subTitle={
            errorsgraphQL && errorsgraphQL.length > 0
              ? errorsgraphQL[0].message?.replace('após as', 'após às')
              : ''
          }
          title={errorsgraphQL && errorsgraphQL.length > 0 ? errorsgraphQL[0].title : ''}
          secondLine={errorsgraphQL && errorsgraphQL.length > 0 ? errorsgraphQL[0].details : ''}
          showNoDataState
        />
      </div>
    )
  }

  useEffect(() => {
    if (error?.graphQLErrors && error?.graphQLErrors?.length > 0) {
      hasError(true)
      const dataError = errorList((error.graphQLErrors as unknown) as GraphQLError[])
      if (dataError) {
        errorTracker(dataError)
      }
    } else {
      hasError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error])

  useEffect(() => {
    setFilters({ ...initialDates })
    onSubmit({ ...initialDates })
    setEoFormats({})
    setAllFilters({})
    onLoadFilters({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool])

  if (loading) return <Loading />

  const handleGradeChange = (selectedGrade: Grade) => {
    setFilters({ grade: [selectedGrade], ...initialDates })
    setEoFormats([])
  }

  const handleKlassChange = (selectedKlasses: Klass[]) => {
    const formats = selectedKlasses.flatMap(klass =>
      klass.eoFormats?.map(format => ({
        id: format,
        value: getEOFormat(format),
      })),
    )

    const uniqueFormats = formats.filter(
      (format, item) => formats.findIndex((current: any) => format?.id === current.id) === item,
    )

    setFilters({
      eoFormat: uniqueFormats && uniqueFormats?.length === 1 ? uniqueFormats[0] : undefined,
      grade: filters.grade,
      klasses: selectedKlasses,
      ...initialDates,
    })
    setEoFormats(uniqueFormats)
  }

  const handleFormatChange = (selectedFormat: Item) => {
    setFilters({
      eoFormat: selectedFormat,
      grade: filters.grade,
      klasses: filters.klasses,
      ...initialDates,
    })
  }

  const handleExtraFiltersChange = (newFilters: any) => {
    const currentFilters = { ...filters }
    const fixedFilters = {
      eoFormat,
      grade,
      klasses,
    }
    const newExtraFilter = Object.keys(newFilters)[0]
    const hasDisciplines = Object.keys(currentFilters).includes('disciplines')
    const hasNotebooks = Object.keys(currentFilters).includes('notebooks')
    const filterStartDate =
      newFilters.startDate || currentFilters.startDate || initialDates.startDate
    const filterEndDate = newFilters.endDate || currentFilters.endDate || initialDates.endDate

    let newFiltersTest = {
      ...currentFilters,
      ...newFilters,
      endDate: filterEndDate,
      startDate: filterStartDate,
    }

    if (newFilters[newExtraFilter]?.length === 0 || newFilters[newExtraFilter]?.length > 1) {
      if (hasDisciplines && newExtraFilter === 'disciplines') {
        newFiltersTest = {
          ...fixedFilters,
          endDate: filterEndDate,
          startDate: filterStartDate,
          ...newFilters,
        }
      } else if (hasNotebooks && newExtraFilter === 'notebooks') {
        newFiltersTest = {
          ...fixedFilters,
          endDate: filterEndDate,
          startDate: filterStartDate,
          ...newFilters,
        }
        if (hasDisciplines) newFiltersTest.disciplines = currentFilters.disciplines
      } else if (newExtraFilter === 'testTypes') {
        newFiltersTest = {
          ...fixedFilters,
          endDate: filterEndDate,
          startDate: filterStartDate,
          ...newFilters,
        }
        if (hasDisciplines) newFiltersTest.disciplines = currentFilters.disciplines
        if (hasNotebooks) newFiltersTest.notebooks = currentFilters.notebooks
      }
    }

    setFilters(newFiltersTest)
  }

  const handleButtonClicked = () => {
    onSubmit(filters)
    setIsHidden(!isHidden)
    onLoadFilters(allFilters)

    const { disciplines, lists, testTypes } = extraFilters

    window.PLURALL_TRACKER.track('search-filter:generate', {
      class_name: !!klasses && !!klasses.length,
      discipline_name: !!disciplines && !!disciplines.length,
      grade_name: !!grade,
      list_name: !!lists && !!lists.length,
      testType_name: !!testTypes && !!testTypes.length,
    })
  }

  const handleHide = () => {
    setIsHidden(!isHidden)
  }

  const handleLoadFilters = (newFilters: string[]) => {
    const currentFilters = { ...allFilters }
    const keepFilters: any = {}

    setAllFilters({ ...currentFilters, ...newFilters })

    newFilters.forEach(filterName => {
      if (extraFilters[filterName]) keepFilters[filterName] = extraFilters[filterName]
    })
    setFilters({
      eoFormat,
      grade,
      klasses,
      ...initialDates,
      ...keepFilters,
    })
  }

  const hasEoFormatSelected = hasEoFormats && eoFormat
  const showExtraFilters = hasEoFormats
    ? hasEoFormatSelected
    : grade && klasses && klasses.length > 0

  if (error) return errorMessage((error.graphQLErrors as unknown) as GraphQLError[])

  return (
    <>
      <div className={styles.bar}>
        <ComponentDrawer hidden={isHidden} onHide={handleHide}>
          <PageContainer className={styles.container}>
            <GradeFilter
              grades={data?.me?.grades || []}
              selected={grade && grade[0]}
              onChange={handleGradeChange}
            />
            <ClassFilter
              selectedGrade={grade && grade[0]}
              selected={klasses}
              onChange={handleKlassChange}
            />
            {eoFormats?.length > 1 && (
              <EOFormatFilter
                formats={eoFormats}
                selected={eoFormat}
                onChange={handleFormatChange}
              />
            )}

            {showExtraFilters && (
              <ExtraFilters
                contextFormat={contextFormat?.length ? contextFormat : [eoFormat.id]}
                schoolId={schoolId}
                key={klasses.length}
                classIds={klasses}
                gradeId={grade[0].id}
                eoFormat={eoFormat?.id}
                onChange={handleExtraFiltersChange}
                filters={extraFilters}
                onLoadFilters={handleLoadFilters}
                onLoading={setLoadingExtraFilters}
              />
            )}
            <Button disabled={!isButtonEnabled} onClick={handleButtonClicked} data-test-id='search'>
              Buscar
            </Button>
          </PageContainer>
        </ComponentDrawer>
      </div>
      {loadingExtraFilters && <Loading />}
    </>
  )
}

export default FilterBar
