import React from 'react'
import { EngagementRole, ReportType } from 'types/enums'
import { Card, CardStatus, NewTable as Table, Cell } from 'components'
import defaultSort from 'utils/defaultSort'
import styles from './styles.module.css'

interface EngagementTableProps {
  data: EngagementTeacher[] | EngagementStudent[]
  role: EngagementRole
  loading: boolean
  doubtsAvailable?: boolean
}

const EngagementTable = ({ data, loading, role, doubtsAvailable = false }: EngagementTableProps) => {
  const label = role === EngagementRole.Professor ? 'professor' : 'aluno'

  const renderTable = () =>
    data.length ? (
      role === EngagementRole.Professor ? (
        renderTableTeacher(data as EngagementTeacher[])
      ) : (
        renderTableStudent(data as EngagementStudent[], doubtsAvailable)
      )
    ) : (
      <CardStatus status='not-found' message={`Não encontramos nenhum ${label} na sua escola.`} />
    )

  return (
      <Card className={styles.card}>{loading ? <CardStatus /> : renderTable()}</Card>
  )
}

const renderTableTeacher = (data: EngagementTeacher[]) => {
  const headers = [
    { id: 'name', label: 'Nome', sortable: true },
    { id: 'disciplines', label: 'Disciplina', sortable: true, width: 300 },
    { id: 'lastAccess', label: 'Último acesso', sortable: true },
    { id: 'total', label: 'Dias de acesso', sortable: true },
  ]

  return (
    <Table type={ReportType.Engajamento} headers={headers} data={data.sort(defaultSort('name'))} defaultSortBy='name'>
      {({ name, disciplines, lastAccess, total }: any) => (
        <>
          <Cell>{name}</Cell>
          <Cell width={headers[1].width}>{disciplines}</Cell>
          <Cell>{lastAccess}</Cell>
          <Cell>{total}</Cell>
        </>
      )}
    </Table>
  )
}

const renderTableStudent = (data: EngagementStudent[], doubtsAvailable: boolean) => {
  const headersDoubts = doubtsAvailable
    ? [
        { id: 'doubtsRead', label: 'Dúvidas consultadas', sortable: true },
        { id: 'doubtsPosted', label: 'Dúvidas enviadas', sortable: true },
      ]
    : []

  const headers = [
    { id: 'name', label: 'Nome', sortable: true },
    { id: 'gradeName', label: 'Série', sortable: true },
    { id: 'className', label: 'Turma', sortable: true },
    { id: 'lastAccess', label: 'Último acesso', sortable: true },
    { id: 'total', label: 'Dias de acesso', sortable: true },
  ].concat(headersDoubts)

  return (
    <Table
      type={ReportType.Engajamento}
      headers={headers}
      data={data.sort(defaultSort('name'))}
      defaultSortBy='name'
      limit={50}
    >
      {({ name, gradeName, className, lastAccess, total, doubtsRead, doubtsPosted }: any) => (
        <>
          <Cell>{name}</Cell>
          <Cell>{gradeName}</Cell>
          <Cell>{className}</Cell>
          <Cell>{lastAccess}</Cell>
          <Cell>{total}</Cell>
          {doubtsAvailable && <Cell>{doubtsRead}</Cell>}
          {doubtsAvailable && <Cell>{doubtsPosted}</Cell>}
        </>
      )}
    </Table>
  )
}

export default EngagementTable
