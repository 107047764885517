import React, { useContext, useEffect, useState } from 'react'
import defaultSort from 'utils/defaultSort'
import EngagementClient from 'utils/restClient'
import { Card, CardStatus } from 'components'
import { getIds } from 'utils/itemBuilder'
import { LoggedUserContext } from 'utils/context'
import { PlurallCharts } from '@plurall/charts'
import styles from './styles.module.css'

interface DoubtsDisciplineChartProps {
  filters: EngagementFilter
}

const doubtsPostedLabel = 'Dúvidas enviadas'
const doubtsReadLabel = 'Dúvidas consultadas'
const fontColorLabel = 'rgb(141, 148, 150)'

const DoubtsByDisciplineChart = ({ filters }: DoubtsDisciplineChartProps) => {
  const user = useContext(LoggedUserContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [doubts, setDoubts] = useState<DoubtDiscipline[]>([])

  useEffect(() => {
    const getDoubts = async () => {
      setLoading(true)
      const newDoubts = await fetchDoubts(user, filters)
      setDoubts(newDoubts.sort(defaultSort('totalRead')).reverse())
      setLoading(false)
    }

    getDoubts()
  }, [user, filters])

  const renderChart = () =>
    doubts.length ? (
      <PlurallCharts
        ariaLabel='Dúvidas dos alunos por disciplina'
        backgroundColor='#FFF'
        data={[
          {
            backgroundColor: '#00C782',
            barPercentage: 0.9,
            categoryPercentage: 0.4,
            data: doubts.map(discipline => discipline.totalRead),
            label: doubtsReadLabel,
          },
          {
            backgroundColor: '#A056B5',
            barPercentage: 0.9,
            categoryPercentage: 0.4,
            data: doubts.map(discipline => discipline.totalSent),
            label: doubtsPostedLabel,
          },
        ]}
        dataTestId='chart-doubts-by-discipline'
        height='400px'
        labels={doubts.map(discipline => discipline.disciplineName)}
        options={{
          legend: {
            labels: {
              boxWidth: 64,
              fontColor: fontColorLabel,
            },
            position: 'bottom',
          },
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: fontColorLabel,
                  lineHeight: '24px',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: fontColorLabel,
                  lineHeight: '24px',
                  suggestedMax: 10,
                  suggestedMin: 0,
                },
              },
            ],
          },
        }}
      />
    ) : (
      <CardStatus
        status='not-found'
        message='Não há registro de dúvidas consultadas e enviadas para o período selecionado.'
      />
    )

  return <Card className={styles.card}>{loading ? <CardStatus /> : renderChart()}</Card>
}

const fetchDoubts = async (user: User, filters: EngagementFilter) => {
  const client = new EngagementClient()
  return await client.fetchDoubts(
    user.uid,
    filters.school.id,
    filters.month.id,
    getIds(filters.grades),
    getIds(filters.classes),
  )
}

export default DoubtsByDisciplineChart
