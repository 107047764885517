/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Link, Text } from 'plurall-ui'
import { PageContainer, Layout, ErrorMessage } from 'components'
import notFoundImage from 'images/not-found.svg'

class ErrorPage extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  state = {}

  componentDidMount = () => {
    const { title, message } = queryString.parse(this.props.location.search)
    this.setMessage(title, message)
  }

  setMessage = () => {
    const { title, message } = queryString.parse(this.props.location.search)
    const defaultMsg = (
      <>
        <Text secondary size='big' element='span'>
          Tente&nbsp;
        </Text>

        <Link href={null} size='big' onClick={this.reloadPage}>
          recarregar a página
        </Link>

        <Text secondary size='big' element='span'>
          &nbsp;ou&nbsp;
        </Text>

        <Link href={null} size='big' onClick={this.goBack}>
          voltar
        </Link>

        <Text secondary size='big' element='span'>
          &nbsp;para a página anterior.
        </Text>

        <br />

        <Text secondary size='big' element='span'>
          Se o problema persistir&nbsp;
        </Text>

        <Link size='big' href='mailto:contato@plurall.net'>
          entre em contato
        </Link>

        <Text secondary size='big' element='span'>
          &nbsp;com o suporte.
        </Text>
      </>
    )

    this.setState({
      msg: message && title !== 'undefined' ? message : defaultMsg,
      title: title !== 'undefined' ? title : '',
    })
  }

  goBack = event => {
    event.preventDefault()
    window.history.back()
  }

  reloadPage = event => {
    event.preventDefault()
    window.location.reload()
  }

  render() {
    return (
      <Layout>
        <PageContainer>
          <ErrorMessage title={this.state.title} subTitle={this.state.msg} src={notFoundImage} />
        </PageContainer>
      </Layout>
    )
  }
}

export default ErrorPage
