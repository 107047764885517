import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { Icon } from 'plurall-ui'
import { Heading as EloHeading, Link, Tooltip } from '@plurall/elo'

import styles from './Answers.module.css'

interface HeadingProps {
  tasks: Task[]
  hasDiscursiveQuestions: boolean
  hasMultipleTries: boolean
  expandedAnswers: string[]
  setExpandedAnswers: Dispatch<SetStateAction<string[]>>
}

const Heading: FunctionComponent<HeadingProps> = ({
  tasks,
  hasDiscursiveQuestions,
  hasMultipleTries,
  expandedAnswers,
  setExpandedAnswers,
}) => (
  <div className={styles.answerHeader}>
    <EloHeading size='small' className={styles.answerTitle}>
      <span>Respostas</span>
      <div className={styles.tooltip}>
        <Tooltip
          content={getAnswerTooltipText(hasDiscursiveQuestions, hasMultipleTries)}
          position='top'
        >
          <Icon type='help' size='small' />
        </Tooltip>
      </div>
    </EloHeading>

    <Link
      onClick={handleToggleAllAnswers(tasks, expandedAnswers, setExpandedAnswers)}
      secondary
      dataTestId={`${expandedAnswers.length > 0 ? 'hide' : 'show'}-all-answers`}
    >
      {expandedAnswers.length > 0 ? 'Ocultar' : 'Exibir'} todas as respostas
    </Link>
  </div>
)

const handleToggleAllAnswers = (
  tasks: Task[],
  expandedAnswers: string[],
  setExpandedAnswers: Dispatch<SetStateAction<string[]>>,
) =>
  () => {
    setExpandedAnswers(expandedAnswers.length > 0 ? [] : tasks.map(task => task.taskId))
  }

const getAnswerTooltipText = (hasDiscursiveQuestions: boolean, hasMultipleTries: boolean) => {
  const tooltipText = 'São as respostas dadas pelo aluno a partir dos filtros selecionados em sua busca.'
  const multipleTriesText = hasMultipleTries ? ', além das tentativas feitas até a resposta final' : ''

  const discursiveQuestionsText = `Nas questões objetivas, serão exibidos os acertos e erros, `
    + `respectivamente${multipleTriesText}. Nas questões discursivas, será exibida a redação `
    + `ou imagem enviada a partir de uma resposta fotografada pelo aluno.`
  const objectivesQuestionsText = `Serão exibidos os acertos e erros, respectivamente${multipleTriesText}.`

  return `${tooltipText} ${hasDiscursiveQuestions ? discursiveQuestionsText : objectivesQuestionsText}`
}

export default Heading
