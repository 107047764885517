import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const Card = ({ children, className }) => (
  <div className={[styles.card, className].join(' ')}>{children}</div>
)

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Card
