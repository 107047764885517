import Filter from 'components/Filter'
import React from 'react'

interface GradeFilterProps {
  grades: Grade[]
  selected?: Grade
  onChange: (grade: Grade) => void
}

const GradeFilter = ({ grades, selected, onChange }: GradeFilterProps) => {
  return (
    <Filter
      items={grades}
      name='Série'
      onChange={onChange}
      selected={selected && selected.id}
    />
  )
}

export default GradeFilter
