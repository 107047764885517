import React from 'react'

const LoggedUserContext = React.createContext<User>({} as User)

const CurrentSchool = React.createContext<Partial<School>>({})

const FilterContext = React.createContext<any>({})

const ContextFormat = React.createContext<string[]>([])

const ReportContextFormat = React.createContext<Partial<ContextFormat>>({})

export { LoggedUserContext, CurrentSchool, FilterContext, ContextFormat, ReportContextFormat }
