import { checkTaskNoTitle, checkMultipleTries, dashConcatText } from 'utils/tasks'

export const taskFullTitle = (task: Task) => {
  return dashConcatText([
    task.discipline,
    task.activityTitle,
    task.taskTitle,
  ])
}

export const taskTitle = (task: Task) => {
  return checkTaskNoTitle(task.taskWorkflowContext)
    ? '' : dashConcatText([task.testType, task.notebookName, task.materialName])
}

export const hasTaskMultipleTries = (tasks: Task[]) => {
  return tasks.some((task: Task) => checkMultipleTries(task.taskWorkflowContext))
}
