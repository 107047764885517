import React from 'react'
import PropTypes from 'prop-types'
import { Card, Notification } from 'components'
import notFoundImage from 'images/not-found.svg'

const NotFoundCard = ({ title, alt, className }) => (
  <Card className={className}>
    <Notification title={title}>
      <img src={notFoundImage} alt={alt} />
    </Notification>
  </Card>
)

NotFoundCard.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
}

NotFoundCard.defaultProps = {
  title: 'Sua busca não obteve nenhum resultado',
  alt: 'Nenhum resultado encontrado',
}

export default NotFoundCard
