import React from 'react'
import PropTypes from 'prop-types'
import { ActionDescriptor } from 'components'

import styles from './ComponentDrawer.module.css'

const ComponentDrawer = ({ children, hidden, onHide }) => (
  <div className={styles.drawer}>
    <div className={`${styles.bar} ${hidden ? styles.hidden : ''}`}>
      <ActionDescriptor text='Filtros' icon='filter' onClick={onHide} />
    </div>
    <div className={`${styles.overlay} ${hidden ? styles.hidden : ''}`}>
      <div className={styles.bla}>{children}</div>
    </div>
  </div>
)

ComponentDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  hidden: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
}

export default ComponentDrawer
