const totalAccessedMonthPercentage = (overview: EngagementOverview) => {
  if (overview.totalRegistered === 0) return 0
  return (overview.totalAccessedMonth / overview.totalRegistered) * 100
}

const totalAccessedImprovementMonth = (overview: EngagementOverview) => {
  if (overview.totalAccessedPreviousMonth === 0) return undefined
  const delta = overview.totalAccessedMonth - overview.totalAccessedPreviousMonth
  return (delta / overview.totalAccessedPreviousMonth) * 100
}

const totalAccessedPercentage = (overview: EngagementOverview) => {
  if (overview.totalRegistered === 0) return 0
  return (overview.totalAccessed / overview.totalRegistered) * 100
}

export { totalAccessedMonthPercentage, totalAccessedImprovementMonth, totalAccessedPercentage }
