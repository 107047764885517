import classNames from 'classnames/bind'
import { Icon } from 'plurall-ui'
import { Link, Text } from '@plurall/elo'
import React, { FunctionComponent } from 'react'
import AnimateHeight from 'react-animate-height'
import { TaskStatus, TaskType } from 'types/enums'
import Letter from '../Letter'
import Answer from './Answer'

import { checkContainsText, checkMultipleTries } from 'utils/tasks'
import { ReactComponent as DiscursiveTaskIcon } from './DiscursiveTaskIcon.svg'
import styles from './Task.module.css'

const cx = classNames.bind(styles)

interface TaskProps {
  answers?: Answer[]
  subItems: any
  attempt?: string
  expanded?: boolean
  id: string
  onAnswerToggle?: (id: string) => void
  options?: Option[]
  resultStatus?: TaskStatus
  taskWorkflowContext?: string
  text: string
  officialAnswer: string
  type?: TaskType
}

const Task: FunctionComponent<TaskProps> = ({
  answers,
  subItems,
  expanded,
  id,
  onAnswerToggle,
  options,
  resultStatus,
  taskWorkflowContext,
  text,
  officialAnswer,
  type,
}) => {
  const buttonText = expanded ? 'Ocultar resposta' : 'Exibir resposta'
  const isOpenResponse = type === TaskType.OpenResponse || type === TaskType.Essay
  const showAttemptLabel = checkMultipleTries(taskWorkflowContext)

  return (
    <>
      {answers && (
        <div className={styles.subHeader}>
          <div className={styles.type}>
            {renderHeaderIcon(isOpenResponse, resultStatus)}
            {renderHeaderText(answers, isOpenResponse, showAttemptLabel)}
          </div>

          {onAnswerToggle && (
            <Link
              color='green'
              onClick={() => onAnswerToggle(id)}
              secondary
              dataTestId={`${expanded ? 'hide' : 'show'}-answer-${id}`}
            >
              {buttonText}
            </Link>
          )}
        </div>
      )}

      <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
        {renderQuestion(
          text,
          isOpenResponse,
          showAttemptLabel,
          options,
          answers,
          expanded,
          subItems,
        )}
        {renderOfficialAnswer(officialAnswer)}
      </AnimateHeight>
    </>
  )
}

const renderHeaderIcon = (isOpenResponse: boolean, resultStatus?: string) => {
  if (isOpenResponse) return <DiscursiveTaskIcon className={styles.icon} />

  const iconType = resultStatus === TaskStatus.Correct ? 'success' : 'error'
  return <Icon type={iconType} />
}

const renderHeaderText = (
  answers: Answer[],
  isOpenResponse: boolean,
  showAttemptLabel: boolean,
) => {
  if (isOpenResponse) return <Text>Resposta discursiva</Text>

  return (
    answers &&
    answers.map((answer, index) => (
      <div className={styles.answer} key={answer.answer}>
        <Letter label={answer.answer} />
        {showAttemptLabel && <span className={styles.text}>{index + 1}ª Tentativa</span>}
      </div>
    ))
  )
}

const renderQuestion = (
  text: string,
  isOpenResponse: boolean,
  showAttemptLabel: boolean,
  options?: Option[],
  answers?: Answer[],
  expanded: boolean = true,
  subItems?: any,
) => {
  const hide = !expanded
  const classes = cx('content', { hide })

  return (
    <div className={classes}>
      <Text className={styles.answer}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Text>
      {subItems &&
        subItems.map((subItem: any) => {
          return (
            <Text className={styles.subItem}>
              <span dangerouslySetInnerHTML={{ __html: subItem.text }} />
            </Text>
          )
        })}

      {options
        ? options.map(renderOption(answers, isOpenResponse, showAttemptLabel))
        : answers && answers.map(renderStudentAnswer)}
    </div>
  )
}

const renderOption = (
  answers: Answer[] = [],
  isOpenResponse: boolean,
  showAttemptLabel: boolean,
) => (option: Option) => {
  const attemptIndex = answers.findIndex(
    answer => answer.answer && answer.answer.toUpperCase() === option.sequence,
  )
  const attempt = answers[attemptIndex]

  return (
    <Answer
      answer={isOpenResponse ? attempt.answer : option.text}
      attempt={attempt && attemptIndex + 1}
      key={option.sequence}
      lastAttempt={attempt && attemptIndex + 1 === answers.length}
      showAttemptLabel={showAttemptLabel}
      sequence={option.sequence}
      status={attempt && attempt.status}
      correct={option.correct}
    />
  )
}

const renderStudentAnswer = (answer: Answer) => {
  return (
    <Answer
      key={answer.status}
      status={answer.status}
      image={answer.image}
      answer={answer.answer}
    />
  )
}

const renderOfficialAnswer = (officialAnswer: string) => {
  return (
    <div className={styles.officialAnswer}>
      <Text bold element='span'>
        Gabarito
      </Text>
      {checkContainsText(officialAnswer) ? (
        <Text size='normal'>
          <span dangerouslySetInnerHTML={{ __html: officialAnswer }} />
        </Text>
      ) : (
        <Text className={styles.officialAnswerText}>
          Esta questão ainda não possui gabarito comentado.
        </Text>
      )}
    </div>
  )
}

Task.defaultProps = {
  expanded: true,
}

export default Task
