import React from 'react'
import { PercentageComplete, TasksDone } from 'components'
import {
  answersColumn,
  classColumn,
  examStatusColumn,
  examTimeColumn,
  nameColumn,
  numberColumn,
} from './studentColumns'
import { taskColumn, taskColumnMultipleChoiceAndOpenResponse } from './taskColumns'
import { FilterFields, TaskType } from 'types/enums'

const containsDefaultColumns = taskTypes =>
  taskTypes.includes(TaskType.MultipleChoice) || taskTypes.includes(TaskType.OpenResponse)

export const getEOTableColumns = (taskTypes, totals, filters) => {
  const isFilterExams = filters[FilterFields.exams] && filters[FilterFields.exams].length === 1
  const isFilterStandardTests =
    filters[FilterFields.standardTests] && filters[FilterFields.standardTests].length === 1

  return [
    numberColumn,
    nameColumn,
    examStatusColumn(isFilterExams || isFilterStandardTests, filters.endDate),
    classColumn(filters[FilterFields.klasses] && filters[FilterFields.klasses].length > 1),
    taskColumnMultipleChoiceAndOpenResponse(
      totals.objectiveQuestions + totals.discursiveQuestions,
      taskTypes.includes(TaskType.MultipleChoice) && taskTypes.includes(TaskType.OpenResponse),
    ),
    taskColumn(TaskType.MultipleChoice, totals, taskTypes.includes(TaskType.MultipleChoice)),
    taskColumn(TaskType.OpenResponse, totals, taskTypes.includes(TaskType.OpenResponse)),
    taskColumn(
      TaskType.Video,
      totals,
      !containsDefaultColumns(taskTypes) && taskTypes.includes(TaskType.Video),
    ),
    taskColumn(
      TaskType.Read,
      totals,
      !containsDefaultColumns(taskTypes) && taskTypes.includes(TaskType.Read),
    ),
    taskColumn(TaskType.Essay, totals, taskTypes.includes(TaskType.Essay)),
    examTimeColumn(isFilterExams || isFilterStandardTests, filters.endDate),
    answersColumn,
  ]
}

export const filterColumnsEOTable = (columnsEOTable, taskTypes, totals) =>
  columnsEOTable
    .filter(column => taskTypes.includes(column.key))
    .map(column => {
      switch (column.key) {
        case TaskType.MultipleChoice:
          return { ...column, label: `${column.header} (${totals.objectiveQuestions})` }
        case TaskType.OpenResponse:
          return { ...column, label: `${column.header} (${totals.discursiveQuestions})` }
        case TaskType.Read:
          return { ...column, label: `${column.header} (${totals.readingActivities})` }
        case TaskType.Video:
          return { ...column, label: `${column.header} (${totals.videos})` }
        case TaskType.Essay:
          return { ...column, label: `${column.header} (${totals.essay})` }
        default:
          return { ...column }
      }
    })

export const didAllTasks = (student, totals) => {
  const { objectiveQuestions, discursiveQuestions, readingActivities } = totals

  return (
    (!objectiveQuestions ||
      student?.studentTotals?.objectiveQuestionsDone === objectiveQuestions) &&
    (!discursiveQuestions ||
      student?.studentTotals?.discursiveQuestionsDone === discursiveQuestions) &&
    (!readingActivities || student?.studentTotals?.readingActivitiesDone === readingActivities)
  )
}

export const didAnyTask = (student, totals) =>
  (totals?.objectiveQuestions > 0 && student?.studentTotals?.objectiveQuestionsDone > 0) ||
  (totals?.discursiveQuestions > 0 && student?.studentTotals?.discursiveQuestionsDone > 0) ||
  (totals?.readingActivities > 0 && student?.studentTotals?.readingActivitiesDone > 0)

export const didNoTask = (student, totals) =>
  (!totals?.objectiveQuestions || student?.studentTotals?.objectiveQuestionsDone === 0) &&
  (!totals?.discursiveQuestions || student?.studentTotals?.discursiveQuestionsDone === 0) &&
  (!totals?.readingActivities || student?.studentTotals?.readingActivitiesDone === 0)

export const standardSort = (value1, value2) => {
  if (value1 < value2) {
    return -1
  }

  if (value1 > value2) {
    return 1
  }

  return 0
}

export const tableFilterOptions = studentsByFilterType => {
  const { allStudents, anyTaskDone, noTaskDone, allTasksDone } = studentsByFilterType
  return [
    {
      id: 'allStudents',
      value: `Todos os alunos (${allStudents.length})`,
    },
    {
      id: 'anyTaskDone',
      value: `Alunos que fizeram alguma questão (${anyTaskDone.length})`,
    },
    {
      id: 'noTaskDone',
      value: `Alunos que não fizeram nenhuma questão (${noTaskDone.length})`,
    },
    {
      id: 'allTasksDone',
      value: `Alunos que fizeram todas as questões (${allTasksDone.length})`,
    },
  ]
}

export const percentageString = (value, total) =>
  `${total > 0 ? Math.floor((value / total) * 100) : 0}%`

// ⚠⚠⚠ Atenção: A ordens das chaves desse objeto é importante. Favor não reordenar. ⚠⚠⚠
const orderedFilterMap = {
  grade: 'Série',
  klasses: 'Turmas',
  materials: 'Materiais',
  notebooks: 'Cadernos',
  disciplines: 'Disciplinas',
  lessons: 'Aulas',
  phs: 'Phs',
  lists: 'Listas',
  activities: 'Atividades',
  testTypes: 'Tipo de Tarefas',
  olimpicoLists: 'Listas',
  emTempoLists: 'Listas',
  startDate: 'Data de Início',
  endDate: 'Data de Término',
  dateUpdate: 'Data de Atualização',
}

export const sortByCorrect = (
  { studentTotals: { objectiveQuestionsCorrect: correctA } },
  { studentTotals: { objectiveQuestionsCorrect: correctB } },
) => standardSort(correctA, correctB)

export const sortByCorrectPerDone = (
  { studentTotals: { objectiveQuestionsCorrect: correctA, objectiveQuestionsDone: doneA } },
  { studentTotals: { objectiveQuestionsCorrect: correctB, objectiveQuestionsDone: doneB } },
) => {
  const ratioA = doneA > 0 ? correctA / doneA : 0
  const ratioB = doneB > 0 ? correctB / doneB : 0

  return standardSort(ratioA, ratioB)
}

export const renderTasksDone = (done, available, progressBar, showPercentage) => (
  <TasksDone
    done={done}
    available={available}
    progressBar={progressBar}
    showPercentage={showPercentage}
  />
)

export const renderPercentageComplete = (currentValue, maxValue, threshold) => (
  <PercentageComplete currentValue={currentValue} maxValue={maxValue} threshold={threshold} />
)

export const filterNames = key => orderedFilterMap[key]
export const filterOrder = Object.keys(orderedFilterMap)

export const fileNameExportCSV = filterFields =>
  Object.values(filterFields).includes(FilterFields.phs) ? "pH's por aluno" : 'Todas as tarefas'
