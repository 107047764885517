import React, { FunctionComponent, useState, Dispatch, SetStateAction, useEffect } from 'react'
import Modal from 'plurall-modal'
import { Text } from 'plurall-ui'
import SomosClient from 'utils/client'
import { Loading, ErrorMessage } from 'components'
import Task from '../../TaskList/Task'
import styles from './QuestionDetails.module.css'

interface QuestionDetailsProps {
  taskId: string
  title: string
  subtitle: string
  filters: any
  onClose: () => void
}

const fetchTask = async (
  taskId: string,
  setTask: Dispatch<SetStateAction<Task | undefined>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const v3Client = new SomosClient()
  const tasks: Task[] = await v3Client.getTasks([taskId])
  const task = tasks.find(t => +t.taskId === +taskId)

  setTask(task)
  setLoading(false)
}

const QuestionDetails: FunctionComponent<QuestionDetailsProps> = ({
  taskId,
  title,
  subtitle,
  filters,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [task, setTask] = useState<Task | undefined>(undefined)

  useEffect(() => {
    window.PLURALL_TRACKER.track('question-details', { task_id: taskId, filters })
    fetchTask(taskId, setTask, setLoading)
  }, [taskId, filters])

  return (
    <Modal
      maskOnClose={true}
      width='70%'
      overflow='hidden'
      padding='0 20px'
      height='calc(100% + 20px)'
      title={title}
      closed={onClose}
    >
      <div className={styles.subtitle}>
        <Text size='normal' secondary>
          {subtitle}
        </Text>
      </div>
      {loading && <Loading />}
      {!task && (
        <ErrorMessage
          src=''
          subTitle='Para acessar o conteúdo, recarregue a página ou faça o login novamente.'
          title='Falha na autenticação'
        />
      )}
      <div className={styles.modal}>{task && renderTask(task)}</div>
    </Modal>
  )
}

const renderTask = ({ taskId, options, taskType, text, officialAnswer, subItems }: Task) => {
  return (
    <div className={styles.contentItem}>
      <Task
        id={taskId}
        type={taskType}
        options={options}
        text={text}
        subItems={subItems}
        officialAnswer={officialAnswer}
      />
    </div>
  )
}

export default QuestionDetails
