import React from 'react'

import { Text } from 'plurall-ui'

import styles from './styles.module.css'

const Loading = () => (
  <div className={styles.loading}>
    <div className={styles.loadingContent}>
      <img
        className={styles.image}
        src={`${process.env.REACT_APP_ASSETS_URL}/images/loading.gif`}
        alt='Loading'
      />
      <Text bold size='small'>
        Carregando...
      </Text>
    </div>
  </div>
)

export default Loading
