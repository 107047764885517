import React, { useEffect, useState } from 'react'
import defaultSort from 'utils/defaultSort'
import EngagementClient from 'utils/restClient'

import { Card, CardStatus } from 'components'
import { getIds } from 'utils/itemBuilder'
import { PlurallCharts } from '@plurall/charts'
import styles from './styles.module.css'

interface DigitalClassesDisciplineChartProps {
  user: User
  filters: EngagementFilter
}

interface DigitalClassesTimeByDiscipline {
  disciplineName: string
  classTime: number
  classTimeToHuman: string
}

const DigitalClassesReadLabel = 'Quantidade de horas das aulas (h)'
const fontColorLabel = 'rgb(141, 148, 150)'

const DigitalClassesByDisciplineChart = ({ user, filters }: DigitalClassesDisciplineChartProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [DigitalClasses, setDigitalClasses] = useState<DigitalClassesTimeByDiscipline[]>([])

  useEffect(() => {
    const getDigitalClasses = async () => {
      setLoading(true)
      const newDigitalClasses = await fetchDigitalClassesByDiscilines(user, filters)
      setDigitalClasses(newDigitalClasses.sort(defaultSort('classTime')).reverse())
      setLoading(false)
    }

    getDigitalClasses()
  }, [user, filters])

  const renderChart = () =>
    DigitalClasses.length && !loading ? (
      <PlurallCharts
        ariaLabel='Quantidade de horas das aulas (h)'
        backgroundColor='#FFF'
        data={[
          {
            backgroundColor: '#49B9F2',
            barPercentage: 0.9,
            categoryPercentage: 0.4,
            data: DigitalClasses.map(discipline => discipline.classTime),
            label: DigitalClassesReadLabel,
          },
        ]}
        dataTestId='chart-digitalclasses-by-discipline'
        height='400px'
        labels={DigitalClasses.map(discipline => discipline.disciplineName)}
        options={{
          legend: {
            labels: {
              boxWidth: 64,
              fontColor: fontColorLabel,
            },
            position: 'bottom',
          },
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: fontColorLabel,
                  lineHeight: '24px',
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Quantidade de horas das aulas (h)',
                  fontColor: fontColorLabel,
                  lineHeight: '24px',
                },
                ticks: {
                  fontColor: fontColorLabel,
                  lineHeight: '24px',
                  suggestedMax: 8,
                  suggestedMin: 0,
                },
              },
            ],
          },
        }}
      />
    ) : (
      <CardStatus
        status='not-found'
        message='Não há registro de aulas digitais para o período selecionado.'
      />
    )

  return <Card className={styles.card}>{loading ? <CardStatus /> : renderChart()}</Card>
}

const fetchDigitalClassesByDiscilines = async (user: User, filters: EngagementFilter) => {
  const client = new EngagementClient()
  return await client.fetchDigitalClassesByDiscilines(
    user.uid,
    filters.school.id,
    getIds(filters.months),
    getIds(filters.grades),
    getIds(filters.classes),
  )
}

export default DigitalClassesByDisciplineChart
