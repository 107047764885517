/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Icon, Link, Text } from '@plurall/elo'
import { Spinner } from '@plurall/elo-beta'

import styles from './styles.module.css'

export const useExportLink = () => {
  const renderExportLink = (
    loading: boolean,
    onClick: (event: MouseEvent) => void,
    exportName: string,
    loadingName: string,
    dataTestId?: string,
  ) => {
    if (loading) {
      return (
        <div className={styles.disabled}>
          <Text secondary bold>
            {loadingName}
          </Text>
          <Spinner size='small' />
        </div>
      )
    }

    return (
      <Link secondary onClick={onClick} data-test-id={dataTestId}>
        {exportName}
        <Icon type='download' size='small' />
      </Link>
    )
  }

  return {
    renderExportLink,
  }
}
