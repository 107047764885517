import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.module.css'

const CardItem = ({ id, chart, small, verticalAlign, children }) => {
  const className = classnames(styles.item, styles[verticalAlign], { [styles.small]: small })

  return (
    <div className={className} data-test-id={id}>
      {chart && <div className={styles.chart}>{chart}</div>}
      <div className={styles.details}>{children}</div>
    </div>
  )
}

CardItem.propTypes = {
  id: PropTypes.string,
  chart: PropTypes.node,
  verticalAlign: PropTypes.oneOf(['center', 'top', 'bottom']),
  small: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

CardItem.defaultProps = {
  verticalAlign: 'center',
  small: false,
}

export default CardItem
