import PlurallClient from '@plurall/client'
import { EngagementRole } from 'types/enums'
import { currentYear } from 'utils/engagement'
import formatSchoolData from 'utils/formatSchoolData'
import {
  formatDigitalClassesOverview,
  formatDigitalClassesStatsDisciplines,
  formatEngagementDigitalClasses,
  formatAttendessDigitalClasses,
  formatEngagementTeacher,
  formatEngagementStudent,
} from 'utils/formatEngagementData'

import { getOauthClient } from './oauth'
import { getToken } from './token'

class ApiClient {
  constructor(config) {
    this.apiURL = process.env.REACT_APP_PLURALL_API_URL
    this.config = config
    this.token = getToken()
  }
  getPlurallClient = () => {
    const location = window.location.href
    const token = getToken()
    if (token !== this.token) {
      const OAuth = getOauthClient(location.pathname)
      window.location.href = OAuth.token.getUri()
      return null
    }

    const client = new PlurallClient({
      accept: this.config ? this.config.accept : 'vnd.plurall.api.v3+json',
      apiURL: process.env.REACT_APP_PLURALL_REPORTS_API,
      pmVersion: this.config?.pmVersion || 2,
      graphqlURL: process.env.REACT_APP_PLURALL_REPORTS_API,
      disableAutoCamelize: false,
      disableSnakeCaseRequest: true,
      accessToken: token,
      applicationId: process.env.REACT_APP_PLURALL_ID_APPLICATION,
      apolloConfig: { fetchPolicy: 'no-cache' },
      onError: error => {
        if (error && error.response.status === 403) {
          const title = error.response.data.title // eslint-disable-line
          const message = error.response.data.msg || error.response.data.message
          window.location.href = `/error?title=${title}&message=${message}&status=403`
        }
      },
    })
    return client
  }

  isValidResponseData = data => !!(Array.isArray(data) && data.length)

  fetchDigitalClassesOverview = async (
    userId,
    schoolId,
    months = [],
    gradeIds = [],
    classIds = [],
  ) => {
    const params = {
      year: currentYear(),
      months,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/digitalclasses/overview', params)
      .then(response => formatDigitalClassesOverview(response.data))
      .catch(error => {
        const { errors } = error.response?.data
        if (errors.status === 403) {
          window.location.href = `/error?title=${errors.title}&message=${errors.message}&status=${errors.status}`
        }
      })
  }

  fetchDigitalClassesLastUpdate = async () =>
    this.getPlurallClient()
      .restClient.get('v1/reports/engagement/digitalclasses/last_update')
      .then(response => response?.data?.lastUpdate)

  fetchDigitalClassesByDiscilines = async (
    userId,
    schoolId,
    months = [],
    gradeIds = [],
    classIds = [],
  ) => {
    const params = {
      year: currentYear(),
      months,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/digitalclasses/stats/disciplines', params)
      .then(response =>
        this.isValidResponseData(response.data)
          ? formatDigitalClassesStatsDisciplines(response.data)
          : [],
      )
  }

  fetchEngagementLastUpdate = async () =>
    this.getPlurallClient()
      .restClient.get('v1/reports/engagement/last_update')
      .then(response => response?.data?.lastUpdate)

  fetchDigitalClassesByDiscilines = async (
    userId,
    schoolId,
    months = [],
    gradeIds = [],
    classIds = [],
  ) => {
    const params = {
      year: currentYear(),
      months,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/digitalclasses/stats/disciplines', params)
      .then(response =>
        this.isValidResponseData(response.data)
          ? formatDigitalClassesStatsDisciplines(response.data)
          : [],
      )
  }

  fetchDigitalClasses = async (userId, schoolId, months = [], gradeIds = [], classIds = []) => {
    const params = {
      year: currentYear(),
      months,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('/v1/reports/engagement/digitalclasses', params)
      .then(response =>
        this.isValidResponseData(response.data)
          ? formatEngagementDigitalClasses(response.data)
          : [],
      )
      .catch(error => {
        const { errors } = error.response?.data
        if (errors.status === 403) {
          window.location.href = `/error?title=${errors.title}&message=${errors.message}&status=${errors.status}`
        }
      })
  }

  fetchAttendeesDigitalClasses = async classId =>
    this.getPlurallClient()
      .restClient.get(`/v1/reports/engagement/digitalclasses/${classId}/attendees`)
      .then(response =>
        this.isValidResponseData(response?.data?.data)
          ? formatAttendessDigitalClasses(response?.data?.data)
          : [],
      )

  fetchSchools = () =>
    this.getPlurallClient()
      .restClient.post('v1/reports/engagement/schools')
      .then(response => response.data)
      .catch(error => {
        const { errors } = error.response?.data
        if (errors) {
          window.location.href = `/error?title=${errors.title}&message=${errors.message}&status=${errors.status}`
        }
      })

  fetchSchoolData = async schoolId =>
    this.getPlurallClient()
      .restClient.get(`/v1/reports/engagement/school/${schoolId}/classes`)
      .then(response => {
        if (response.data?.length) {
          return formatSchoolData(response.data)
        }
        return response
      })

  fetchEngagementOverview = async (
    role,
    userId,
    schoolId,
    month,
    disciplineIds = [],
    gradeIds = [],
    classIds = [],
  ) => {
    const params = {
      year: currentYear(),
      month,
      user_id: userId,
      school_ids: [schoolId],
      discipline_ids: disciplineIds,
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    const path =
      role === EngagementRole.Professor
        ? 'v1/reports/engagement/teachers/access/overview'
        : 'v1/reports/engagement/students/access/overview'

    return this.getPlurallClient()
      .restClient.post(path, params)
      .then(response => response.data)
      .catch(error => {
        const { errors } = error.response?.data
        if (errors?.status === 403) {
          window.location.href = `/error?title=${errors.title}&message=${errors.message}&status=${errors.status}`
        }
      })
  }

  fetchEngagementTeacher = async (userId, schoolId, month, disciplineIds = []) => {
    const params = {
      year: currentYear(),
      month,
      user_id: userId,
      school_ids: [schoolId],
      discipline_ids: disciplineIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/teachers', params)
      .then(response => {
        if (response?.error?.response?.status === 404) {
          return []
        }
        return formatEngagementTeacher(response.data)
      })
  }

  fetchEngagementStudent = async (userId, schoolId, month, gradeIds = [], classIds = []) => {
    const params = {
      year: currentYear(),
      month,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/students', params)
      .then(response => {
        if (response?.error?.response?.status === 404) {
          return []
        }
        return formatEngagementStudent(response.data)
      })
  }

  fetchDoubts = async (userId, schoolId, month, gradeIds = [], classIds = []) => {
    const params = {
      year: currentYear(),
      month,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/students/doubt/stats', params)
      .then(response => {
        if (response?.error?.response?.status === 404) {
          return []
        }
        return response.data
      })
  }

  fetchEngagementTeacherExport = async (userId, schoolId, month, disciplineIds = []) => {
    const params = {
      year: currentYear(),
      month,
      user_id: userId,
      school_ids: [schoolId],
      discipline_ids: disciplineIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/teachers?format=xls', params, {
        responseType: 'blob',
      })
      .then(response => response)
      .catch()
  }

  fetchEngagementStudentExport = async (userId, schoolId, month, gradeIds = [], classIds = []) => {
    const params = {
      year: currentYear(),
      month,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/students?format=xls', params, {
        responseType: 'blob',
      })
      .then(response => response)
      .catch()
  }

  fetchDigitalClassesExport = async (
    userId,
    schoolId,
    months = [],
    gradeIds = [],
    classIds = [],
  ) => {
    const params = {
      year: currentYear(),
      months,
      user_id: userId,
      school_ids: [schoolId],
      grade_ids: gradeIds,
      class_ids: classIds,
    }
    return this.getPlurallClient()
      .restClient.post('/v1/reports/engagement/digitalclasses?format=xls', params, {
        responseType: 'blob',
      })
      .then(response => response)
      .catch()
  }

  fetchEngagementAttendeesListExport = async classId => {
    const params = {
      class_id: classId,
    }
    return this.getPlurallClient()
      .restClient.post('v1/reports/engagement/digitalclasses/attendees?format=xls', params, {
        responseType: 'blob',
      })
      .then(response => response)
      .catch()
  }

  fetchReportExport = async (variables, query, operationName) => {
    const params = {
      variables,
      query,
      operationName,
    }

    return this.getPlurallClient()
      .restClient.post('exporter/xlsx', params, {
        responseType: 'blob',
      })
      .then(response => response)
      .catch()
  }
}

export default ApiClient
