import React from 'react'
// @ts-ignore
import { Dialog, Checkbox, Divider, InputDropdown } from '@plurall/elo-beta'

import styles from './SchoolSelection.module.css'

interface FormattedSchools {
  label: string
  id: string | number
  checked: boolean
}

interface SchoolSelectionProps {
  formattedSchools: FormattedSchools[]
  setFormattedSchools: any
  maxTotalSchools: number
  schools: ISchool[]
  handleConfirmSchool: any
  handleChangeRadioSchool: any
}

const SchoolSelection = ({
  formattedSchools,
  setFormattedSchools,
  maxTotalSchools,
  schools,
  handleConfirmSchool,
  handleChangeRadioSchool,
}: SchoolSelectionProps) => {
  const handleChangeDropdownSchool = (selectedId: number | string) => {
    const newSchools = formattedSchools.map(item => ({
      ...item,
      checked: selectedId === item.id,
    }))
    setFormattedSchools(newSchools)
  }

  return (
    <Dialog
      clickOutside={false}
      title='Selecione a escola que deseja'
      primaryButtonText='Confirmar'
      onPrimaryClick={handleConfirmSchool}
      onClose={handleConfirmSchool}
      size='medium'
    >
      <div>
        {formattedSchools.length < maxTotalSchools ? (
          formattedSchools.map((school, index) => (
            <div key={school.id} className={styles.CheckboxContainer}>
              <Checkbox
                type='radio'
                checked={school.checked}
                dataTestId={`radio-school-${index + 1}`}
                onChange={() => handleChangeRadioSchool(index)}
                label={school.label}
              />
              <div className={styles.Divider}>
                {schools.length !== index + 1 && <Divider light />}
              </div>
            </div>
          ))
        ) : (
          <div className={styles.InputDropdownContainer}>
            <InputDropdown
              dataTestId='school-select'
              disabled={false}
              items={formattedSchools}
              label='Alterar escola'
              onChange={(school: FormattedSchools) => handleChangeDropdownSchool(school.id)}
              placeholder='Selecionar'
              searchable
              scrollbarMaxHeight
              scrollbarWrapperHeight='250'
            />
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default SchoolSelection
