import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'

const Separator = ({ className }) => {
  return <div className={classNames(styles.separator, className)} />
}

Separator.propTypes = {
  className: PropTypes.string,
}

export default Separator
