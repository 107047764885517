import { ErrorBoundary } from 'components'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router, Switch } from 'react-router-dom'
import { initSentry } from 'utils/sentry'
import { App, LoginCallbackPage, ErrorPage } from 'views'
import { ApolloProvider as ApolloProviderHooks } from 'react-apollo-hooks'
import { graphqlClient } from 'utils/graphqlClient'
import { SelectedSchoolProvider } from 'contexts/SelectedSchoolContext'
import './index.css'
import './_variables.css'

// eslint-disable-next-line
Array.prototype.flatMap = function flatMap(lambda) {
  return Array.prototype.concat.apply([], this.map(lambda))
}

let environment = process.env.REACT_APP_NODE_ENV
if (environment === 'local' || environment === 'pull_request') {
  environment = 'staging'
}

window.PLURALL_TRACKER = new window.PlurallTracker({
  clientId: 'report:eo',
  environment,
  hotjarId: 1414184,
  plurallFeedback: 3,
})

const callbackHistory: Array<(callback: (location: any) => void) => void> = []
window.PLURALL_CUSTOM_HISTORY = {
  listen: (callback: any) => {
    if (!callbackHistory.find(c => c === callback)) {
      callbackHistory.push(callback)
    }
  },
}
const customHistory = createBrowserHistory()
customHistory.listen((location: any) => {
  if ('PLURALL_TRACKER' in window) {
    window.PLURALL_TRACKER.pageView()
  }
  callbackHistory.forEach(callback => {
    callback(location)
  })
})

initSentry(customHistory)

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProviderHooks client={graphqlClient}>
      <Router history={customHistory}>
        <Switch>
          <Route path='/login/callback' component={LoginCallbackPage} />
          <Route path='/error' render={props => <ErrorPage {...props} />} />
          <SelectedSchoolProvider>
            <PrivateRoute path='/' component={App} reportContext={{}} />
          </SelectedSchoolProvider>
        </Switch>
      </Router>
    </ApolloProviderHooks>
  </ErrorBoundary>,
  document.getElementById('root'),
)
