import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'
import { Text } from '@plurall/elo'
import styles from './CustomTabs.module.css'

interface CustomTabsProps {
  className?: string
  contentClass?: string
  defaultTab?: string
  tabs: CustomTabProps[]
  tabsClass?: string
  onClick?: (args: any) => void
}

const CustomTabs: FunctionComponent<CustomTabsProps> = ({
  defaultTab,
  tabs,
  className = '',
  contentClass = '',
  tabsClass = '',
  onClick,
}) => {
  const [visible, setVisible] = useState(defaultTab || tabs[0].key)

  if (!tabs) return null

  return (
    <div className={classNames('tabs-wrapper', className)}>
      <div className={classNames('tabs', styles.tabs, tabsClass)}>
        {tabs.map(({ key, label }) => (
          <div
            data-test-id={key}
            key={key}
            className={classNames('tab', styles.tab, visible === key ? styles.activeTab : '')}
            onClick={() => {
              setVisible(key)
              onClick && onClick(key)
            }}
          >
            <Text size='small' className={styles.tabText}>
              {label}
            </Text>
          </div>
        ))}
      </div>
      <div className={classNames('content', styles.content, contentClass)}>
        {tabs.map(
          ({ key, content, className: itemClass }) =>
            visible === key &&
            content && (
              <div key={key} className={classNames('content-item', itemClass)}>
                {content()}
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default CustomTabs
