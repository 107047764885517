import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Text } from '@plurall/elo-beta'

import styles from './styles.module.css'

const Notification = ({ title, subtext, children }) => (
  <div className={styles.notification}>
    <div className={styles.title}>
      <Heading size='normal' variant='brand' textAlign='center'>
        {title}
      </Heading>
    </div>
    <div className={styles.content}>{children}</div>
    {subtext && (
      <div className={styles.subtext}>
        {typeof subtext === 'string' ? (
          <Text shy alignment='center'>
            {subtext}
          </Text>
        ) : (
          subtext
        )}
      </div>
    )}
  </div>
)

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtext: PropTypes.node,
}

export default Notification
