import React from 'react'
import { PercentageComplete, TasksDone } from 'components'
import { TaskType } from 'types/enums'

interface Countables {
  correct?: number
  done?: number
  total?: number
  watched?: number
}

const LABEL_DONE_AVAILABLE = 'Feitas / Disponíveis'

const numbers = {
  accessor: (_: any, index: number) => index + 1,
  header: 'Nº',
  key: 'number',
  sortable: false,
  visible: true,
  width: 47,
}

const disciplines = {
  accessor: ({ discipline: { name } }: { discipline: { name: string } }) => name,
  header: 'Disciplina',
  key: 'discipline',
  sortable: false,
  visible: true,
  width: 196,
}

const video = (visible: boolean) => ({
  columns: [
    {
      accessor: ({ videos: { watched, total } }: { videos: Countables }) => ({ watched, total }),
      cell: ({ watched = 0, total = 0 }: Countables) => (
        <TasksDone done={watched} available={total} />
      ),
      header: 'Vistos / Disponíveis',
      key: 'watched_over_available',
      sortable: false,
      visible,
      width: 150,
    },
  ],
  header: 'Vídeos das aulas',
  key: 'videos',
  sortable: false,
  visible,
})

const readingActivities = (visible: boolean) => ({
  columns: [
    {
      accessor: ({ readingActivities: { done, total } }: { readingActivities: Countables }) => ({
        done,
        total,
      }),
      cell: ({ done = 0, total = 0 }: Countables) => <TasksDone done={done} available={total} />,
      header: 'Vistas / Disponíveis',
      key: 'done_over_available_reading_activities',
      sortable: false,
      visible,
      width: 150,
    },
  ],
  header: 'Questões de Leitura',
  key: 'reading_activities',
  sortable: false,
  visible,
})

const essay = (visible: boolean) => ({
  columns: [
    {
      accessor: ({ essay: { done, total } }: { essay: Countables }) => ({ done, total }),
      cell: ({ done = 0, total = 0 }: Countables) => <TasksDone done={done} available={total} />,
      header: LABEL_DONE_AVAILABLE,
      key: 'done_over_available_essay',
      sortable: false,
      visible,
      width: 150,
    },
  ],
  header: 'Questões de Redação',
  key: 'essay',
  sortable: false,
  visible,
})

const discursiveQuestions = (visible: boolean) => ({
  columns: [
    {
      accessor: ({
        discursiveQuestions: { done, total },
      }: {
        discursiveQuestions: Countables
      }) => ({ done, total }),
      cell: ({ done = 0, total = 0 }: Countables) => (
        <TasksDone done={done} available={total} progressBar showPercentage />
      ),
      header: LABEL_DONE_AVAILABLE,
      key: 'done_over_available_discursive_questions',
      sortable: false,
      visible,
      width: 150,
    },
  ],
  header: 'Questões Discursivas',
  key: 'discursive_questions',
  sortable: false,
  visible,
})

const objectiveQuestions = (visible: boolean) => ({
  align: 'center',
  columns: [
    {
      accessor: ({ objectiveQuestions: { done, total } }: { objectiveQuestions: Countables }) => ({
        done,
        total,
      }),
      cell: ({ done = 0, total = 0 }: Countables) => (
        <TasksDone done={done} available={total} progressBar showPercentage />
      ),
      header: LABEL_DONE_AVAILABLE,
      key: 'done_over_available_object_questions',
      sortable: false,
      visible,
      width: 180,
    },
    {
      accessor: ({
        objectiveQuestions: { correct, done },
      }: {
        objectiveQuestions: Countables
      }) => ({ correct, done }),
      cell: ({ correct = 0, done = 0 }: Countables) => (
        <PercentageComplete currentValue={correct} maxValue={done} threshold={50} />
      ),
      header: 'Acertos nas Feitas',
      key: 'correct_over_done_objective_questions',
      sortable: false,
      visible,
      width: 180,
    },
    {
      accessor: ({
        objectiveQuestions: { correct, total },
      }: {
        objectiveQuestions: Countables
      }) => ({
        correct,
        total,
      }),
      cell: ({ correct = 0, total = 0 }: Countables) => (
        <PercentageComplete currentValue={correct} maxValue={total} threshold={50} />
      ),
      header: 'Acertos nas Disponíveis',
      key: 'correct_over_available_objective_questions',
      sortable: false,
      visible,
      width: 180,
    },
  ],
  header: 'Questões Objetivas',
  key: 'objective_questions',
  sortable: false,
  visible,
})

const taskColumnMultipleChoiceAndOpenResponse = (visible: boolean) => ({
  columns: [
    {
      accessor: ({
        objectiveQuestions: { done: objectiveDone, total: objectiveTotal },
        discursiveQuestions: { done: discursiveDone, total: discursiveTotal },
      }: any) => ({ objectiveDone, discursiveDone, objectiveTotal, discursiveTotal }),
      cell: ({ objectiveDone, discursiveDone, objectiveTotal, discursiveTotal }: any) => (
        <TasksDone
          done={objectiveDone + discursiveDone}
          available={objectiveTotal + discursiveTotal}
          progressBar
          showPercentage
        />
      ),
      header: 'Total de Feitas / Disponíveis',
      key: 'done_over_available_object_and_discursive_questions',
      sortable: false,
      visible,
      width: 215,
    },
  ],
  header: 'Questões Objetivas e Discursivas',
  key: 'object_and_discursive_questions',
  sortable: false,
  visible,
})

const taskColumn = (taskType: TaskType, visible: boolean) => {
  switch (taskType) {
    case TaskType.MultipleChoice:
      return objectiveQuestions(visible)
    case TaskType.OpenResponse:
      return discursiveQuestions(visible)
    case TaskType.Video:
      return video(visible)
    case TaskType.Read:
      return readingActivities(visible)
    case TaskType.Essay:
      return essay(visible)
    default:
      throw new Error(`Unknown Task Type '${taskType}'`)
  }
}

export const getTableColumns = (taskTypes: TaskType[]) => [
  numbers,
  disciplines,
  taskColumnMultipleChoiceAndOpenResponse(
    taskTypes.includes(TaskType.MultipleChoice) && taskTypes.includes(TaskType.OpenResponse),
  ),
  taskColumn(TaskType.MultipleChoice, taskTypes.includes(TaskType.MultipleChoice)),
  taskColumn(TaskType.OpenResponse, taskTypes.includes(TaskType.OpenResponse)),
  taskColumn(TaskType.Video, taskTypes.includes(TaskType.Video)),
  taskColumn(TaskType.Read, taskTypes.includes(TaskType.Read)),
  taskColumn(TaskType.Essay, taskTypes.includes(TaskType.Essay)),
]
