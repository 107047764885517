import PlurallClient from '@plurall/client'
import { getOauthClient } from './oauth'
import { getToken } from './token'

class Client {
  constructor(config) {
    this.apiURL = process.env.REACT_APP_PLURALL_API_URL
    this.config = config
    this.token = getToken()
  }
  getPlurallClient = () => {
    const location = window.location.href
    const token = getToken()
    if (token !== this.token) {
      const OAuth = getOauthClient(location.pathname)
      window.location.href = OAuth.token.getUri()
      return null
    }

    const client = new PlurallClient({
      accept: this.config ? this.config.accept : 'vnd.plurall.api.v3+json',
      apiURL: process.env.REACT_APP_PLURALL_API_URL,
      pmVersion: this.config?.pmVersion || 3,
      graphqlURL: process.env.REACT_APP_SOMOS_GRAPHQL_URL,
      disableAutoCamelize: false,
      accessToken: token,
      applicationId: process.env.REACT_APP_PLURALL_ID_APPLICATION,
      apolloConfig: { fetchPolicy: 'no-cache' },
      onError: error => {
        if (error && error.response.status === 403) {
          const title = error.response.data.title // eslint-disable-line
          const message = error.response.data.msg || error.response.data.message
          window.location.href = `/error?title=${title}&message=${message}&status=403`
        }
      },
    })
    return client
  }
  getProfile = () => this.getPlurallClient().profile.get()
  getTasks = async (tasksIds, studentId = null) => {
    const tasks = await this.getPlurallClient()
      .restClient.get('/tasks', {
        params: {
          tasks: tasksIds,
          student_id: studentId,
        },
      })
      .catch(error => error.response?.data)

    if (tasks) {
      if (tasks.code) return []
      const { data } = tasks
      return data?.data || []
    }
    return []
  }
}

export default Client
