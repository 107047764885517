import PlurallClient from '@plurall/client'

import { getToken } from 'utils/token'

const { graphqlClient } = new PlurallClient({
  apiURL: process.env.REACT_APP_PLURALL_REPORTS_API,
  graphqlURL: process.env.REACT_APP_PLURALL_REPORTS_API,
  accessToken: getToken(),
})

export { graphqlClient }
