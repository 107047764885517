import React, { useEffect, useState } from 'react'
import { Alert } from 'plurall-ui'
import PropTypes from 'prop-types'
import EngagementClient from 'utils/restClient'
import { formatDate } from 'utils/formatDate'

const LastUpdateEngagement = ({ type }) => {
  const [engagementLastUpdated, setEngagementLastUpdated] = useState()

  useEffect(() => {
    const getDigitalClassesWidgets = async () => {
      const client = new EngagementClient()

      const lastUpdate =
        type === 'Aula Digital'
          ? await client.fetchDigitalClassesLastUpdate()
          : await client.fetchEngagementLastUpdate()

      setEngagementLastUpdated(lastUpdate)
    }

    getDigitalClassesWidgets()
  }, [type])

  return (
    <>
      {engagementLastUpdated && (
        <Alert name='Importante'>
          {`Relatórios atualizados até ${formatDate(engagementLastUpdated, {
            day: '2-digit',
            month: '2-digit',
          })}. Próxima atualização prevista para amanhã, às 13:00`}
        </Alert>
      )}
    </>
  )
}

LastUpdateEngagement.propTypes = {
  type: PropTypes.string,
}

export default LastUpdateEngagement
