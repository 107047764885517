import Cell from 'components/NewTable/Cell'
import React, { useState, FunctionComponent } from 'react'
import { Link, Text } from '@plurall/elo'
import QuestionDetails from '../QuestionDetails'

interface OpenQuestionDetailsModalProps {
  taskId: string
  name: string
  title: string
  subtitle: string
  filters?: any
  type?: string
  width?: number
}

const OpenQuestionDetailsModal: FunctionComponent<OpenQuestionDetailsModalProps> = ({
  taskId,
  name,
  title,
  subtitle,
  filters,
  type,
  width,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (event: MouseEvent) => {
    event.preventDefault()
    setOpen(true)
  }

  return (
    <>
      {type === 'read' || type === 'video' ? (
        <Cell width={width}>{name}</Cell>
      ) : (
        <Cell width={width}>
          <Link size='normal' secondary onClick={handleOpen} dataTestId={`question-${taskId}`}>
            <Text element='span' secondary>
              {name}
            </Text>
          </Link>
        </Cell>
      )}

      {isOpen && (
        <QuestionDetails
          taskId={taskId}
          title={title}
          subtitle={subtitle}
          filters={filters}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default OpenQuestionDetailsModal
