import React, { useState, useEffect } from 'react'
import { orderBy } from 'natural-orderby'
import { ReportType } from 'types/enums'
import { Card, CardStatus, NewTable as Table, Cell } from 'components'
import { getIds } from 'utils/itemBuilder'
import EngagementClient from 'utils/restClient'
import {
  exportReportXLSX,
  EXPORT_STATUS_MESSAGE,
  getFormattedDate,
  removeEloToast,
  showEloToast,
} from 'utils'
import OpenModalAttendeesList from './OpenModalAttendeesList'
import styles from './styles.module.css'
import { useExportLink } from 'hooks'

interface EngagementDigitalClassesTableProps {
  user: User
  filters: EngagementFilter
}

const client = new EngagementClient()

const headers = [
  { id: 'classDate', csv: 'eventDate', label: 'Data', sortable: true },
  { id: 'classTitle', label: 'Aula', sortable: true },
  { id: 'gradeName', label: 'Série', sortable: true },
  { id: 'className', label: 'Turma', sortable: true },
  { id: 'disciplineName', label: 'Disciplina', sortable: true },
  { id: 'teacherName', label: 'Organizador', sortable: true },
  { id: 'classBegin', csv: 'classBeginEndTime', label: 'Início e término', sortable: true },
  { id: 'totalClassSeconds', csv: 'classTimeDuration', label: 'Duração', sortable: true },
  { id: 'attendeesCount', label: 'Participantes', sortable: true },
]

const EngagementDigitalClassesTable = ({ user, filters }: EngagementDigitalClassesTableProps) => {
  const { renderExportLink } = useExportLink()

  const [loading, setLoading] = useState<boolean>(true)
  const [engagement, setEngagement] = useState<EngagementDigitalClasses[]>([])
  const [loadingExport, setLoadingExport] = useState<boolean>(false)

  useEffect(() => {
    const getEngagement = async () => {
      setLoading(true)
      const newEngagement = await fetchEngagementDigitalClass(user, filters)

      setEngagement(newEngagement)
      setLoading(false)
    }

    getEngagement()
  }, [user, filters])

  const handleSortChange = (id: string, desc: boolean) => {
    const order = desc ? 'desc' : 'asc'
    const sortedEngagement = orderBy(engagement, [id], [order])
    setEngagement(sortedEngagement)
  }

  const handleExportDigitalClasses = async (event: MouseEvent) => {
    event.preventDefault()
    window.PLURALL_TRACKER.track('digital-class-tab:export')

    setLoadingExport(true)

    const toastId = showEloToast(EXPORT_STATUS_MESSAGE.LOADING)
    try {
      const { request } = await client.fetchDigitalClassesExport(
        user.uid,
        filters.school.id,
        getIds(filters.months),
        getIds(filters.grades),
        getIds(filters.classes),
      )

      const options = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }
      const date = new Date()

      const months =
        Array.isArray(filters.months) && filters.months.length > 1
          ? `${filters.months.length} meses`
          : filters.months?.map(month => month.value)

      const grades =
        Array.isArray(filters.grades) && filters.grades.length > 1
          ? `${filters.grades.length} séries`
          : filters.grades?.map(grade => grade.value)

      const classes =
        Array.isArray(filters.classes) && filters.classes.length > 1
          ? `${filters.classes.length} turmas`
          : filters.classes?.map(klass => klass.value)

      const name = `Aulas_digitais-${!months?.length ? 'Todos os Meses' : months}-${grades ||
        'Todas as Séries'}-${classes || 'Todas as Turmas'}-${getFormattedDate(date, options)}`

      removeEloToast(toastId)
      showEloToast(EXPORT_STATUS_MESSAGE.SUCCESS, 'positive')

      exportReportXLSX(request?.response, name)
      setLoadingExport(false)
    } catch (error) {
      setLoadingExport(false)

      removeEloToast(toastId)
      showEloToast(EXPORT_STATUS_MESSAGE.ERROR, 'negative')
    }
  }

  const renderTable = () =>
    engagement.length ? (
      <>
        <div className={styles['button-export']}>
          {renderExportLink(
            loadingExport,
            handleExportDigitalClasses,
            'Exportar lista de aulas digitais',
            'Exportando lista de aulas digitais',
            'export',
          )}
        </div>

        {renderTableDigitalClasses(engagement, handleSortChange)}
      </>
    ) : (
      <Card className={styles.card}>
        <CardStatus
          status='not-found'
          message='Não há registro de aulas digitais para o período selecionado.'
        />
      </Card>
    )

  return loading ? (
    <Card className={styles.card}>
      <CardStatus />
    </Card>
  ) : (
    renderTable()
  )
}

const renderViewListAttendees = (index: number, classId: any) => (
  <OpenModalAttendeesList
    text='Ver'
    title='Lista de participantes'
    classId={classId}
    index={index}
  />
)

const renderTableDigitalClasses = (
  data: EngagementDigitalClasses[],
  handleSortChange: (id: string, desc: boolean) => void,
) => {
  return (
    <Card className={styles.card}>
      <Table
        type={ReportType.Engajamento}
        headers={headers}
        data={data}
        defaultSortBy='classDate'
        limit={50}
        onSortChange={handleSortChange}
      >
        {(
          {
            eventDate,
            attendeesCount,
            classTitle,
            gradeName,
            className,
            disciplineName,
            teacherName,
            classBeginEndTime,
            classTimeDuration,
            digitalClassId,
          }: any,
          index: number,
        ) => (
          <>
            <Cell>{eventDate}</Cell>
            <Cell>{classTitle}</Cell>
            <Cell>{gradeName}</Cell>
            <Cell>{className}</Cell>
            <Cell>{disciplineName}</Cell>
            <Cell>{teacherName}</Cell>
            <Cell>{classBeginEndTime}</Cell>
            <Cell>{classTimeDuration}</Cell>
            <Cell>
              <div className={styles.attendees}>
                {attendeesCount} {renderViewListAttendees(index, digitalClassId)}
              </div>
            </Cell>
          </>
        )}
      </Table>
    </Card>
  )
}

const fetchEngagementDigitalClass = async (user: User, filters: EngagementFilter) => {
  return await client.fetchDigitalClasses(
    user.uid,
    filters.school.id,
    getIds(filters.months),
    getIds(filters.grades),
    getIds(filters.classes),
  )
}

export default EngagementDigitalClassesTable
