import Cell from '../Cell'
import React, { FunctionComponent } from 'react'
import styles from './ProgressCell.module.css'
import { Icon, ProgressBar, Text } from 'plurall-ui'

interface ProgressCellProps {
  current: number
  hideProgress?: boolean
  invert?: boolean
  showIcon?: boolean
  total: number
  width?: number
}

const threshold = 50

const renderPercentage = (percentage: number, invalidValue: boolean = false) => (
  <Text secondary>{invalidValue ? '-' : `${percentage}%`}</Text>
)
const renderProgress = (
  progress: string,
  hideProgress: boolean = false,
  percentage?: number,
  secondary: boolean = false,
) =>
  hideProgress ? (
    <Text secondary={secondary}>{progress}</Text>
  ) : (
    <ProgressBar threshold={threshold} percentage={percentage}>
      {progress}
    </ProgressBar>
  )

const ProgressCell: FunctionComponent<ProgressCellProps> = ({
  current,
  hideProgress = false,
  invert,
  showIcon,
  total,
  width,
}) => {
  const percentage = total > 0 ? Math.floor((current / total) * 100) : 0
  const iconType = percentage >= threshold ? 'statsPositive' : 'statsNegative'
  const progress = `${current} de ${total}`

  const invalidValue = total === 0

  return (
    <Cell width={width}>
      <div className={styles.cell}>
        {showIcon && <Icon className={styles.icon} size='small' type={iconType} />}

        <div>
          {invert
            ? renderPercentage(percentage, invalidValue)
            : renderProgress(progress, hideProgress, percentage, false)}
        </div>

        <div className={styles.complement}>
          {invert
            ? renderProgress(progress, true, undefined, true)
            : renderPercentage(percentage, invalidValue)}
        </div>
      </div>
    </Cell>
  )
}

export default ProgressCell
