type IVariant = 'informative' | 'negative' | 'positive'

export const showEloToast = (message: string, variant: IVariant = 'informative') => {
  if (window.PLURALL_ELO_TOAST_CONTAINER?.addToast) {
    const toast = {
      text: message,
      autoClose: true,
      disableButtonClose: false,
      seconds: 6,
      variant,
    }

    const toastId: number = window.PLURALL_ELO_TOAST_CONTAINER.addToast(toast)

    return toastId
  }

  return undefined
}

export const removeEloToast = (toastId: number | undefined) => {
  window.PLURALL_ELO_TOAST_CONTAINER.removeToast(toastId)
}
