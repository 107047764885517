import Client from 'utils/restClient'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { convertFiltersToQueryVariables } from 'utils/convert'
import { eoTasksQuery, examTasksQuery, studentsTotalsQuery } from 'graphql/queries'
import { exportReportXLSX, getFormattedDate } from 'utils'
import { FilterContext } from 'utils/context'
import { FilterHeading, LastUpdate, Loading, TasksTable } from 'components'
import { getEOTableColumns } from 'components/EOTable/helpers'
import { graphqlClient } from 'utils/graphqlClient'
import { isReportEO } from 'utils/reportType'
import { orderBy } from 'natural-orderby'
import { TaskType } from 'types/enums'

const client = new Client()

interface QuestionsProps {
  contextFormat?: string[]
  filters: any
  allFilters: any
}

const limit = 50

const Tasks: FunctionComponent<QuestionsProps> = ({ contextFormat, filters }) => {
  const variables = convertFiltersToQueryVariables(filters)
  const [loadingExport, setLoadingExport] = useState<boolean>(false)
  const [idToastError, setIIdToastError] = useState<number>()
  const [questions, setQuestions] = useState([] as any)
  const [taskTypesFiltersCheckbox, setTaskTypesFilterscheckbox] = useState<
    TaskTypesFiltersCheckboxParams[]
  >([])
  const [taskTypesFilter, setTaskTypesFilter] = useState<TaskType[]>()
  const [loading, setLoading] = useState(true)
  const [loadingTasks, setLoadingTasks] = useState(false)
  const [error, setError] = useState(false)
  const [emptyQuestions, setEmptyQuestions] = useState(false)
  const [fetchMoreShow, setFetchMoreShow] = useState(false)
  const [offsetReset, setOffsetReset] = useState(false)
  const [hasAnnulledQuestions, setHasAnnulledQuestions] = useState(false)
  const [annulledCheckFilter, setAnnulledCheckFilter] = useState<number[]>([0])
  const [sort, setSort] = useState({
    desc: false,
    id: '',
  })

  contextFormat = variables?.contextFormat ? [variables.contextFormat] : contextFormat

  const getTheTotalNumberOfQuestions = async () => {
    try {
      setLoading(true)
      setAnnulledCheckFilter([0])
      const { loading: loadingStudentsTotals, data } = await graphqlClient.query({
        query: studentsTotalsQuery,
        variables: {
          ...variables,
          contextFormat,
          questionStatus: [0, 1],
        },
      })

      const tasksTypesOrder = getEOTableColumns(data?.me?.taskTypes, data?.me?.totals, filters)
        .filter(column => data?.me?.taskTypes.includes(column.key))
        .map(type => type.key)
      const tasksTypesFilters = handleFilterTaskType({
        tasksTypes: tasksTypesOrder,
        totals: data?.me?.totals,
      })
      setHasAnnulledQuestions(data?.me?.totals?.annuled > 0)
      setTaskTypesFilterscheckbox(tasksTypesFilters)
      setLoading(loadingStudentsTotals)
    } catch {
      setError(true)
    }
  }

  const taskTypesFilterWithoutAnnulled = taskTypesFilter?.filter(item => item !== TaskType.Annuled)

  const getTasks = async (offset?: number) => {
    try {
      setLoadingTasks(true)
      setEmptyQuestions(false)

      const { loading: graphqlLoading, data } = await graphqlClient.query({
        query: isReportEO(contextFormat) ? eoTasksQuery : examTasksQuery,
        variables: {
          ...variables,
          contextFormat,
          first: limit,
          offset: offset || 0,
          questionStatus: annulledCheckFilter,
          taskType: taskTypesFilterWithoutAnnulled,
        },
      })

      if (!!data.me?.questions?.length === false || data.me?.questions?.length === 0) {
        setEmptyQuestions(true)
      }

      if (data.me?.questions && offset) {
        setQuestions((prevState: any) => [...prevState, ...data.me.questions])
        setFetchMoreShow(data.me.questions?.length >= limit)
        setOffsetReset(false)
        // tslint:disable-next-line: no-unused-expression
        sort.id && handleSortChange(sort.id, sort.desc, [...questions, ...data.me.questions])
      } else {
        setQuestions(data.me?.questions || [])
        setFetchMoreShow(data.me?.questions?.length >= limit)
        setOffsetReset(true)
        // tslint:disable-next-line: no-unused-expression
        sort.id && handleSortChange(sort.id, sort.desc, data.me?.questions || [])
      }
      setLoadingTasks(graphqlLoading)
    } catch {
      setError(true)
    }
  }

  const handleFilterTaskType = ({
    tasksTypes,
    totals,
  }: {
    tasksTypes: string[]
    totals: any
  }): TaskTypesFiltersCheckboxParams[] => {
    const results = tasksTypes
      ?.map((type: string): TaskTypesFiltersCheckboxParams | undefined => {
        switch (type) {
          case 'multiple_choice':
            return {
              checked: false,
              id: 'onlyTasksMultipleChoice',
              key: [TaskType.MultipleChoice],
              label: `Questões Objetivas (${totals.objectiveQuestions})`,
              typeTooltip: `questões objetivas`,
            }
          case 'open_response':
            return {
              checked: false,
              id: 'onlyTasksOpenResponse',
              key: [TaskType.OpenResponse],
              label: `Questões Discursivas (${totals.discursiveQuestions})`,
              typeTooltip: `questões discursivas`,
            }
          case 'read':
            return {
              checked: false,
              id: 'onlyTasksRead',
              key: [TaskType.Read],
              label: `Questões de Leitura (${totals.readingActivities})`,
              typeTooltip: `questões de leitura`,
            }
          case 'video':
            return {
              checked: false,
              id: 'onlyTasksVideo',
              key: [TaskType.Video],
              label: `Questões de Vídeo (${totals.videos})`,
              typeTooltip: `questões de vídeo`,
            }
          case 'essay':
            return {
              checked: false,
              id: 'onlyEssay',
              key: [TaskType.Essay],
              label: `Questões de Redação (${totals.essay})`,
              typeTooltip: `questões de redação`,
            }
          case 'annuled':
            return {
              checked: false,
              id: 'onlyAnnulled',
              key: [TaskType.Annuled],
              label: `Questões Anuladas (${totals.annuled})`,
              typeTooltip: `questões anuladas`,
            }
        }
      })
      .filter((type: any) => {
        return typeof type !== 'undefined'
      })
      .map((type: any, index: number) => {
        if (index === 0) {
          setTaskTypesFilter(type.key)
          return {
            ...type,
            checked: true,
          }
        } else {
          return {
            ...type,
            checked: false,
          }
        }
      })
    if (results.length === 0) {
      setTaskTypesFilter(undefined)
    }
    return results
  }

  useEffect(() => {
    if (taskTypesFilter && taskTypesFilter.length > 0) {
      getTasks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTypesFilter])

  useEffect(() => {
    getTheTotalNumberOfQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleExportTasks = async (event: Event) => {
    if (idToastError) {
      window.PLURALL_ELO_TOAST_CONTAINER.removeToast(idToastError)
    }
    setLoadingExport(true)
    const idToast = window.PLURALL_ELO_TOAST_CONTAINER.addToast({
      autoClose: false,
      text: 'Exportando relatório, aguarde um momento.',
      variant: 'informative',
    })

    event.preventDefault()
    window.PLURALL_TRACKER.track('questions-tab:export')
    try {
      const params = {
        ...variables,
        contextFormat,
        first: limit,
        offset: 0,
        questionStatus: annulledCheckFilter,
        taskType: taskTypesFilterWithoutAnnulled,
      }
      const query = isReportEO(contextFormat)
        ? eoTasksQuery?.loc?.source.body
        : examTasksQuery?.loc?.source.body
      const operationName = 'questions'
      const { request } = await client.fetchReportExport(params, query, operationName)
      setLoadingExport(false)
      window.PLURALL_ELO_TOAST_CONTAINER.removeToast(idToast)
      window.PLURALL_ELO_TOAST_CONTAINER.addToast({
        autoClose: true,
        seconds: 10,
        text: 'Relatório de desempenho na turma por questão foi exportado.',
        variant: 'positive',
      })
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      }
      const date = new Date()
      const name = `Desempenho_Questão-${getFormattedDate(date, options)}`
      exportReportXLSX(request?.response, name)
    } catch (error) {
      setLoadingExport(false)
      window.PLURALL_ELO_TOAST_CONTAINER.removeToast(idToast)
      setIIdToastError(
        window.PLURALL_ELO_TOAST_CONTAINER.addToast({
          action: 'Tentar novamente',
          autoClose: false,
          onActionClick: () => handleExportTasks(event),
          text: 'Erro ao exportar relatório de desempenho na turma por questão.',
          variant: 'negative',
        }),
      )
    }
  }

  useEffect(() => {
    window.PLURALL_TRACKER.track('questions')
  }, [])

  const handleSortChange = (id: string, desc: boolean, moreQuestions?: any) => {
    setSort({ id, desc })
    const order = desc ? 'desc' : 'asc'
    const ids = id.split('-')
    let identifiers = (question: any) => question[id]
    if (ids.length === 2) {
      identifiers = (question: any) => question[ids[0]][ids[1]]
    } else if (ids.length === 3) {
      identifiers = (question: any) => question[ids[0]][ids[1]][ids[2]]
    }
    const sortedQuestions = orderBy(moreQuestions || questions, identifiers, [order])
    setQuestions(sortedQuestions)
  }

  if ((loading || loadingTasks) && error) {
    return <Loading />
  }

  return (
    <>
      <LastUpdate />
      <FilterHeading filters={filters} headingText='Desempenho da turma por questão' />

      <FilterContext.Provider value={filters}>
        <TasksTable
          questions={questions}
          contextFormat={contextFormat}
          gradeName={filters.grade[0].value}
          filters={filters}
          fetchMore={getTasks}
          fetchMoreShow={fetchMoreShow}
          limit={limit}
          loading={loading || loadingTasks}
          handleExportTasks={handleExportTasks}
          loadingExport={loadingExport}
          offsetReset={offsetReset}
          handleSortChange={handleSortChange}
          taskTypesFiltersCheckbox={taskTypesFiltersCheckbox}
          setTaskTypesFilter={setTaskTypesFilter}
          taskTypesFilter={taskTypesFilter}
          setTaskTypesFilterscheckbox={setTaskTypesFilterscheckbox}
          emptyQuestions={emptyQuestions}
          setAnnulledCheckFilter={setAnnulledCheckFilter}
          hasAnnulledQuestions={hasAnnulledQuestions}
        />
      </FilterContext.Provider>
    </>
  )
}

export default Tasks
