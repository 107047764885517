import React from 'react'
import styles from './styles.module.css';
import { EngagementRole } from 'types/enums';
import { Heading, Text } from '@plurall/elo';

interface EngagementFilterHeadingProps {
  title: string
  role: EngagementRole
  filters: EngagementFilter
}

const EngagementFilterHeading = ({ title, role, filters }: EngagementFilterHeadingProps) => {
  return (
    <div className={styles.heading}>
      <Heading size='normal'>{title}</Heading>
      <Text secondary>{renderText(role, filters)}</Text>
    </div>
  )
}

const renderText = (role: EngagementRole, filters: EngagementFilter) => {
  const filterGradesText = labelify(filters.grades, 'Séries')
  const filterClassesText = labelify(filters.classes, 'Turmas')

  switch (role) {
    case EngagementRole.Professor:
      const filterDisciplinesText = labelify(filters.disciplines, 'Disciplinas')
      return `${labelify(filters.month)} - ${filterDisciplinesText}`
    case EngagementRole.Aluno:
      return `${labelify(filters.month)} - ${filterGradesText} - ${filterClassesText}`
    case EngagementRole.DigitalClasses:
      return `${labelify(filters.months, 'Meses', 'Todos os')} - ${filterGradesText} - ${filterClassesText}`
    default:
      return '-'
  }
}

const labelify = (filter: Item | Item[] | undefined, label: string = '', labelPrefix = 'Todas as') => {
  if (filterNotSelected(filter)) return `${labelPrefix} ${label}`

  return Array.isArray(filter)
    ? (filter.length > 1 ? `${filter.length} ${label}` : filter[0].value)
    : filter && filter.value
}

const filterNotSelected = (filter: any) => {
  return !filter || (Array.isArray(filter) && !filter.length)
}

export default EngagementFilterHeading
