import { buildItem } from './itemBuilder'
import currentYear from './currentYear'
import dayChangeYear from './dayChangeYear'

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

const currentMonth = () =>
  dayChangeYear < new Date()
    ? new Date().getMonth() + 1
    : new Date(currentYear(), 11, 31).getMonth() + 1

const availableMonths = () => {
  const monthsOfYear =
    dayChangeYear < new Date()
      ? months
          .slice(0, currentMonth())
          .map((month, index) => ({ id: index + 1, name: month }))
          .map(buildItem)
      : months.map((month, index) => ({ id: index + 1, name: month })).map(buildItem)

  return monthsOfYear
}

const availableClasses = (school: School, grades: Item[] = []) => {
  const categorizedClasses: Item[] = []
  grades.forEach(grade => {
    const item = {
      ...grade,
      items: school.classes
        ? (school.classes as SchoolClass[])
            .filter(schoolClass => schoolClass.gradeId === grade.id)
            .map(buildItem)
        : [],
    } as Item
    categorizedClasses.push(item)
  })
  return categorizedClasses
}

const availableGrades = (school: School) =>
  school.grades ? (school.grades as SchoolGrade[]).map(buildItem) : []

const availableDisciplines = (school: School) =>
  school.disciplines ? school.disciplines.map(buildItem) : []

const humanizedMonthAndYear = (month: number) => {
  const year = currentYear()
  const monthName = months[month - 1]
  return `${monthName}/${year}`
}

export {
  currentYear,
  currentMonth,
  availableMonths,
  availableGrades,
  availableClasses,
  availableDisciplines,
  humanizedMonthAndYear,
}
