import { CustomTabs } from 'components'
import Modal from 'plurall-modal'
import { Text } from 'plurall-ui'
import React, { Component } from 'react'
import { FilterContext } from 'utils/context'
import { convertFiltersToQueryVariables } from 'utils/convert'
import Answers from './Answers'
import Details from './Details'
import styles from './TaskList.module.css'

interface TaskListProps {
  title: string
  subtitle: string
  onClose: () => void
  studentId: number
}

class TaskList extends Component<TaskListProps> {
  public static contextType = FilterContext

  public componentDidMount() {
    window.PLURALL_TRACKER.track('student_details')
  }

  public render() {
    const queryVariables = convertFiltersToQueryVariables(this.context.filters)
    const { title, subtitle, studentId, onClose } = this.props

    return (
      <Modal
        width='80%'
        overflow='hidden'
        padding='0 20px'
        height='calc(100% + 20px)'
        title={title}
        closed={onClose}
        maskOnClose={true}
      >
        <div className={styles.subtitle}>
          <Text size='big' secondary>
            {subtitle}
          </Text>
        </div>
        <CustomTabs
          className={styles.tabsWrapper}
          tabsClass={styles.tabsContainer}
          contentClass={styles.contentContainer}
          tabs={this.getTabs(queryVariables, studentId, title)}
        />
      </Modal>
    )
  }

  private getTabs = (queryVariables: any, studentId: number, title: string) => [
    {
      className: styles.contentItem,
      content: () => <Answers filters={queryVariables} studentId={studentId} />,
      key: 'respostas',
      label: 'Respostas',
    },
    {
      className: styles.contentItem,
      content: () => <Details filters={queryVariables} studentId={studentId} studentName={title} />,
      key: 'detalhes',
      label: 'Disciplinas',
    },
  ]
}

export default TaskList
