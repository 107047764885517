import { useContext } from 'react'
import { ReportContextFormat } from 'utils/context'
import { ReportType, SlugType } from 'types/enums'

const GetReportContextTrackerPrefix = () => {
  const contextFormat = useContext(ReportContextFormat)
  return contextFormat && contextFormat.trackerPrefix ? contextFormat.trackerPrefix : ''
}
const getReportTitle = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.Provas:
      return 'Relatórios - Provas'
    case ReportType.Olimpico:
      return 'Relatórios - Plurall Olímpico'
    case ReportType.Engajamento:
      return 'Relatórios - Engajamento'
    case ReportType.EstudoOrientado:
      return 'Relatórios - Estudo Orientado'
    case ReportType.StandardTest:
      return 'Relatórios - Standard Tests'
    default:
      return 'Relatórios - Estudo Orientado'
  }
}

const getReportBaseUrl = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.Provas:
      return `/${ReportType.Provas}`
    case ReportType.Olimpico:
      return `/${ReportType.Olimpico}`
    case ReportType.Engajamento:
      return `/${ReportType.Engajamento}`
    case ReportType.EstudoOrientado:
      return `/${ReportType.EstudoOrientado}`
    case ReportType.StandardTest:
      return `/${ReportType.StandardTest}`
    default:
      return `/${ReportType.EstudoOrientado}`
  }
}

const getReportTypeFromPath = (path: string) => {
  if (path.includes(`/${ReportType.Provas}`)) {
    return ReportType.Provas
  } else if (path.includes(`/${ReportType.Engajamento}`)) {
    return ReportType.Engajamento
  } else if (path.includes(`/${ReportType.Olimpico}`)) {
    return ReportType.Olimpico
  } else if (path.includes(`/${ReportType.StandardTest}`)) {
    return ReportType.StandardTest
  }
  return ReportType.EstudoOrientado
}

const getMenuSubItem = (path: string) => {
  if (path.includes(ReportType.EstudoOrientado)) {
    return SlugType.EstudoOrientado
  } else if (path.includes(ReportType.Olimpico)) {
    return SlugType.Olimpico
  } else if (path.includes(ReportType.StandardTest)) {
    return SlugType.StandardTest
  }
  return SlugType.EstudoOrientado
}

const contextFormatProva = 'PROVA'
const contextFormatOlimpico = 'OLIMPICO'
const contextFormatStandardTests = 'STANDARD_TEST'
const contextEoNotDoubts = [
  'AMPLIE_O_PERCURSO_TEST',
  'PITAGORAS_TEST',
  'MAXI_TEST',
  'ELEVA_TEST',
  'ETICO_TEST',
  'ETICO',
  'EM_TEMPO',
  contextFormatProva,
  contextFormatOlimpico,
  contextFormatStandardTests,
]

const getReportContextFormat = (reportType: ReportType) => {
  if (reportType === ReportType.Provas) {
    return [contextFormatProva]
  } else if (reportType === ReportType.Olimpico) {
    return [contextFormatOlimpico]
  } else if (reportType === ReportType.StandardTest) {
    return [contextFormatStandardTests]
  }
  return []
}

const formatADAE = 'Aula dada, aula estudada'
const formatAmpliePercursoTest = 'Amplie o percurso'
const formatElevaTest = 'Estudo Orientado Eleva'
const formatEmTempo = 'Em Tempo'
const formatEtico = 'Estudo Orientado Ético'
const formatFibonacciTest = 'Estudo Orientado Fibonacci'
const formatHAC = 'HAC'
const formatMaxiTest = 'Estudo Orientado Maxi'
const formatParTest = 'Estudo Orientado Par'
const formatPH = 'pHs'
const formatPitagorasTest = 'Estudo Orientado Pitágoras'
const formatTcOnline = 'TC online'
const formatOlimpico = 'Plurall Olímpico'
const formatStandardTest = 'Standard Tests'
const formatPreparatorioEnem = 'Preparatório Enem'
const formatAtividadesEnem = 'Atividades Enem'
const formatConecteLive = 'Conecte Live'
const formatDesafioDigital = 'Desafio digital'
const formatRotaAprofundamento = 'Rota de Aprofundamento'
const formatEstudoOrientadoSomos = 'Estudo Orientado Somos'
const formatTrilhasRPP = 'Trilhas de RPP'
const formatRevistaEnem = 'Revisa Enem'
const formatTop5 = 'TOP 5'
const formatEticoTest = 'Estudo Orientado Ético'

const getEOFormat = (format: any) => {
  switch (format) {
    case 'ADAE':
      return formatADAE

    case 'AMPLIE_O_PERCURSO_TEST':
      return formatAmpliePercursoTest

    case 'ELEVA_TEST':
      return formatElevaTest

    case 'EM_TEMPO':
      return formatEmTempo

    case 'FIBONACCI_TEST':
      return formatFibonacciTest

    case 'HAC':
      return formatHAC

    case 'MAXI_TEST':
      return formatMaxiTest

    case 'PAR_TEST':
      return formatParTest

    case 'PH':
      return formatPH

    case 'PITAGORAS_TEST':
      return formatPitagorasTest

    case 'TCONLINE':
      return formatTcOnline

    case 'ETICO':
      return formatEtico

      case 'ETICO_TEST':
      return formatEticoTest

    case 'OLIMPICO':
      return formatOlimpico

    case 'STANDARD_TEST':
      return formatStandardTest

    case 'PREPARATORIO_ENEM':
      return formatPreparatorioEnem

    case 'ATIIDADES_ENEM':
      return formatAtividadesEnem

    case 'CONECTE_LIVE':
      return formatConecteLive

    case 'DESAFIO_DIGITAL':
      return formatDesafioDigital

    case 'ROTA_DE_APROFUNDAMENTO':
      return formatRotaAprofundamento

    case 'ESTUDO_ORIENTADO_SOMOS':
      return formatEstudoOrientadoSomos

    case 'TRILHAS_RPP':
      return formatTrilhasRPP

    case 'REVISA_ENEM':
        return formatRevistaEnem

    case 'TOP_5':
        return formatTop5

    default:
      return ''
  }
}


const isReportEO = (contextFormat?: string[]): boolean => {
  const [item] = contextFormat || []

  return !contextEoNotDoubts.includes(item)
}

const isReportExam = (contextFormat?: string[]): boolean =>
  !!contextFormat?.length && contextFormat.includes(contextFormatProva)

const isReportOlimpico = (contextFormat?: string[]): boolean =>
  !!contextFormat?.length && contextFormat.includes(contextFormatOlimpico)

const isReportStandardTests = (contextFormat?: string[]): boolean =>
  !!contextFormat?.length && contextFormat.includes(contextFormatStandardTests)

const reportContexts = () => ({
  eng: {
    id: 'reng',
    label: 'Engajamento',
    name: 'engajamento',
    trackerPrefix: 'eng',
    pageTitle: 'Relatório de Engajamento',
  },
  eo: {
    id: 'eo',
    label: 'Estudo Orientado',
    name: 'estudo-orientado',
    trackerPrefix: 'eo',
    pageTitle: 'Relatório de Estudo Orientado',
  },
  olimpico: {
    id: 'olimpico',
    label: 'Olímpico',
    name: 'olimpico',
    trackerPrefix: 'olimpico',
    pageTitle: 'Relatórios - Plurall Olímpico',
  },
  provas: {
    id: 'prova',
    label: 'Prova',
    name: 'prova',
    trackerPrefix: 'prova',
    pageTitle: 'Relatório de Provas',
  },
  standard_test: {
    id: 'standard_test',
    label: 'Standard Tests',
    name: 'standard_test',
    pageTitle: 'Relatório Standard Tests',
    trackerPrefix: 'standard_test',
  },
})

export {
  getReportTitle,
  getReportBaseUrl,
  getReportTypeFromPath,
  getMenuSubItem,
  GetReportContextTrackerPrefix,
  getReportContextFormat,
  getEOFormat,
  reportContexts,
  isReportEO,
  isReportExam,
  isReportOlimpico,
  isReportStandardTests,
}
