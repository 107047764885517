import React, { useState } from 'react'
// @ts-ignore
import { Heading, InputDropdown } from '@plurall/elo-beta'
import FilterBar from './FilterBar'
import Students from './Students'
import styles from './Report.module.css'
import Tasks from './Tasks'
import { ContextFormat } from 'utils/context'
import { Route, RouteComponentProps } from 'react-router'
import { PageContainer, StartSearchCard, Tabs } from 'components'
import { useSelectedSchoolContext } from 'contexts'
import {
  getReportContextFormat,
  getReportTitle,
  getReportBaseUrl,
  getReportTypeFromPath,
} from 'utils/reportType'

interface Params {
  id: string
}
interface FormattedSchools {
  label: string
  id: string | number
  checked: boolean
}

const Report = ({ match }: RouteComponentProps<Params>) => {
  const maxTotalSchools = 5

  const [filters, setFilters] = useState({} as any)
  const [allFilters, setAllFilters] = useState({} as any)
  const [error, setError] = useState(false)
  const reportType = getReportTypeFromPath(match.path)

  const {
    formattedSchools,
    handleSchoolChange,
    renderSchoolSelection,
    selectedSchool,
    showSchoolSelection,
    schools,
  } = useSelectedSchoolContext()

  const contextFormat = getReportContextFormat(reportType)

  if (showSchoolSelection && schools.length > 1) {
    return renderSchoolSelection()
  }

  const title = getReportTitle(reportType)
  const baseUrl = getReportBaseUrl(reportType)
  const { grade, klasses } = filters
  const shouldRenderResults = grade && klasses
  const getPageContainer = (hasStudents: boolean) => (
    <PageContainer className={styles.container}>
      {shouldRenderResults ? (
        hasStudents ? (
          <Students contextFormat={contextFormat} filters={filters} allFilters={allFilters} />
        ) : (
          <Tasks contextFormat={contextFormat} filters={filters} allFilters={allFilters} />
        )
      ) : (
        <StartSearchCard title='Acompanhe o desempenho nas questões por aluno' />
      )}
    </PageContainer>
  )

  return (
    <ContextFormat.Provider value={contextFormat}>
      <div className={styles.rowTop}>
        <div className={styles.heading}>
          <Heading noParagraph>{title}</Heading>
          <Heading noParagraph element='h6' shy='true'>
            {' '}
            {selectedSchool.name}
          </Heading>
        </div>
        <div className={styles.dropdown}>
          <InputDropdown
            label='Alterar escola'
            placeholder='Selecionar'
            dataTestId='school-select'
            items={formattedSchools.map(item => {
              return { ...item, checked: false }
            })}
            searchable={formattedSchools.length > maxTotalSchools}
            scrollbarMaxHeight
            scrollbarWrapperHeight='250'
            disabled={false}
            onChange={(school: FormattedSchools) => handleSchoolChange(school)}
          />
        </div>
      </div>

      <FilterBar
        contextFormat={contextFormat}
        onSubmit={setFilters}
        onLoadFilters={setAllFilters}
        hasError={setError}
      />

      {!error && (
        <div>
          <Tabs className={styles.sticky} links={buildLinks(baseUrl, selectedSchool.id)} />
          <Route path={`${baseUrl}/escolas/:id`} exact render={() => getPageContainer(true)} />
          <Route
            path={`${baseUrl}/escolas/:id/por-tarefa`}
            exact
            render={() => getPageContainer(false)}
          />
        </div>
      )}
    </ContextFormat.Provider>
  )
}

const buildLinks = (baseUrl: string, schoolId: string | number) => [
  {
    exact: true,
    label: 'Alunos',
    location: `${baseUrl}/escolas/${schoolId}`,
  },
  {
    exact: true,
    label: 'Questões',
    location: `${baseUrl}/escolas/${schoolId}/por-tarefa`,
  },
]

export default Report
