import { dateDiffToHuman, formatDate, formatTime } from './formatDate'

const examTimeText = (exams: Exam[]) => {
  let formattedExamTime = '--'
  if (exams && exams.length > 0) {
    const dateStarted = new Date(exams[0].dateAnswerFirstQuestion)
    const dateFinished = new Date(exams[0].dateAnswerLastQuestion)
    formattedExamTime = (dateStarted && dateFinished) && dateDiffToHuman(dateStarted, dateFinished)
  }
  return formattedExamTime
}

const examFinishedAtToHumanText = (exams: Exam[]) => {
  if (exams && exams.length > 0 && exams[0].finishedAt) {
    const dateOptions = { month: 'numeric', day: 'numeric' }
    return `${formatDate(exams[0].finishedAt, dateOptions)} às ${formatTime(exams[0].finishedAt)}`
  }
  return ''
}

const wasTheExamDelivered = (exams: Exam[]) => Boolean(exams && exams.length > 0 && exams[0].finishedAt)

const wasDeliveredOnFilteredEndDate = (exams: Exam[], filteredEndDate: Date) => {
  if (exams && exams.length > 0 && exams[0].finishedAt) {
    const finishedAt = new Date(exams[0].finishedAt)
    return Boolean(finishedAt <= filteredEndDate)
  }
  return false
}

export { examFinishedAtToHumanText, examTimeText, wasDeliveredOnFilteredEndDate, wasTheExamDelivered}
