import { FormatFilters } from 'types/enums'

interface Field {
  query?: any
  order?: number
  multiSelect?: boolean
  queryParamName: string
  queryType?: 'search'
}

const fieldMap: { [key: string]: Field } = {
  disciplines: {
    multiSelect: true,
    order: 1,
    query: () => `
      disciplines {
        id
        disciplineId
        sectorId
        value: name
        notebookIds
      }
    `,
    queryParamName: 'disciplineIds',
  },

  endDate: {
    order: 6,
    queryParamName: 'answerDateEnd',
    queryType: 'search',
  },

  exams: {
    multiSelect: true,
    order: 4,
    query: () => `
      exams {
        id
        value: name
        close_at
        disciplineIds
      }
    `,
    queryParamName: 'nodeIds',
  },

  standardTests: {
    multiSelect: true,
    order: 4,
    query: () => `
      standardTests {
        id
        value: name
        close_at
      }
    `,
    queryParamName: 'nodeIds',
  },

  olimpicoLists: {
    multiSelect: true,
    order: 4,
    query: () => `
      olimpicoLists {
        id
        value: name
        close_at
        disciplineIds
      }
    `,
    queryParamName: 'nodeIds',
  },

  emTempoLists: {
    multiSelect: true,
    order: 4,
    query: () => `
      emTempoLists {
        id: name
        disciplineIds
        testIds
        value: name
      }
    `,
    queryParamName: 'testTitles',
  },

  grade: {
    multiSelect: true,
    queryParamName: 'gradeIds',
  },

  klasses: {
    multiSelect: true,
    queryParamName: 'classIds',
  },

  eoFormat: {
    queryParamName: 'contextFormat',
  },

  lessons: {
    multiSelect: true,
    order: 4,
    query: () => `
      lessons {
        id: lessonId
        value: fullName
        disciplineIds
        disciplines {
          disciplineId
          notebookId
          disciplineName
          nodeId
          sectorId
          testTypeId
        }
      }
    `,
    queryParamName: 'activities',
  },

  lists: {
    multiSelect: true,
    order: 4,
    query: () => `
      lists {
        id: name
        disciplineIds
        testIds
        value: name
      }
    `,
    queryParamName: 'testTitles',
  },

  activities: {
    multiSelect: true,
    order: 4,
    query: () => `
      activities {
        id: name
        disciplineIds
        value: name
        testIds
      }
    `,
    queryParamName: 'activities',
  },

  materials: {
    multiSelect: true,
    order: 0,
    queryParamName: 'materialIds',
  },

  notebooks: {
    multiSelect: true,
    order: 2,
    query: () => `
      notebooks {
        id
        materialIds
        value: name
        disciplineIds
      }
    `,
    queryParamName: 'notebookIds',
  },

  phs: {
    multiSelect: true,
    order: 4,
    query: () => `
      phs {
        id
        value: fullName
        close_at
        disciplineIds
      }
    `,
    queryParamName: 'testTitles',
  },

  startDate: {
    order: 5,
    queryParamName: 'answerDateStart',
    queryType: 'search',
  },

  testTypes: {
    multiSelect: true,
    order: 3,
    query: (format: FormatFilters) => `
      testTypes {
        id: name
        value: name
        ${format === FormatFilters.ADAE ? 'lessons' : 'testIds'}
        ${format === FormatFilters.ADAE ? 'notebooks' : ''}
      }
    `,
    queryParamName: 'testTypes',
  },
}

const buildQueryEoFiltersFromFields = (format: FormatFilters, fields: string[]) => {
  const field = (name: string) => {
    const defaultFormat = `
      ${name} {
        id
        value: name
      }
    `
    const customFormat = fieldMap[name]
    if (customFormat && customFormat.query) {
      return customFormat.query(format)
    }

    return defaultFormat
  }

  return `
    query eoFilters($schoolId: ID!, $gradeIds: [ID!], $classIds: [ID!], $contextFormat: [ContextFormat]) {
      me {
        eoFilters(schoolId: $schoolId, gradeIds: $gradeIds, classIds: $classIds, contextFormat: $contextFormat) {
          ... on ${format} {
            ${fields.map(field).join('')}
          }
        }
      }
    }
  `
}

const queryEoFiltersPossibleTypes = `
    query __type {
      __type(name: "EoFilters") {
        name
        kind
        possibleTypes {
          fields {
            name
          }
          name
        }
      }
    }
  `

const sortByFieldOrder = (a: string, b: string): number => {
  const first = fieldMap[a].order!
  const second = fieldMap[b].order!
  return first - second
}

const getFieldFromMap = (field: string): any => {
  return fieldMap[field]
}

export {
  buildQueryEoFiltersFromFields,
  queryEoFiltersPossibleTypes,
  getFieldFromMap,
  fieldMap,
  sortByFieldOrder,
}
