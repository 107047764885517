import { Link, Text, Tooltip } from '@plurall/elo'
import { Loading, NotFoundCard, ThreeDotsCircle } from 'components'
import { userTasksQuery } from 'graphql/queries'
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { TaskTypeDiscipline } from 'types/enums'
import SomosClient from 'utils/client'
import { ContextFormat, FilterContext } from 'utils/context'
import defaultSort from 'utils/defaultSort'
import { graphqlClient } from 'utils/graphqlClient'
import Stats from '../Stats'
import Task from '../Task'
import styles from './Answers.module.css'
import Heading from './Heading'
import { hasTaskMultipleTries, taskFullTitle, taskTitle } from './helpers'

interface AnswerProps {
  filters: any
  studentId: number
}
interface CheckboxFilters {
  key: keyof typeof TaskTypeDiscipline
  visible: boolean
}

interface AnswerStates {
  setCurrentPage: Dispatch<SetStateAction<number>>
  setExpandedAnswers: Dispatch<SetStateAction<string[]>>
  setLoading: Dispatch<SetStateAction<boolean>>
}

interface UserList {
  tasks: Task[]
  stats: UserStats
}

const perPage = 10

const Answers: FunctionComponent<AnswerProps> = ({ filters, studentId }) => {
  const context = useContext(ContextFormat)
  const contextFormat = context && context.length > 0 ? context : [filters.contextFormat]
  const { selectedCheckboxFilters } = useContext(FilterContext)
  const [data, setData] = useState<UserList>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [expandedAnswers, setExpandedAnswers] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [tasks, setTasks] = useState<Task[]>([])
  const [error, setError] = useState(false)

  const handleTaskTypesFilter = () => {
    return selectedCheckboxFilters
      .filter((checkbox: CheckboxFilters) => checkbox.visible)
      .map((checkbox: CheckboxFilters) => checkbox.key)
  }

  const { current: my } = useRef({
    lastScrollY: null,
  } as {
    // tslint:disable-next-line: trailing-comma
    lastScrollY: null | number
  })

  const getUserTasks = async () => {
    try {
      const { data: dataUserTasks } = await graphqlClient.query({
        query: userTasksQuery,
        variables: {
          ...filters,
          contextFormat,
          taskType: handleTaskTypesFilter(),
          userId: studentId,
        },
      })

      if (!data && !!dataUserTasks.me) {
        setData(sortTasks(dataUserTasks.me.userTasks, setLoading))
      }
    } catch {
      setError(true)
    }
  }

  useEffect(() => {
    getUserTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data && data.tasks && data.tasks.length > 0) {
      fetchExtraInfo(
        studentId,
        data.tasks,
        getCurrentTasks(data.tasks, currentPage),
        setLoading,
        setTasks,
      )
    }
  }, [currentPage, data, studentId])

  useEffect(() => {
    const modalScroll = document.getElementById('answerTabContent')
    if (my.lastScrollY) {
      modalScroll?.scroll(0, my.lastScrollY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, loading])

  const handleLoadMoreClick = (event: MouseEvent) => {
    event.preventDefault()

    window.PLURALL_TRACKER.track('modal_get_more_questions', {
      modal_total_pagination: Math.ceil(tasks.length / perPage),
    })

    const modalScroll = document.getElementById('answerTabContent')
    my.lastScrollY = modalScroll && modalScroll.scrollHeight - 240 * 3

    setLoading(true)
    setCurrentPage(currentPage + 1)
  }

  return (
    <div id='answerTabContent' className={styles.answerTabContent}>
      {error || (loading && <Loading />)}

      {!loading &&
        !!data &&
        (tasks.length > 0 ? (
          renderAnswers(
            data.stats,
            tasks,
            data.tasks.length,
            currentPage,
            expandedAnswers,
            handleLoadMoreClick,
            {
              setCurrentPage,
              setExpandedAnswers,
              setLoading,
            },
          )
        ) : (
          <NotFoundCard
            title='Nenhuma questão foi feita por este aluno.'
            className={styles.notFoundCard}
          />
        ))}
    </div>
  )
}

const renderAnswers = (
  stats: UserStats,
  tasks: Task[],
  totalTasks: number,
  currentPage: number,
  expandedAnswers: string[],
  handleLoadMoreClick: (event: MouseEvent) => void,
  states: AnswerStates,
) => {
  const { setExpandedAnswers } = states
  const { attempts, totals } = stats

  const currentTasks = getCurrentTasks(tasks, currentPage)
  const showLoadMoreQuestions = Math.ceil(totalTasks / perPage) > currentPage

  const hasDiscursiveQuestions = totals.discursiveQuestions > 0
  const hasMultipleTries = hasTaskMultipleTries(tasks)

  return (
    <>
      <Stats attempts={attempts} totals={totals} hasMultipleTries={hasMultipleTries} />

      <div className={styles.answerContent}>
        <Heading
          tasks={tasks}
          hasDiscursiveQuestions={hasDiscursiveQuestions}
          hasMultipleTries={hasMultipleTries}
          expandedAnswers={expandedAnswers}
          setExpandedAnswers={setExpandedAnswers}
        />

        {currentTasks.map(renderTask(expandedAnswers, setExpandedAnswers))}

        {showLoadMoreQuestions && (
          <Link
            className={styles.more}
            secondary
            onClick={(event: MouseEvent) => handleLoadMoreClick(event)}
            dataTestId='more_questions'
          >
            Carregar mais {perPage} resultados
          </Link>
        )}
      </div>
    </>
  )
}

const getCurrentTasks = (tasks: Task[], currentPage: number) => [
  ...tasks.slice(0, currentPage * perPage),
]

const sortTasks = (dataUserTasks: any, setLoading: Dispatch<SetStateAction<boolean>>) => {
  if (!dataUserTasks.tasks || dataUserTasks.tasks.length === 0) {
    setLoading(false)
    return dataUserTasks
  }

  dataUserTasks.tasks.forEach((task: Task) => {
    task.fullTitle = taskFullTitle(task)
    task.taskTitleComplements = taskTitle(task)
  })
  dataUserTasks.tasks.sort(defaultSort('fullTitle'))
  return dataUserTasks
}

const renderTask = (
  expandedAnswers: string[],
  setExpandedAnswers: Dispatch<SetStateAction<string[]>>,
) => ({
  answers,
  subItems,
  fullTitle,
  options,
  resultStatus,
  taskId,
  taskTitleComplements,
  taskType,
  taskWorkflowContext,
  text,
  officialAnswer,
}: Task) => (
  <div key={taskId} className={styles.task}>
    <div className={styles.title}>
      <Text bold>{fullTitle}</Text>
      {taskTitleComplements && taskTitleComplements.length > 0 && (
        <Tooltip content={taskTitleComplements} position='top'>
          <ThreeDotsCircle />
        </Tooltip>
      )}
    </div>
    <Task
      answers={answers}
      subItems={subItems}
      expanded={expandedAnswers.includes(taskId)}
      id={taskId}
      key={taskId}
      onAnswerToggle={handleToggleAnswer(expandedAnswers, setExpandedAnswers)}
      options={options}
      resultStatus={resultStatus}
      taskWorkflowContext={taskWorkflowContext}
      text={text}
      officialAnswer={officialAnswer}
      type={taskType}
    />
  </div>
)

const handleToggleAnswer = (
  currentExpandedAnswers: string[],
  setExpandedAnswers: Dispatch<SetStateAction<string[]>>,
) => (id: string) => {
  const answerIsExpanded = currentExpandedAnswers.includes(id)

  const newExpandedAnswers = [...currentExpandedAnswers]
  if (answerIsExpanded) {
    const index = newExpandedAnswers.indexOf(id)
    newExpandedAnswers.splice(index, 1)
  } else {
    newExpandedAnswers.push(id)
  }

  setExpandedAnswers(newExpandedAnswers)
}

const fetchExtraInfo = async (
  studentId: number,
  tasks: Task[],
  tasksToRender: Task[],
  setLoading: Dispatch<SetStateAction<boolean>>,
  setTasks: Dispatch<SetStateAction<Task[]>>,
) => {
  const v3Client = new SomosClient()
  const incompleteTasks = tasksToRender.filter(task => !task.answers)

  if (tasks.length <= 0) {
    return
  }

  if (incompleteTasks.length <= 0) {
    setTasks(tasksToRender)
    setLoading(false)
    return
  }

  const fetchedTasks: Task[] = await v3Client.getTasks(
    incompleteTasks.map(task => task.taskId),
    studentId as any,
  )

  const newTasks = [...tasks]
  fetchedTasks.forEach(fetchedTask => {
    const task = newTasks.find(t => t.taskId === fetchedTask.taskId)
    if (!task) return
    task.text = fetchedTask.text
    task.subItems = fetchedTask.subItems
    task.answers = fetchedTask.answers
    task.options = fetchedTask.options
    task.officialAnswer = fetchedTask.officialAnswer
  })

  setTasks(newTasks)
  setLoading(false)
}

export default Answers
