import React, { useState, useEffect } from 'react'
import Modal from 'plurall-modal'
import { orderBy } from 'natural-orderby'
import { Text, Button } from '@plurall/elo'
import { Badge } from '@plurall/elo-beta'
import { NewTable as Table, Cell, CardStatus } from 'components'
import EngagementClient from 'utils/restClient'
import { ReportType } from 'types/enums'
import { exportReportXLSX, EXPORT_STATUS_MESSAGE, getFormattedDate } from 'utils'
import { useToastInModal } from 'hooks/useToastInModal'

import styles from './AttendeesList.module.css'

interface AttendessListProps {
  title: string
  onClose: () => void
  classId: any
}

const client = new EngagementClient()

const headers = [
  { id: 'attendeeCount', label: 'Nº', sortable: true },
  { id: 'attendeeName', label: 'Participante', sortable: true },
  { id: 'attendeeRoles', label: 'Perfil' },
  { id: 'sessionTimes', label: 'Entrada - Saída', sortable: true },
  { id: 'totalTime', label: 'Tempo total em aula', sortable: true },
]

const AttendessList = ({ title, onClose, classId }: AttendessListProps) => {
  const { setToast, renderToast } = useToastInModal()

  const [loading, setLoading] = useState<boolean>(true)
  const [exportLoading, setExportLoading] = useState(false)
  const [attendeesList, setAttendeesList] = useState<Attendees[]>([])
  const [digitalClass, setDigitalClass] = useState<DigitalClassesAttendees>()

  useEffect(() => {
    const getAttendeesList = async () => {
      setLoading(true)
      const [digitalClass] = await client.fetchAttendeesDigitalClasses(classId)
      const attendees = digitalClass?.attendances

      setDigitalClass(digitalClass)
      setAttendeesList(attendees)
      setLoading(false)
    }

    getAttendeesList()
  }, [classId])

  const handleSortChange = (id: string, desc: boolean) => {
    const order = desc ? 'desc' : 'asc'
    const sortedAttendees = orderBy(attendeesList, [id], [order])
    setAttendeesList(sortedAttendees)
  }

  const handleExportAttendeesList = async () => {
    window.PLURALL_TRACKER.track('digital-class-tab:modal-class-export')

    setToast({ show: true, message: EXPORT_STATUS_MESSAGE.LOADING, variant: 'informative' })
    try {
      setExportLoading(true)
      const { request } = await client.fetchEngagementAttendeesListExport(classId)

      const options = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }
      const date = new Date()

      const name = `${digitalClass?.title}-${getFormattedDate(date, options)}`

      setToast({ show: true, message: EXPORT_STATUS_MESSAGE.SUCCESS, variant: 'positive' })

      exportReportXLSX(request?.response, name)
    } catch (error) {
      setToast({ show: true, message: EXPORT_STATUS_MESSAGE.ERROR, variant: 'negative' })
    } finally {
      setExportLoading(false)
    }
  }

  const renderBadge = (index: number, roleName: string) => {
    const variant = roleName !== 'Convidado' && 'positive'
    const value = roleName

    return (
      <div>
        <Badge variant={variant} dataTestId={`status-${value}-${index + 1}`}>
          {value}
        </Badge>
      </div>
    )
  }

  const renderTable = () => (
    <div className={styles.table}>
      <Table
        type={ReportType.Engajamento}
        headers={headers}
        data={attendeesList}
        defaultSortBy='attendeeName'
        limit={50}
        modal={true}
        onSortChange={handleSortChange}
      >
        {({ attendeeCount, attendeeName, attendeeRoles, totalTimeDuration, sessionTimes }: any) => (
          <>
            <Cell>{attendeeCount}</Cell>
            <Cell>{attendeeName}</Cell>

            <Cell>
              <div className={styles['cell-grid']}>
                {attendeeRoles.map((role: string, index: number) => renderBadge(index, role))}
              </div>
            </Cell>
            <Cell>
              <div className={styles['cell-grid']}>
                {sessionTimes?.map((time: string) => (
                  <Text>{time}</Text>
                ))}
              </div>
            </Cell>
            <Cell>{totalTimeDuration}</Cell>
          </>
        )}
      </Table>
    </div>
  )

  const digitalClassInfo = `${digitalClass?.classDate}
  - ${digitalClass?.gradeName}
  - ${digitalClass?.className}
  - ${digitalClass?.disciplineName}
  - ${digitalClass?.organizerTeacher}
  - ${digitalClass?.classBeginEndTime}
  - ${digitalClass?.classTimeDuration}
  - ${digitalClass?.attendeesCount} participante${digitalClass?.attendeesCount! > 1 ? 's' : ''}`

  return (
    <Modal
      width='55vw'
      overflow='hidden'
      padding='0 20px'
      height='calc(100% + 20px)'
      title={title}
      closed={onClose}
      maskOnClose={true}
    >
      {renderToast()}
      {loading ? (
        <CardStatus />
      ) : (
        <>
          <div className={styles.modal}>
            <div className={styles.header}>
              <div className={styles.subtitle}>{digitalClass?.title}</div>
              <div className={styles.infos}></div>
              <Text size='small' secondary>
                {digitalClassInfo}
              </Text>
            </div>
            <>{renderTable()}</>
          </div>
          <div className={styles.export}>
            <div className={styles.button}>
              <Button
                onClick={handleExportAttendeesList}
                data-test-id={'button-export-attendees-list'}
                disabled={!attendeesList?.length || exportLoading}
              >
                Exportar
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

export default AttendessList
