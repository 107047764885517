import React from 'react'
import PropTypes from 'prop-types'
import { Icon, SearchBox, Text, Checkbox, Tooltip } from '@plurall/elo'
import slugify from 'slugify'
import { Card, Table, ExportCSV } from 'components'
import { formatDateTime, formatDate } from 'utils/formatDate'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { FilterFields } from 'types/enums'
import styles from './styles.module.css'
import {
  getEOTableColumns,
  didAllTasks,
  didAnyTask,
  didNoTask,
  filterColumnsEOTable,
  filterNames,
  fileNameExportCSV,
  filterOrder,
  tableFilterOptions,
} from './helpers'

class EOTable extends React.PureComponent {
  constructor(props) {
    super(props)
    const { taskTypes, totals, filters } = this.props
    this.state = {
      filter: { id: -1, value: '' },
      columnsEOTable: getEOTableColumns(taskTypes, totals, filters),
      emptyQuestions: taskTypes.length === 0,
    }
  }

  getTooltipCheckbox = tipoCheck =>
    `Selecione essa opção para exibir o desempenho do(s) aluno(s) nas questões ${tipoCheck}`

  isFilterExamsOrStandardTests = filters => {
    const isExam = Object.values(filters).some(element => element === FilterFields.exams)
    const idStandardTests = Object.values(filters).some(
      element => element === FilterFields.standardTests,
    )
    return isExam || idStandardTests
  }

  handleFilterChange = filter => {
    if (filter.id !== -1) {
      const { filters: selectedFilters } = this.props

      const behaviors = ['allStudents', 'anyTaskDone', 'noTaskDone', 'allTasksDone']
      const behaviorTrackName = behaviors.includes(filter.id) ? 'behavior' : 'name'

      window.PLURALL_TRACKER.track(`filter-student-${behaviorTrackName}`, {
        studentsFilter: filter,
        selectedFilters,
      })
    }

    this.setState({ filter })
  }

  handleFilterColumnsTableChange = ({ value, checked }) => {
    const { taskTypes, filters, totals, setSelectedCheckboxFilters } = this.props
    const { columnsEOTable } = this.state

    const filterColumnsTable = filterColumnsEOTable(columnsEOTable, taskTypes, totals)
    setSelectedCheckboxFilters(filterColumnsTable)

    const hasOtherColumnSelected = columnsEOTable.some(
      column => taskTypes.includes(column.key) && column.visible && column.key !== value,
    )

    if (hasOtherColumnSelected) {
      window.PLURALL_TRACKER.track(`students-tab:checkbox-${value}`, {
        checked,
        filters,
      })

      const columns = columnsEOTable.map(column => ({ ...column }))

      const columnSelected = columns.find(column => column.key === value)
      columnSelected.visible = checked
      // eslint-disable-next-line no-param-reassign
      columnSelected.columns.forEach(column => {
        column.visible = checked
      })

      const dependentColumns = columns.filter(column =>
        column.dependencyKey?.includes(columnSelected.key),
      )
      dependentColumns.forEach(dependentColumn => {
        const visible = dependentColumn.dependencyKey.every(key =>
          columns.some(column => column.key === key && column.visible),
        )
        // eslint-disable-next-line no-param-reassign
        dependentColumn.visible = visible
        // eslint-disable-next-line no-param-reassign
        dependentColumn.columns.forEach(column => {
          column.visible = visible
        })
      })

      this.setState({ columnsEOTable: columns })
    }
  }

  studentsByFilterType() {
    const { students, totals } = this.props

    return {
      allStudents: students,
      allTasksDone: students.filter(student => didAllTasks(student, totals)),
      anyTaskDone: students.filter(student => didAnyTask(student, totals)),
      noTaskDone: students.filter(student => didNoTask(student, totals)),
    }
  }

  filteredStudents(studentsByFilterType) {
    const { filter } = this.state
    const { students } = this.props

    if (filter.value === '') {
      return studentsByFilterType.allStudents
    }

    const defaultOptions = ['allStudents', 'anyTaskDone', 'noTaskDone', 'allTasksDone']

    if (defaultOptions.includes(filter.id)) {
      return studentsByFilterType[filter.id]
    }

    return students.filter(student => student.name === filter.value)
  }

  filtersTable(firstColumn) {
    const { filters, allFilters: extraFilters } = this.props
    const filterKeys = Object.keys(filters)
    const allFilterNames = filterKeys.concat(Object.values(extraFilters))

    const uniqueFilterNames = [...new Set(allFilterNames)]

    return filterOrder
      .filter(name => uniqueFilterNames.includes(name))
      .map(name => {
        const field = filterKeys.find(el => el === name)
        if (!field) {
          return {
            [firstColumn]: `${filterNames(name)} : Todos`,
          }
        }

        const filter = filters[name]
        const filterColumn = Array.isArray(filter)
          ? filter.map(el => el.value).join(',')
          : typeof filter === 'string' || filter instanceof String
          ? formatDate(filter)
          : filter.value

        return {
          [firstColumn]: `${filterNames(name)} : ${filterColumn ||
            formatDateTime(filter.toString())}`,
        }
      })
  }

  trackEmptyState() {
    window.PLURALL_TRACKER.track('error-page:no_data')
  }

  renderTableFilter(items) {
    const { students } = this.props
    return (
      <div className={styles.tableFilter}>
        <Text className={styles.tableFilterLabel} size='small' element='span'>
          Filtrar por:
        </Text>
        <SearchBox
          dataTestId='search-students'
          className={styles.tableFilterDropdown}
          defaultItems={items}
          items={students.map(({ id, name }) => ({ id, value: name }))}
          onClick={this.handleFilterChange}
          placeholder='Nome do Aluno'
        />
      </div>
    )
  }

  renderExportCSV(columnsEOTable, filteredStudents) {
    const {
      allFilters: filterFields,
      update: { lastUpdate },
    } = this.props

    const firstColumn = columnsEOTable[1].key
    const filters = this.filtersTable(firstColumn)
    filters.push({ [firstColumn]: `${filterNames('dateUpdate')} : ${formatDateTime(lastUpdate)}` })
    const isExamsOrStandardTests = this.isFilterExamsOrStandardTests(filterFields)
    const studentsWithFilters = filteredStudents.concat(filters)

    return (
      <ExportCSV
        className={styles.exportCSV}
        columns={columnsEOTable}
        data={studentsWithFilters}
        noHeader={false}
        defaultSort={{ key: 'name' }}
        separator=';'
        suffix
        isFilterExamsOrStandardTests={isExamsOrStandardTests}
        filename={slugify(`${fileNameExportCSV(filterFields)}`, {
          replacement: '-',
          remove: "'",
          lower: true,
        })}
        text='Exportar relatório'
      />
    )
  }

  renderFilterColumnsTable(columnsEOTable) {
    const { taskTypes, totals, setSelectedCheckboxFilters } = this.props
    const filterColumnsTable = filterColumnsEOTable(columnsEOTable, taskTypes, totals)
    setSelectedCheckboxFilters(filterColumnsTable)

    return (
      <>
        {filterColumnsTable.map(column => (
          <Tooltip content={this.getTooltipCheckbox(column.typeTooltip)} position='top'>
            <Checkbox
              dataTestId={column.key}
              name='filterColumnsEOTable'
              key={column.key}
              value={column.key}
              label={column.label}
              checked={column.visible}
              onChange={this.handleFilterColumnsTableChange}
            />
          </Tooltip>
        ))}
      </>
    )
  }

  renderTableSubheader(columnsEOTable) {
    return (
      <div className={styles.tableCaptionWrapper}>
        <div className={styles.tableFilterColumns}>
          {this.renderFilterColumnsTable(columnsEOTable)}
        </div>
        <div className={styles.tableCaption}>
          <Text bold secondary element='span'>
            Legenda:
          </Text>
          <span className={styles.tableCaptionIcon}>
            <Icon type='statsPositive' size='small' />
          </span>
          <Text secondary element='span'>
            Acima de 50%
          </Text>
          <span className={styles.tableCaptionIcon}>
            <Icon type='statsNegative' size='small' />
          </span>
          <Text secondary element='span'>
            Abaixo de 50%
          </Text>
        </div>
      </div>
    )
  }

  renderCardTable(filteredStudents) {
    const { columnsEOTable } = this.state
    return (
      <>
        {this.renderTableSubheader(columnsEOTable)}
        <Table columns={columnsEOTable} data={filteredStudents} defaultSort={{ key: 'name' }} />
      </>
    )
  }

  render() {
    const { emptyStudents } = this.props
    const { columnsEOTable, emptyQuestions } = this.state
    const studentsByFilterType = this.studentsByFilterType()
    const filteredStudents = this.filteredStudents(studentsByFilterType)

    if (emptyQuestions) {
      this.trackEmptyState()
    }
    return (
      <div className={styles.tableWrapper}>
        <div className={styles.tableFilterWrapper}>
          {!emptyQuestions &&
            !emptyStudents &&
            this.renderTableFilter(tableFilterOptions(studentsByFilterType))}
          {!emptyQuestions &&
            !emptyStudents &&
            this.renderExportCSV(
              columnsEOTable.filter(column => column.visible),
              filteredStudents,
            )}
        </div>
        <Card>
          {emptyStudents && (
            <ErrorMessage
              src=''
              subTitle='Não há alunos ativados nas turmas selecionadas.'
              title='Nenhum resultado encontrado'
              secondLine='Oriente a ativação das contas dos alunos para visualizar os dados ou selecione outras opções de filtros e clique no botão "Buscar".'
              showNoDataState
            />
          )}

          {emptyQuestions && !emptyStudents && (
            <ErrorMessage
              src=''
              subTitle='Não há resultados para os filtros selecionados.'
              secondLine='Selecione outras opções de filtros e clique no botão "Buscar".'
              title='Nenhum resultado encontrado'
              showNoDataState
            />
          )}
          {!emptyQuestions && !emptyStudents && this.renderCardTable(filteredStudents)}
        </Card>
      </div>
    )
  }
}

EOTable.propTypes = {
  students: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  totals: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  setSelectedCheckboxFilters: PropTypes.shape().isRequired,
  update: PropTypes.shape().isRequired,
  allFilters: PropTypes.shape().isRequired,
  taskTypes: PropTypes.shape().isRequired,
  emptyStudents: PropTypes.bool.isRequired,
}

export default EOTable
