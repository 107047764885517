import React, { useState, useEffect } from 'react'
import { CardStatus, InformationCard } from 'components'

import { Heading, Text } from 'plurall-ui'
import { getIds } from 'utils/itemBuilder'
import EngagementClient from 'utils/restClient'
import styles from './styles.module.css'

interface DigitalClassesOverview {
  classesAverageTime: string
  classesTotal: number
  classesTotalTime: string
}

interface DigitalClassesBigNumbersProps {
  user: User
  filters: EngagementFilter
}

const fetchDigitalClassesWidgets = async (user: User, filters: EngagementFilter) => {
  const client = new EngagementClient()
  return await client.fetchDigitalClassesOverview(
    user.uid,
    filters.school.id,
    getIds(filters.months),
    getIds(filters.grades),
    getIds(filters.classes),
  )
}

const DigitalClassesWidgets = ({ user, filters }: DigitalClassesBigNumbersProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [overview, setOverview] = useState<DigitalClassesOverview>({
    classesAverageTime: '-',
    classesTotal: 0,
    classesTotalTime: '-',
  } as DigitalClassesOverview)

  useEffect(() => {
    const getDigitalClassesWidgets = async () => {
      setLoading(true)
      const newOverview = await fetchDigitalClassesWidgets(user, filters)
      setOverview(newOverview)
      setLoading(false)
    }

    getDigitalClassesWidgets()
  }, [user, filters])

  return (
    <>
      {loading ? (
        <CardStatus />
      ) : (
        <div className={styles.widgetsDigitalClassesWrapper}>
          <div className={styles.informationCard} data-test-id={`classes-total-time`}>
            <InformationCard
              heading={<Heading size='normal'>{`${overview.classesTotalTime}`}</Heading>}
              text={<Text secondary>Duração total de aulas</Text>}
            />
          </div>
          <div className={styles.informationCard} data-test-id={`classes-average-time`}>
            <InformationCard
              heading={<Heading size='normal'>{`${overview.classesAverageTime}`}</Heading>}
              text={<Text secondary>Duração média das aulas</Text>}
            />
          </div>
          <div className={styles.informationCard} data-test-id={`classes-total`}>
            <InformationCard
              heading={<Heading size='normal'>{`${overview.classesTotal}`}</Heading>}
              text={<Text secondary>Total de aulas</Text>}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default DigitalClassesWidgets
