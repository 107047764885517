import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Text } from 'plurall-ui'

import style from './styles.module.css'

function PercentageComplete(props) {
  const { currentValue, maxValue, threshold } = props
  const percentage = maxValue > 0 ? Math.round((currentValue / maxValue) * 100) : 0
  const iconType = percentage >= threshold ? 'statsPositive' : 'statsNegative'

  return (
    <div className={style.base}>
      <Icon size='small' type={iconType} className={style.icon} />
      <Text>{maxValue === 0 ? '-' : `${percentage}%`}</Text>
      <div className={style.values}>
        <Text secondary>
          ({currentValue} de {maxValue})
        </Text>
      </div>
    </div>
  )
}

PercentageComplete.propTypes = {
  threshold: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
}

export default PercentageComplete
