import React, { useState } from 'react'
import { EngagementRole } from 'types/enums'
import { Filter } from 'components'
import { Button } from '@plurall/elo'
import {
  availableClasses,
  availableDisciplines,
  availableGrades,
  availableMonths,
} from 'utils/engagement'
import { getIds } from 'utils/itemBuilder'
import styles from './EngagementFilterBar.module.css'

interface EngagementFilterBarProps {
  role: EngagementRole
  filters: EngagementFilter
  selectedSchool: School
  onSubmit: (filters: EngagementFilter) => void
}

const EngagementFilterBar = ({
  role,
  selectedSchool,
  filters,
  onSubmit,
}: EngagementFilterBarProps) => {
  const [selected, setSelected] = useState<EngagementFilter>(filters)
  const months = availableMonths()
  const disciplines = availableDisciplines(selectedSchool)
  const grades = availableGrades(selectedSchool)
  const classes = availableClasses(selectedSchool, selected.grades || [])

  const handleMonthChange = (item: Item) => handleFiltersChange({ month: item } as EngagementFilter)
  const handleDisciplinesChange = (itens: Item[]) =>
    handleFiltersChange({ disciplines: itens } as EngagementFilter)
  const handleMonthsChange = (itens: Item[]) =>
    handleFiltersChange({ months: itens } as EngagementFilter)
  const handleClassesChange = (itens: Item[]) =>
    handleFiltersChange({ classes: itens } as EngagementFilter)
  const handleGradesChange = (itens: Item[]) => {
    const enabledClasses = availableClasses(selectedSchool, itens).flatMap(klass =>
      getIds(klass.items),
    )
    const selectedClasses = selected.classes?.filter(klass => enabledClasses.includes(klass.id))
    handleFiltersChange({ grades: itens, classes: selectedClasses } as EngagementFilter)
  }

  const handleFiltersChange = (newFilters: EngagementFilter) => {
    const currentFilters = { ...selected }
    setSelected({
      ...currentFilters,
      ...newFilters,
    })
  }

  const handleSubmit = () => {
    onSubmit(selected)
  }

  return (
    <div className={styles.container}>
      {role === EngagementRole.DigitalClasses ? (
        <Filter
          multiSelect
          name='Mês'
          items={months}
          selected={selected.months && getIds(selected.months)}
          onChange={handleMonthsChange}
          placeholder='Todos os Meses'
          buttonLabels={{
            plural: 'Meses',
            singular: 'Mês',
          }}
        />
      ) : (
        <Filter
          name='Mês'
          items={months}
          selected={selected.month?.id}
          onChange={handleMonthChange}
        />
      )}
      {role === EngagementRole.Professor ? (
        <Filter
          multiSelect
          name='Disciplina'
          items={disciplines}
          selected={selected.disciplines && getIds(selected.disciplines)}
          onChange={handleDisciplinesChange}
          placeholder='Todas as Disciplinas'
          buttonLabels={{
            plural: 'Disciplinas',
            singular: 'Disciplina',
          }}
        />
      ) : (
        <>
          <Filter
            multiSelect
            name='Série'
            items={grades}
            selected={selected.grades && getIds(selected.grades)}
            onChange={handleGradesChange}
            placeholder='Todas as Séries'
            buttonLabels={{
              plural: 'Séries',
              singular: 'Série',
            }}
          />
          <Filter
            multiSelect
            name='Turma'
            disabled={!selected.grades?.length}
            items={classes}
            selected={selected.classes && getIds(selected.classes)}
            onChange={handleClassesChange}
            placeholder='Todas as Turmas'
            buttonLabels={{
              plural: 'Turmas',
              singular: 'Turma',
            }}
          />
        </>
      )}
      <Button onClick={handleSubmit} data-test-id='search'>
        Filtrar
      </Button>
    </div>
  )
}

export default EngagementFilterBar
