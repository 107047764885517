import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@plurall/elo'
import { ReactComponent as IconUp } from './IconUp.svg'
import { ReactComponent as IconDown } from './IconDown.svg'
import { ReactComponent as IconIdle } from './IconIdle.svg'

import styles from './styles.module.css'

class Evolution extends React.Component {
  renderImprovementIcon(roundedValue) {
    if (roundedValue > 0) {
      return <IconUp />
    }
    if (roundedValue < 0) {
      return <IconDown />
    }
    return <IconIdle />
  }

  renderImprovement() {
    const { value, label } = this.props
    const roundedValue = Math.round(value)
    const improvementText =
      roundedValue === 0
        ? 'Estável'
        : `${Math.abs(roundedValue)}% ${roundedValue > 0 ? 'maior' : 'menor'}`

    return (
      <div className={styles.wrapper}>
        <span>{this.renderImprovementIcon(roundedValue)}</span>
        <div className={styles.value}>
          <Text secondary>
            {improvementText}&nbsp;{label}
          </Text>
        </div>
      </div>
    )
  }

  render() {
    return <div className={styles.evolution}>{this.renderImprovement()}</div>
  }
}

Evolution.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
}

export default Evolution
